<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <div class="header-d">
        <el-button v-if="false" :disabled="ids.length<=1" @click="batchDelete" type="danger">
          <i class="el-icon-delete"/>
          批量删除
        </el-button>
        <el-button @click="saveOrUpdate" type="primary">
          创建用户
        </el-button>
        <el-select v-model="form.userState"
                   clearable
                   class="common-left-space"
                   @change="init"
                   placeholder="用户状态">
          <el-option
              v-for="item in options"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-select
            v-model="form.roleId"
                   @change="init"
                   class="common-left-space"
                   placeholder="用户类别">
          <el-option
              v-for="item in roleList"
              v-if="item.type>2 && item.type<5"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-date-picker
            class="common-left-space"
            v-model="timeArr"
            type="daterange"
            align="left"
            @change="init"
            format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-button class="common-left-space"
                   @click="form.account='';init()"
                   type="info">
          <i class="el-icon-refresh"></i>
          重置搜索
        </el-button>
        <el-button type="primary"
                   @click="showCol='支付宝'"
                   v-if="false"
                   class="common-left-space">切换支付宝
        </el-button>
        <el-button type="primary"
                   v-if="false"
                   @click="showCol='银行卡'"
                   class="common-left-space">切换银行卡
        </el-button>
        <el-input
            @keyup.enter.native="init"
            clearable
            class="header-d-input"
            v-model.trim="form.account"
            placeholder="支持帐号、昵称、姓名模糊搜索">
          <el-button @click="init"
                     slot="append"
                     icon="el-icon-search">
          </el-button>
        </el-input>
      </div>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         align="center"
                         width="55">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template && item.show.includes(showCol)"
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else-if="item.template && item.show.includes(showCol)"
                           :show-overflow-tooltip="false"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link type="info" size="mini" @click="controls('see',scope.row)">{{$t('table.see')}}</el-link>
                <el-link class="common-left-space" v-if="scope.row.infoAuth===2 && scope.row.userState===1" type="success" size="mini" @click="controls('approveOk',scope.row)">通过认证</el-link>
                <el-link class="common-left-space" type="primary" size="mini" @click="controls('modify',scope.row)">{{$t('table.modify')}}</el-link>
                <el-link class="common-left-space" v-if="scope.row.userState===0" type="success" size="mini" @click="controls('approveOk',scope.row)">{{$t('table.approveOk')}}</el-link>
                <el-link class="common-left-space" type="warning" size="mini" @click="controls('resetPwd',scope.row)">{{$t('table.resetPwd')}}</el-link>
                <el-link class="common-left-space" type="success" size="mini" @click="controls('settle',scope.row)">结算</el-link>
                <el-link class="common-left-space" type="danger" size="mini" @click="controls('deleted',scope.row)">{{$t('table.deleted')}}</el-link>
              </template>
              <template v-if="item.params==='businessState'">
                {{scope.row.businessState|businessStateTransfer}}
              </template>
              <template v-if="item.params==='userState'">
                {{scope.row.userState|userStateTransfer}}
              </template>
              <template v-if="item.params==='infoAuth'">
                <el-tag :type="scope.row.infoAuth===0 ?
                'danger' : scope.row.infoAuth===1 ?
                'warning':scope.row.infoAuth===2 ?
                'primary':scope.row.infoAuth===3 ? 'success' : ''"
                        size="mini">{{scope.row.infoAuth|dictTransfer(infoAuthList)}}</el-tag>
              </template>
              <template v-if="item.params==='usdtAddress'">
                <span v-copy class="font-fine copy-underline">{{scope.row.usdtAddress}}<i class="el-icon-copy-document"/></span>
              </template>
              <template v-if="item.params==='account'">
                <span v-copy class="font-fine copy-underline">{{scope.row.account}}<i class="el-icon-copy-document"/></span>
              </template>
              <template v-if="item.params==='payType'">
                {{scope.row.payType|payTypeTransfer()}}
              </template>
              <template v-if="item.params==='email'">
                <copy :text="scope.row.email"/>
              </template>
              <template v-if="item.params==='openVerify'">
                {{scope.row.openVerify|dictTransfer(openVerifyList)}}
              </template>
              <template v-if="item.params==='orderSuccessAliRate'">
                {{ (scope.row.businessDTO === undefined ?'0.00' : scope.row.businessDTO.countAliTotal > 0 ? computeRate(scope.row.businessDTO.countAliSuccess , scope.row.businessDTO.countAliTotal) : '0.00') }}%
              </template>
              <template v-if="item.params==='orderSuccessBankRate'">
                {{ (scope.row.businessDTO === undefined ?'0.00' : scope.row.businessDTO.countBankTotal > 0 ? computeRate(scope.row.businessDTO.countBankSuccess , scope.row.businessDTO.countBankTotal) : '0.00') }}%
              </template>
              <template v-if="item.params==='totalAmountAli'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.totalAmountAli}}</template>
              <template v-if="item.params==='totalAmountBank'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.totalAmountBank}}</template>
              <template v-if="item.params==='orderSuccessAli'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.orderSuccessAli}}</template>
              <template v-if="item.params==='orderSuccessBank'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.orderSuccessBank}}</template>
              <template v-if="item.params==='countAliTotal'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.countAliTotal}}</template>
              <template v-if="item.params==='countBankTotal'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.countBankTotal}}</template>
              <template v-if="item.params==='countBankSuccess'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.countBankSuccess}}</template>
              <template v-if="item.params==='countAliSuccess'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.countAliSuccess}}</template>
              <template v-if="item.params==='serviceChargeBank'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.serviceChargeBank}}</template>
              <template v-if="item.params==='serviceChargeAli'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.serviceChargeAli}}</template>
              <template v-if="item.params==='serviceChargeAliSettle'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.serviceChargeAliSettle}}</template>
              <template v-if="item.params==='serviceChargeBankSettle'">{{scope.row.businessDTO===undefined?'0':scope.row.businessDTO.serviceChargeBankSettle}}</template>
              <template v-if="item.params==='businessSate'">
                <el-switch
                    @change="businessStateOpen(scope.row)"
                    v-model="scope.row.businessState"
                    active-color="#13ce66"
                    inactive-color="#DCDFE6"
                    :active-value="0"
                    :inactive-value="1"
                    active-text="开启"
                    inactive-text="关闭">
                </el-switch>
              </template>
              <template v-if="item.params==='online'">
                <el-tag :type="scope.row.online===0?'info':'success'" size="mini">{{scope.row.online===0?'离线':'在线'}}</el-tag>
              </template>
              <template v-if="item.params==='noSettle'">
                {{scope.row.businessDTO?.noSettle?.amount.toFixed(2)||'0.00'}}CNY
              </template>
              <template v-if="item.params==='settleCNY'">
                {{scope.row.businessDTO?.noSettle?.settleCNY.toFixed(2)||'0.00'}}CNY
              </template>
              <template v-if="item.params==='settleUSDT'">
                {{scope.row.businessDTO?.noSettle?.settleUSDT.toFixed(2)||'0.00'}}USDT
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
      <SaveOrUpdateUser :role-id="4" @reload="init" ref="saveOrUpdateUser"/>
    </div>
    <div v-else>

    </div>
  </div>
</template>
<script>
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import paging from "@/components/Paging.vue";
import SaveOrUpdateUser from "@/views/info/SaveOrUpdateUser.vue";
import {userDeleteAPI, userInfoAPI, userModifyAPI, userPageAPI} from "@/api/user";
import {controlAPI} from "@/api/Login";
import Copy from "@/components/Copy.vue";

export default {
  name: "PlatformMember",
  mixins:[common,dictTransfer],
  components:{Copy, SaveOrUpdateUser, paging},
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      showCol: '支付宝',
      columnList:[
        {align:"center",label:"昵称",prop:"nickName",width:150,template:false,params:'',show:'支付宝,银行卡'},
        {align:"center",label:"帐号",prop:"account",width:'auto',template:true,params:'account',show:'支付宝,银行卡'},
        {align:"center",label:"未结总额",prop:"noSettle",width:'auto',template:true,params:'noSettle',show:'支付宝,银行卡'},
        {align:"center",label:"应结总额",prop:"settleCNY",width:'auto',template:true,params:'settleCNY',show:'支付宝,银行卡'},
        {align:"center",label:"应结USDT",prop:"settleUSDT",width:'auto',template:true,params:'settleUSDT',show:'支付宝,银行卡'},
        {align:"center",label:"角色",prop:"roleName",width:100,template:false,params:'',show:'支付宝,银行卡'},
        {align:"center",label:"在线状态",prop:"online",width:100,template:true,params:'online',show:'支付宝,银行卡'},
        {align:"center",label:"通道名称",prop:"payType",width:100,template:true,params:'payType',show:'支付宝,银行卡'},
        {align:"center",label:"禁止拉单",prop:"businessSate",width:150,template:true,params:'businessSate',show:'支付宝,银行卡'},
        {align:"center",label:"费率",prop:"aliMinLimit",width:100,template:false,params:'businessSate',show:'支付宝,银行卡'},
        {align:"center",label:"回U上浮",prop:"aliMaxLimit",width:100,template:false,params:'businessSate',show:'支付宝,银行卡'},
        {align:"center",label:"谷歌验证器",prop:"openVerify",width:100,template:true,params:'openVerify',show:'支付宝,银行卡'},
        {align:"center",label:"状态",prop:"userState",width:100,template:true,params:'userState',show:'支付宝,银行卡'},
        // {align:"center",label:"平台认证",prop:"infoAuth",width:120,template:true,params:'infoAuth',show:'支付宝,银行卡'},
        {align:"center",label:"操作",prop:"answer",width:300,template:true,params:'control',show:'支付宝,银行卡'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        userState: 1,
        account: '',
        roleId: '',
        startTime: '',
        endTime: '',
      },
      ids:[],
      options:constant.userState,
      roleList:constant.roleList,
      infoAuthList:constant.infoAuthList,
      openVerifyList:constant.phoneVerify,
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){
      this.form.startTime=this.formatDate(this.timeArr[0],'start')
      this.form.endTime=this.formatDate(this.timeArr[1],'end')
      let form=JSON.parse(JSON.stringify(this.form))
      if (this.form.roleId === "") {
        form.roleId='3,4'
      }
      userPageAPI(form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
        }
      });
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
      this.$refs.saveOrUpdateUser.dialogUser=true
    },
    controls(type,params){
      switch (type) {
          //查看详情
        case 'see':
          this.$refs.saveOrUpdateUser.disabled = true
          //修改
        case 'modify':
          const userInfo = JSON.parse(JSON.stringify(params))
          this.$refs.saveOrUpdateUser.form = userInfo
          this.$refs.saveOrUpdateUser.formBack = userInfo
          this.$refs.saveOrUpdateUser.form.aliMinLimit = userInfo.aliMinLimit
          this.$refs.saveOrUpdateUser.form.aliMaxLimit = userInfo.aliMaxLimit
          this.$refs.saveOrUpdateUser.dialogUser = true
          break
          //通过注册申请
        case 'approveOk':
          controlAPI(0,params.id).then(res=>{
            this.popUp(res)
            this.init()
          })
          break
          //重置密码
        case 'resetPwd':
          controlAPI(1,params.id).then(res=>{
            this.popUp(res)
          })
          break
          //删除
        case 'deleted':
          this.ids.push(params.id)
          this.batchDelete()
          break
        case 'settle':
          this.$router.push({path:'/settle',query:{userId:params.id,nickName:params.nickName}})
          break
      }
    },
    batchDelete(){
      userDeleteAPI(this.ids).then(res=>{
        this.ids=[]
        this.popUp(res)
        this.init()
      })
    },
    computeRate(val1, val2){
      console.log(val1, val2,'compute')
      return (val1 / val2 * 100).toFixed(2)
    },
    businessStateOpen(params){
      let form={}
      form.id=params.id
      form.roleId=1
      form.businessState=params.businessState
      userInfoAPI(params.id).then(res=>{
        form.version=res.data.version
        userModifyAPI(form).then(res=>{
          if (res.code === 200) {
            this.popUp(res);
            this.init()
          }
        });
      });
    },
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
  }
}
</style>
