<template>
  <el-card shadow="hover" style="margin-top: 10px">
    <el-input
        style="margin-top: 10px"
        @keyup.enter.native="init"
        clearable
        size="mini"
        class=""
        v-model="form.account"
        placeholder="支持帐号、昵称、姓名模糊搜索">
      <el-button @click="init"
                 slot="append"
                 icon="el-icon-search">
      </el-button>
    </el-input>
    <el-button v-show="showToggle" v-if="consumer" type="primary" size="mini" style="width:100%;margin-top: 10px;margin-left: auto;" @click="consumer=!consumer;init()">切换到商户列表</el-button>
    <el-button v-show="!showToggle" v-if="!consumer" type="primary" size="mini" style="width:100%;margin-top: 10px;margin-left: auto;">商户列表</el-button>
    <el-button v-show="showToggle" v-if="!consumer" type="primary" size="mini" style="width:100%;margin-top: 10px;margin-left: 0" @click="consumer=!consumer;init()">切换到码商列表</el-button>
    <el-button v-show="!showToggle" v-if="consumer" type="primary" size="mini" style="width:100%;margin-top: 10px;margin-left: 0">码商列表</el-button>
    <el-divider/>
    <p :class="isActive===item.id?'active':''" @click="select(item)" class="txt-line" v-for="(item,index) in tableData.records">{{ item.nickName }}</p>
    <paging
        :component-size="true"
        :page-sizes="[10,20,25,30,40]"
        :update:page="form.pageNum"
        :update:limit="form.pageSize"
        :total="tableData.total"
        :layout="'prev,pager,next'"
        @changeSize="changeSize"
        @pagination="pagination"/>
  </el-card>
</template>

<script>
import {userPageAPI} from "@/api/user";
import paging from "@/components/Paging.vue";

export default {
  name: "ConsumerUser",
  components: {paging},
  props: {
    // true 手动获取,自动获取 false 码商
    type: {
      type: Boolean,
      default: true
    },
    // true 显示切换按钮 false 不显示切换按钮
    showToggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        userState: 1,
        roleId: '3,4',
        account:''
      },
      tableData: {
        records: [],
        total: 0
      },
      consumer:true,
      isActive: false
    }
  },
  mounted() {
    this.consumer=this.type
    const parse = JSON.parse(localStorage.getItem('userInfo'));
    if (parse.roleName.includes('管理员')||parse.roleName.includes('客服')) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.consumer) {
        this.form.roleId='2'
      } else{
        this.form.roleId='3,4'
      }
      userPageAPI(this.form).then(res => {
        if (res.code === 200) {
          this.tableData = res.data
        }
      });
    },
    select(item){
      this.isActive=item.id
      this.$emit('refresh',item,this.consumer)
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-card__body{
  padding: 0 10px;
}
.txt-line{
  cursor: pointer;
  //border: solid 1px;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 30px;
}
.txt-line:hover{
  color: #409EFF;
}
::v-deep .el-divider--horizontal{
  margin-top: 10px;
  margin-bottom: 5px;
}
.active{
  color: #409EFF;
}
</style>
