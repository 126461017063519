<template>
  <div >
    <div v-if="$terminal" class="content-main-padding">
      <div>
        <el-date-picker
            v-model="timeArr"
            type="daterange"
            align="left"
            @change="init"
            format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-button type="primary" @click="form.superiorsId='';init()">重置搜索</el-button>
        <el-input
            @keyup.enter.native="init"
            clearable
            style="width: 350px;float:right;"
            v-model.trim="form.account"
            placeholder="支持帐号、昵称、姓名模糊搜索">
          <el-button @click="init"
                     slot="append"
                     icon="el-icon-search">
          </el-button>
        </el-input>
      </div>
      <div v-if="false" class="card-list">
        <el-card class="card-width"
                 @click=""
                 shadow="hover"
                 v-for="(item, index) in tableData.records">
          <el-descriptions :title="item.nickName"
                           :column="1"
                           size="small" border>
            <template slot="extra">
              <el-link @click="" size="mini" type="primary">
                <i class="el-icon-setting"/>
                设置佣金
              </el-link>
            </template>
            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="平台">{{item.superiorsNickName}}</el-descriptions-item>
            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="卖币单数">{{item.orderCountSelf}}</el-descriptions-item>
            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="卖币成交量">{{item.orderAmountSelf}}</el-descriptions-item>
          </el-descriptions>
        </el-card>
      </div>
      <div v-else style="display: flex;gap: 10px">
        <consumer-user @refresh="init" style="width: 10%"/>
        <div>
          <el-table
              ref="multipleTable"
              :header-row-style="{'color':'gray'}"
              border
              lazy
              :data="tableData.records"
              style="margin-top: 10px;"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              @sort-change="sortChange"
              @expand-change="handleExpandChange"
              :row-class-name="tableRowClassName">
            <el-table-column type="selection"
                             v-if="false"
                             width="55">
            </el-table-column>
            <template v-for="(item,index) in columnList">
              <el-table-column :align="item.align"
                               v-if="!item.template"
                               :show-overflow-tooltip="true"
                               :width="item.width"
                               :prop="item.prop"
                               :key="index"
                               :label="item.label">
              </el-table-column>
              <el-table-column :align="item.align"
                               v-else
                               :show-overflow-tooltip="true"
                               :width="item.width"
                               :prop="item.prop"
                               :key="index"
                               :label="item.label">
                <template slot-scope="scope">
                  <template v-if="item.params==='control'">
                    <el-link type="primary" size="mini" @click="settingBrokerage(scope.row)">设置佣金</el-link>
                  </template>
                  <template v-else-if="item.params==='payState'">
                    {{scope.row.payState|payStateTransfer()}}
                  </template>
                  <template v-else-if="item.params==='payType'">
                    {{scope.row.payType|payTypeTransfer()}}
                  </template>
                  <template v-else-if="item.params==='orderAmountSelf'">
                    {{scope.row.amountAli+scope.row.amountBank}}
                  </template>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <paging
              :page-sizes="[10,20,25,30,40]"
              :update:page="form.pageNum"
              :update:limit="form.pageSize"
              :total="tableData.total"
              @changeSize="changeSize"
              @pagination="pagination"/>
        </div>
      </div>
      <el-dialog
          title="设置下级佣金"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <el-form :model="dataForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="支付宝佣金" prop="juniorRateAli">
            <el-input-number :class="{'apply-shake': valid.juniorRateAli}"
                      placeholder="请输入支付宝佣金"
                      clearable
                      :step="0.001"
                      :max="currentUser.juniorRateAliMax"
                      :min="0"
                      v-model.trim="dataForm.juniorRateAli"></el-input-number>
            <span>当前佣金点数(以10000为例)：<span class="font-red">{{dataForm.juniorRateAli}}</span> * 10000 = </span>
            <span class="font-red">{{dataForm.juniorRateAli*10000}}</span><br>
            <span>最高佣金(以10000为例)：{{currentUser.juniorRateAliMax +' * 10000 = '+currentUser.juniorRateAliMax*10000}}</span>
          </el-form-item>
          <el-form-item label="银行卡佣金" prop="juniorRateBank">
            <el-input-number :class="{'apply-shake': valid.juniorRateBank}"
                      :step="0.001"
                      :min="0"
                      :max="currentUser.juniorRateBankMax"
                      placeholder="请输入银行卡佣金"
                      v-model.trim="dataForm.juniorRateBank"></el-input-number>
            <span>当前佣金点数(以10000为例)：<span class="font-red">{{dataForm.juniorRateBank}}</span> * 10000 = </span>
            <span class="font-red">{{dataForm.juniorRateBank*10000}}</span><br>
            <span>最高佣金(以10000为例)：{{currentUser.juniorRateBankMax +' * 10000 = '+currentUser.juniorRateBankMax*10000}}</span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="handleClose">取  消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确  定</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-else>

    </div>
  </div>
</template>
<script>
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import paging from "@/components/Paging.vue";
import {memberPageAPI, transferMoneyAPI, userModifyAPI} from "@/api/user";
import ConsumerUser from "@/components/ConsumerUser.vue";
import {verifyOnePwdAPI} from "@/api/Login";
let that=''
const checkAli =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.toAccount=true
    setTimeout(()=>{
      if (that) {
        that.valid.juniorRateAli=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkBank =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.toAccount=true
    setTimeout(()=>{
      if (that) {
        that.valid.juniorRateBank=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
export default {
  name: "TeamMember",
  components: {ConsumerUser, paging},
  mixins:[common,dictTransfer,constant],
  data(){
    return{
      form:{
        pageSize:10,
        pageNum: 1,
        account:'',
        startTime:'',
        endTime:'',
        superiorsId:''
      },
      tableData:{
        total:0,
        records:[]
      },
      columnList:[
        {align:"center",label:"昵称",prop:"nickName",width:150,template:false,params:''},
        {align:"center",label:"平台",prop:"superiorsNickName",width:150,template:false,params:''},
        {align:"center",label:"卖币单数",prop:"orderCountJunior",width:150,template:false,params:''},
        {align:"center",label:"卖币成交量",prop:"orderAmountSelf",width:150,template:true,params:'orderAmountSelf'},
        {align:"center",label:"支付宝上级抽佣",prop:"juniorRateAli",width:150,template:false,params:''},
        {align:"center",label:"银行卡上级抽佣",prop:"juniorRateBank",width:150,template:false,params:''},
        {align:"center",label:"操作",prop:"control",width:150,template:true,params:'control'},
      ],
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      ids:[],
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dialogVisible:false,
      dataForm:{
        juniorRateBank: 0,
        juniorRateAli:0,
        id: '',
        superiorsId: '',
      },
      rules:{
        juniorRateAli:[{validator:checkAli, trigger: "blur"},],
        juniorRateBank: [{validator:checkBank, trigger: "blur"},],
      },
      valid:{
        juniorRateBank: false,
        juniorRateAli:false,
      },
      currentUser: '',
    }
  },
  mounted() {
    this.init()
    that=this
  },
  methods:{
    init(params){
      if (params !== undefined) {
        this.form.superiorsId=params.id
        this.currentUser=params
      }
      if (this.timeArr) {
        if (this.timeArr.length > 0) {
          this.form.startTime=this.formatDate(this.timeArr[0],'start');
          this.form.endTime=this.formatDate(this.timeArr[1],'end');
        }
      }
      memberPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData=res.data
        }
      })
    },
    handleClose(){
      this.dialogVisible=false
      this.$refs['ruleForm'].resetFields();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dataForm.roleId=this.loginInfo.roleId
          userModifyAPI(this.dataForm).then(res=>{
            if (res.code === 200) {
              this.ok(res.msg)
              this.init()
              this.dialogVisible=false
            }
          })
        } else {
          return false;
        }
      });
    },
    settingBrokerage(params){
      if (this.form.superiorsId === '') {
        this.error('请先选择上级用户')
        return
      }
      this.dialogVisible=true
      this.dataForm.id=params.id
      this.dataForm.version=params.version
      this.dataForm.juniorRateAli=params.juniorRateAli
      this.dataForm.juniorRateBank=params.juniorRateBank
      this.dataForm.superiorsId=this.form.superiorsId
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    selectable(row, index){
      return row.state===constant.payState[0].type
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
  },
  beforeDestroy() {
    that=null
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.card-list{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
  .card-width{
    cursor: pointer;
    width: 250px;
  }
}
::v-deep .el-card__body{
  padding: 10px;
}
::v-deep .el-descriptions-item__cell ::v-deep .el-descriptions-item__label ::v-deep .is-bordered-label{
  width: 50px;
}
::v-deep .el-descriptions__header{
  margin-bottom: 10px;
}

.label-width{
  width: 80px;
}
</style>
