<template>
  <div>
    <!--pc-->
    <div v-if="$terminal" class="login-back">
      <div class="login-form">
        <div style="margin-top: 30%">
          <div>
            <div class="form-header">
              <img style="width: 60px" :src="Tpng" alt=""/>
              <img v-if="false" style="height: 25px" :src="char" alt=""/>
              <span style="color: #D8D5D0;font-size: 34px">{{loginText}}</span>
            </div>
            <div style="color: #D8D5D0;margin-top: 20px;margin-bottom: 20px">
              欢迎回来，请登录您的账号。
            </div>
          </div>
          <el-form
              style="width: 300px"
              :hide-required-asterisk="false"
              :scroll-to-error="true"
              :scroll-into-view-options="true"
              :model="loginForm"
              ref="loginRef"
              :rules="rulesForm"
              size="large"
              label-position="left">
            <el-form-item prop="account">
              <el-input
                  @blur="exist"
                  @keyup.enter.native="submitForm('loginRef')"
                  :class="{'apply-shake': shakeaccount}"
                  v-model.trim="loginForm.account"
                  :placeholder="$t('login.account')">
              </el-input>
            </el-form-item>
            <el-form-item v-if="false" prop="email">
              <el-input
                  @keyup.enter.native="submitForm('loginRef')"
                  :class="{'apply-shake': shakeEmail}"
                  v-model.trim="loginForm.email"
                  :placeholder="$t('login.email')">
              </el-input>
            </el-form-item>
            <el-form-item v-if="!loginOrRegister" prop="nickName">
              <el-input
                  @keyup.enter.native="submitForm('loginRef')"
                  :class="{'apply-shake': shakeName}"
                  v-model.trim="loginForm.nickName"
                  :placeholder="$t('login.nickName')">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  @keyup.enter.native="submitForm('loginRef')"
                  :class="{'apply-shake': shakePwd}"
                  type="password"
                  :show-password="true"
                  v-model.lazy.trim="pwd"
                  :placeholder="$t('login.password')"/>
            </el-form-item>
            <el-form-item prop="captcha">
              <el-row class="login-language" style="justify-content: space-between">
                <el-col :span="15">
                  <el-input
                      @keyup.enter.native="submitForm('loginRef')"
                      :class="{'apply-shake': shakeCode}"
                      v-model.trim="loginForm.captcha"
                      :placeholder="$t('login.code')"/>
                </el-col>
                <el-col :span="8" style="float:right;">
                  <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="registerCode" v-if="!loginOrRegister">
              <el-row class="login-language" style="justify-content: space-between">
                <div v-if="true">
                  <el-input
                      disabled
                      @keyup.enter.native="submitForm('loginRef')"
                      :class="{'apply-shake': shakeShakeCode}"
                      v-model.trim="loginForm.registerCode"
                      :placeholder="$t('login.registerCode')"/>
                </div>
                <div v-else>
                  <el-col :span="15">
                    <el-input
                        @keyup.enter.native="submitForm('loginRef')"
                        :class="{'apply-shake': shakeCode}"
                        v-model.trim="loginForm.captcha"
                        :placeholder="$t('login.code')"/>
                  </el-col>
                  <el-col :span="8" style="float:right;">
                    <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
                  </el-col>
                </div>
              </el-row>
            </el-form-item>
            <el-form-item v-if="loginOrRegister" prop="otpNum">
              <el-row class="login-language" style="justify-content: space-between">
                <div v-if="true">
                  <el-input
                      @keyup.enter.native="submitForm('loginRef')"
                      v-model.trim="loginForm.otpNum"
                      :placeholder="$t('login.otpNum')"/>
                </div>
                <div v-else>
                  <el-col :span="15">
                    <el-input
                        @keyup.enter.native="submitForm('loginRef')"
                        :class="{'apply-shake': shakeCode}"
                        v-model.trim="loginForm.captcha"
                        :placeholder="$t('login.code')"/>
                  </el-col>
                  <el-col :span="8" style="float:right;">
                    <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
                  </el-col>
                </div>
              </el-row>
            </el-form-item>
            <el-form-item class="login-language">
              <el-radio-group disabled @change="$store.dispatch('i18n')"
                              v-model="$store.state.i18n">
                <el-radio label="zh-CN">中文</el-radio>
                <el-radio label="en-US">English</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="form-btn">
              <div>
                <el-button style="width: 30%"
                           v-if="loginOrRegister"
                           class="register-button"
                           type="warning"
                           @click="loginOrRegister=!loginOrRegister"
                >{{ $t("login.register") }}
                </el-button>
                <el-button style="width: 30%"
                           v-else
                           class="register-button"
                           type="warning"
                           @click="submitForm('loginRef','register')"
                >{{ $t("login.register") }}
                </el-button>
                <el-button
                    v-if="loginOrRegister"
                    style="width: 60%"
                    class="login-button"
                    type="primary"
                    @click="submitForm('loginRef','login')"
                >{{ $t("login.login") }}
                </el-button>
                <el-button
                    v-else
                    style="width: 60%"
                    class="login-button"
                    type="primary"
                    @click="loginOrRegister=true;resForm('loginRef')"
                >{{ $t("login.return") }}
                </el-button>
                <el-button
                    v-if="loginForm.otpPwd!==''"
                    class="login-button"
                    type="primary"
                    @click="dialogOTP=true"
                >重新添加谷歌验证器
                </el-button>
              </div>
              <a v-if="false" class="forgotPassword" @click="dialogRestPwd=true">{{$t("login.forgotPassword")}}</a>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div id="login-back-img" class="login-back-img">

      </div>
      <el-dialog
          :close-on-click-modal="false"
          title="提示"
          :visible.sync="dialogOTP"
          width="30%">
        <div>扫描下方二维码添加谷歌验证器</div>
        <QRCode ref="qrcode"
                :text="loginForm.otpPwd"
                :s-width="1"/>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogOTP = false">取 消</el-button>
          <el-button type="primary" @click="dialogOTP = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          title="提示"
          :visible.sync="dialogRestPwd"
          width="30%">
        <el-form :model="restForm"
                 :rules="restFormRules"
                 ref="restForm"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="帐号" prop="account">
            <el-row>
              <el-col :span="15">
                <el-input :class="{'apply-shake': shakeaccount}" v-model.trim="restForm.account" placeholder="帐号"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button style="float:right;" type="primary" plain @click="resetPwd('restForm','send')">发送验证码</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <!--主要是为了复用-->
          <el-form-item label="邮箱验证码" prop="mailCode">
            <el-input :class="{'apply-shake': shakePwd}" v-model.trim="restForm.mailCode" placeholder="邮箱验证码"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <el-row>
              <el-col :span="15">
                <el-input :class="{'apply-shake': shakeCode}" v-model.trim="restForm.captcha" placeholder="验证码"></el-input>
              </el-col>
              <el-col :span="8">
                <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogRestPwd = false">取 消</el-button>
        <el-button type="primary" @click="resetPwd('restForm','reset')">重 置</el-button>
      </span>
      </el-dialog>
    </div>
    <!--移动端-->
    <div v-else class="m-login-back">
      <div style="margin-top: 10%">
        <div class="form-header">
          <img style="width: 60px" :src="TBlack" alt=""/>
          <img v-if="false" style="height: 25px" :src="charBlack" alt=""/>
          <span style="color: #202020;font-size: 28px">{{loginText}}</span>
        </div>
        <div style="color: #202020;margin-top: 20px;margin-bottom: 20px">
          欢迎回来，请登录您的账号。
        </div>
      </div>
      <el-form
          :hide-required-asterisk="false"
          :scroll-to-error="true"
          :scroll-into-view-options="true"
          :model="loginForm"
          ref="loginRef"
          :rules="rulesForm"
          size="large"
          label-position="left"
          class="m-login-form">
        <el-form-item prop="account">
          <el-input
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakeaccount}"
              v-model.trim="loginForm.account"
              :placeholder="$t('login.account')">
          </el-input>
        </el-form-item>
        <el-form-item v-if="false" prop="email">
          <el-input
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakeEmail}"
              v-model.trim="loginForm.email"
              :placeholder="$t('login.email')">
          </el-input>
        </el-form-item>
        <el-form-item v-if="!loginOrRegister" prop="nickName">
          <el-input
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakeName}"
              v-model.trim="loginForm.nickName"
              :placeholder="$t('login.nickName')">
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakePwd}"
              type="password"
              :show-password="true"
              v-model.lazy.trim="pwd"
              :placeholder="$t('login.password')"/>
        </el-form-item>
        <el-form-item prop="captcha">
          <el-row class="login-language" style="justify-content: space-between">
            <div>
              <el-col :span="15">
                <el-input
                    @keyup.enter.native="submitForm('loginRef')"
                    :class="{'apply-shake': shakeCode}"
                    v-model.trim="loginForm.captcha"
                    :placeholder="$t('login.code')"/>
              </el-col>
              <el-col :span="8" style="float:right;">
                <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
              </el-col>
            </div>
          </el-row>
        </el-form-item>
        <el-form-item v-if="loginOrRegister" prop="otpNum">
          <el-row class="login-language" style="justify-content: space-between">
            <div v-if="true">
              <el-input
                  @keyup.enter.native="submitForm('loginRef')"
                  v-model.trim="loginForm.otpNum"
                  :placeholder="$t('login.otpNum')"/>
            </div>
            <div v-else>
              <el-col :span="15">
                <el-input
                    @keyup.enter.native="submitForm('loginRef')"
                    :class="{'apply-shake': shakeCode}"
                    v-model.trim="loginForm.captcha"
                    :placeholder="$t('login.code')"/>
              </el-col>
              <el-col :span="8" style="float:right;">
                <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
              </el-col>
            </div>
          </el-row>
        </el-form-item>
        <el-form-item prop="registerCode" v-if="!loginOrRegister">
          <el-input
              disabled
              @keyup.enter.native="submitForm('loginRef')"
              :class="{'apply-shake': shakeShakeCode}"
              v-model.trim="loginForm.registerCode"
              :placeholder="$t('login.registerCode')"/>
        </el-form-item>
        <el-form-item class="login-language">
          <el-radio-group disabled @change="$store.dispatch('i18n')" v-model="$store.state.i18n">
            <el-radio label="zh-CN">中文</el-radio>
            <el-radio label="en-US">English</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="form-btn">
          <div>
            <el-button style="width: 30%"
                       v-if="loginOrRegister"
                       class="register-button"
                       type="warning"
                       @click="loginOrRegister=!loginOrRegister"
            >{{ $t("login.register") }}
            </el-button>
            <el-button style="width: 30%"
                       v-else
                       class="register-button"
                       type="warning"
                       @click="submitForm('loginRef','register')"
            >{{ $t("login.register") }}
            </el-button>
            <el-button
                v-if="loginOrRegister"
                style="width: 60%"
                class="login-button"
                type="primary"
                @click="submitForm('loginRef','login')"
            >{{ $t("login.login") }}
            </el-button>
            <el-button
                v-else
                style="width: 60%"
                class="login-button"
                type="primary"
                @click="loginOrRegister=true;resForm('loginRef')"
            >{{ $t("login.return") }}
            </el-button>
            <el-button
                v-if="loginForm.otpPwd!==''"
                class="login-button"
                type="primary"
                @click="dialogOTP=true"
            >重新添加谷歌验证器
            </el-button>
          </div>
          <a v-if="false" class="forgotPassword" @click="resetPwd">{{$t("login.forgotPassword")}}</a>
        </el-form-item>
      </el-form>
      <el-drawer
          :wrapperClosable="false"
          :show-close="false"
          :withHeader="false"
          size="70%"
          :visible.sync="dialogOTP"
          direction="btt">
        <button @click="dialogOTP=false" class="el-button--danger" style="position: absolute;top: 10px;right: 10px">关闭</button>
        <div style="width: 100%;text-align: center">
          添加谷歌验证器
        </div>
        <QRCode style="margin-top: 20px" :s-width="10" ref="qrcode"
                :text="loginForm.otpPwd"/>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import '../../assets/style/myshake.scss'
import {codeAPI, existAPI, loginAPI, mailCodeVerifyAPI, onePwdAPI, registerAPI, sendMailAPI} from "@/api/Login";
import {encryptText} from "@/utils/jsencrypt";
import QRCode from "@/components/QRCode.vue";
import common from "@/mixin/common";
import {orderPageAPI} from "@/api/order";
let that=''
import Tpng from '../../assets/login/T.png'
import char from '../../assets/login/char.png'
import charBlack from '../../assets/login/char-black.png'
import TBlack from '../../assets/login/T-black.png'
import loginBackOTC from '../../assets/login/login-back.png'
import loginBackBackend from '../../assets/login/login-back-backend.png'
import router from "@/router";
const checkaccount =  (rule, value, callback) =>{
  if (!that.loginOrRegister) {
    let email = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value);
    if (!email) {
      that.shakeaccount=true;
      setTimeout(()=>{
        that.shakeaccount=false
      },820);
      callback(new Error('邮箱格式错误'));
    }
  }
  if (value === undefined || value === '') {
    that.shakeaccount=true;
    setTimeout(()=>{
      that.shakeaccount=false
    },820);
    callback(new Error('不可为空'));
  } else {
    callback();
  }
}
const checkEmail =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakeEmail=true
    setTimeout(()=>{
      that.shakeEmail=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checknickName =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakeName=true
    setTimeout(()=>{
      that.shakeName=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkPwd =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakePwd=true
    setTimeout(()=>{
      that.shakePwd=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkCode =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.shakeCode=true
    setTimeout(()=>{
      that.shakeCode=false
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
let interval=null
export default {
  name: "Login",
  mixins:[common],
  components: {QRCode},
  data(){
    return{
      Tpng,
      char,
      TBlack,
      charBlack,
      dialogRestPwd:false,
      loginOrRegister: true,
      shakeCode:false,
      shakePwd:false,
      shakeName:false,
      shakeaccount:false,
      shakeEmail:false,
      shakeShakeCode:false,
      codeImageUrl: '',
      pwd: '',
      loginForm:{
        nickName: '',
        password: '',
        uuid: '',
        captcha: '',
        account: '',
        email: '',
        registerCode: '',
        otpPwd: '',
        otpSecret: '',
        otpNum: '',
      },
      rulesForm:{
        nickName: [
          {validator:checknickName, trigger: "blur"},
        ],
        password: [
          {validator:checkPwd, trigger: "blur"},
        ],
        account: [
          {validator:checkaccount, trigger: "blur"},
        ],
        email: [
          {validator:checkEmail, trigger: "blur"},
        ],
        captcha: [
          {validator:checkCode, trigger: "blur"},
        ],
      },
      restForm:{
        mailCode:'',
        account:'',
        captcha:'',
        uuid:''
      },
      restFormRules:{
        account:[
          {validator:checkaccount, trigger: "blur"}
        ],
        captcha: [
          {validator:checkCode, trigger: "blur"},
        ],
        mailCode: [
          {validator:checkPwd, trigger: "blur"},
        ],
      },
      dialogOTP:false,
      loginText: '',
    }
  },
  created() {
    this.getCode()
  },
  mounted() {
    that = this;
    if (location.hostname === 'tc-otc.com') {
      this.loginText='TC - OTC'
      // this.loginText='TC - 支付后台'
      /*this.$nextTick(()=>{
        let element = document.getElementById('login-back-img')
        // 设置背景图片，确保不重复，并覆盖整个区域
        element.style.background = `url(${loginBackBackend}) no-repeat`;
        element.style.backgroundSize = "cover";
      })*/
    } else {
      this.loginText='TC - 支付后台'
      this.$nextTick(()=>{
        let element = document.getElementById('login-back-img')
        // 设置背景图片，确保不重复，并覆盖整个区域
        element.style.background = `url(${loginBackBackend}) no-repeat`;
        element.style.backgroundSize = "cover";
      })
    }
    if (interval === null) {
      interval=setInterval(()=>{
        that.getCode()
      },110*1000);
    }
    if (document.location.search.length > 1) {
      let url =atob(document.location.search.substring(1));
      let spilt = url.split('&');
      let registerCode = spilt[0].split('=');
      this.loginOrRegister=false
      this.loginForm.registerCode=registerCode[1]
    }
    if (localStorage.getItem('token')) {
      router.push(router.getRoutes()[2].path)
    }
  },
  watch:{
    pwd:{
      handler(n, o) {
        this.loginForm.password=encryptText(n)
      },
      immediate:false,
    }
  },
  methods:{
    resetPwd(formName,type) {
      if (type === 'send') {
        if (this.restForm.account) {
          this.$refs.restForm.validateField('account',(valid)=>{
            if (this.restForm.captcha) {
              this.$refs.restForm.validateField('captcha',(valid)=>{
                sendMailAPI(this.restForm).then(res=>{
                  if (res.code === 200) {
                    this.popUp(res)
                  }
                })
              })
            } else {
              this.$refs.restForm.validateField('captcha')
            }
          })
        } else {
          this.$refs.restForm.validateField('account')
          return;
        }
      }
      if (type === 'reset') {
        //校验帐号
        if (this.restForm.account) {
          this.$refs.restForm.validateField('account',(valid)=>{
            //校验验证码
            if (this.restForm.captcha) {
              this.$refs.restForm.validateField('captcha',(valid)=>{
                //校验邮箱验证码
                if (this.restForm.mailCode) {
                  this.$refs.restForm.validateField('mailCode',(valid)=>{
                    mailCodeVerifyAPI(this.restForm).then(res=>{
                      if (res.code === 200) {
                        this.popUp(res)
                        this.dialogRestPwd = false
                      }
                    })
                  })
                } else {
                  this.$refs.restForm.validateField('mailCode')
                }
              })
            } else {
              this.$refs.restForm.validateField('captcha')
            }
          })
        } else {
          this.$refs.restForm.validateField('account')
        }
      }
    },
    async getCode(){
      let res= await codeAPI();
      this.codeImageUrl = res.data.verifyCode;
      this.loginForm.uuid = res.data.uuid
      this.restForm.uuid = res.data.uuid
    },
    async submitForm(formName,type){
      if (type === 'register') {
        this.loginOrRegister=false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.loginOrRegister) {
            loginAPI(this.loginForm).then(res=>{
              if (res) {
                if (res.code === 200) {
                  this.popUp(res);
                  localStorage.setItem('userInfo',JSON.stringify(res.data));
                  localStorage.setItem('menuList',JSON.stringify(res.data.menuList));
                  this.$store.dispatch('pushPath',res.data.menuList);
                  let form={};
                  form.state='0,1,2,3,4,5'
                  form.pageSize=1
                  form.pageNum=1
                  orderPageAPI(form).then(res=>{
                    if (res.code === 200) {
                      this.$store.state.eventNum=res.data.total
                    }
                  })
                }
              }
            });
          } else {
            const {password,account}=this.loginForm
            onePwdAPI({password,account}).then(res=>{
              if (res.code === 200) {
                this.loginForm.otpPwd=res.data;
                this.loginForm.otpSecret=res.data.split('=')[1]
                this.loginForm.email=this.loginForm.account
                registerAPI(this.loginForm).then(res=>{
                  if (res.code === 200) {
                    this.popUp(res)
                    setTimeout(()=>{
                      this.dialogOTP=true
                      this.$nextTick(()=>{
                        this.$refs.qrcode.qrcode()
                      })
                    },500)
                    this.loginOrRegister=!this.loginOrRegister
                  }
                });
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resForm(formName){
      this.$refs[formName].resetFields();
    },
    /*async getOnePwd(){
      const {password}=this.loginForm
      if (password) {
        const data = await onePwdAPI({password});
        this.loginForm.otpPwd=data.data.otpPwd
        this.loginForm.otpSecret=data.data.otpSecret
        console.log(this.loginForm)
        this.$refs.qrcode.qrcode()
        this.$refs.qrcode.qrcode()
      }
    },*/
    async getOnePwd(){
      const {password}=this.loginForm
      if (password && !this.loginOrRegister) {
        const res = await onePwdAPI({password})
        this.loginForm.otpPwd=res.data
        this.loginForm.otpSecret=res.data.split('=')[1]
        console.log(1)
        setTimeout(()=>{
          this.loginForm.otpSecret=res.data.split('=')[1]
          this.dialogOTP=true
          this.$nextTick(()=>{
            this.$refs.qrcode.qrcode()
          })
        },500)
      }
    },
    exist(){
      const {account}=this.loginForm
      if (account && !this.loginOrRegister) {
        existAPI({account}).then(res=>{
          this.popUpError(res)
          if (res.code === 200) {
            if (res.data === false) {
              this.ok('帐号未重复')
            } else {
              this.error('帐号重复, 请修改后再添加')
            }
          }
        });
      }
    },
  },
  filters: {},
  beforeDestroy() {
    if (interval !== null) {
      clearInterval(interval);
    }
  }
}
</script>

<style scoped lang="scss">
.login-back{
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  //display: flex;
}
.login-back-img{
  width: 70%;
  height: 100vh;
  background: url('../../assets/login/login-back.png') no-repeat;
  background-size: cover;
  position:relative;
}
.login-form{
  height: 100vh;
  background-color: #3B3B3B;
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  //align-content: center;
  justify-content: center;
}
.form-btn{
  //display: flex;
  //justify-content: space-between;
}
.form-header{

}
.register-button{

}
.login-button{
  float: right;
}
.forgotPassword{
  float: right;
  cursor: pointer;
}
.forgotPassword:hover{
  float: right;
  color: #F56C6C;
  cursor: pointer;
}
.m-login-back{
  width: 80%;
  margin: 0 auto;
  //background-color: #3B3B3B;
  .login-img{
    width: 100%;
    margin: 0 auto;
  }
}
.login-code{
  width: 100%;
  height: 40px;
  //border: solid 1px;
  cursor: pointer;
}
.qrcode-pc{
  position: absolute;
  text-align: center;
  top: 20%;
}
</style>
