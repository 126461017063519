<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <!--订单的主要信息-->
      <el-descriptions title="账户信息" :column="3" direction="horizontal" border>
        <el-descriptions-item v-if="$checkRole('管理员,客服')" label="码商昵称" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.bNickName"/>
        </el-descriptions-item>
        <el-descriptions-item label="收款人姓名" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.bRealName"/>
        </el-descriptions-item>
        <el-descriptions-item label="码商收款账号" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.bCollAccount"/>
        </el-descriptions-item>
        <el-descriptions-item v-if="$checkRole('管理员,客服')" label="商户昵称" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.cPayName"/>
        </el-descriptions-item>
        <el-descriptions-item v-if="$checkRole('管理员,客服,手动获取,自动获取,码商')" label="付款人姓名" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.cRealName"/>
        </el-descriptions-item>
        <el-descriptions-item v-if="$checkRole('管理员,客服,手动获取,自动获取,码商')" label="付款人支付帐号" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.cPayAccount"/>
        </el-descriptions-item>
        <el-descriptions-item v-if="$checkRole('管理员,客服,手动获取,自动获取')" label="手动获取帐号" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.consumerAccount"/>
        </el-descriptions-item>
        <el-descriptions-item v-if="$checkRole('管理员,客服')" label="码商帐号" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.businessAccount"/>
        </el-descriptions-item>
        <el-descriptions-item v-if="$checkRole('管理员,客服,手动获取,自动获取,码商')" label="备注" label-class-name="my-label" content-class-name="my-content">
          {{orderInfo.remark}}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider/>
      <el-descriptions title="订单信息" :column="3" direction="horizontal" border>
        <el-descriptions-item label="订单号" label-class-name="my-label" content-class-name="my-content">
          <Copy :text="orderInfo.id"/>
        </el-descriptions-item>
        <el-descriptions-item label="支付状态" label-class-name="my-label" content-class-name="my-content">
          <el-tag size="mini">{{orderInfo.state|orderStateTransfer()}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="支付方式" label-class-name="my-label" content-class-name="my-content">
          {{orderInfo.payType|payTypeTransfer()}}
        </el-descriptions-item>
        <el-descriptions-item label="交易金额" label-class-name="my-label" content-class-name="my-content">
          {{orderInfo.amount}}
        </el-descriptions-item>
        <el-descriptions-item v-if="$checkRole('码商,管理员')" label="平台奖励" label-class-name="my-label" content-class-name="my-content">{{orderInfo.businessAcquire}}</el-descriptions-item>
        <el-descriptions-item label="创建时间" label-class-name="my-label" content-class-name="my-content">{{orderInfo.createTime}}</el-descriptions-item>
        <el-descriptions-item label="支付时间" label-class-name="my-label" content-class-name="my-content">{{orderInfo.payTime}}</el-descriptions-item>
        <el-descriptions-item label="关闭时间" label-class-name="my-label" content-class-name="my-content">{{orderInfo.closeTime}}</el-descriptions-item>
        <el-descriptions-item label="最后更新时间" label-class-name="my-label" content-class-name="my-content">{{orderInfo.closeTime}}</el-descriptions-item>
      </el-descriptions>
      <el-timeline style="margin-top: 10px;">
        <el-timeline-item v-for="(item,index) in orderInfo.historyList" icon="el-icon-more" type="primary" size="large" :timestamp="item.createTime" placement="top">
          <el-card shadow="hover">
            <div style="display: flex">
              <div style="width: 250px">
                <span style="line-height: 30px">通知状态: <el-tag size="mini">{{item.state|payStateTransfer()}}</el-tag></span><el-button v-if="item.state===2" class="common-left-space" @click="orderClose" type="primary" size="mini">确认收款</el-button><br>
                <span v-if="$checkRole('管理员,客服')" style="line-height: 30px">操作人昵称: </span><span v-if="$checkRole('管理员,客服')" style="font-weight: bold">{{item.operateNickName}}</span><br>
                <div v-role="'管理员'">
                  <span style="line-height: 30px">操作人帐号: </span><span style="font-weight: bold">{{item.operateAccount}}</span><br>
                </div>
                <span style="line-height: 30px">查看附件: </span>
                <el-image v-if="item.imgName"
                          style="margin-right: 10px;cursor: pointer"
                          @click="dialogVisible=true;previewUrl=item.imgName"
                          :src="item.imgName"></el-image>
              </div>
              <div style="border-left: solid 1px #DCDFE6;">
                <span style="line-height: 30px;margin-left: 10px">操作备注: </span><span v-if="item.remark">{{item.remark}}</span><br>
              </div>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <el-divider v-if="$checkRole('管理员,客服,码商')" />
      <el-descriptions v-if="$checkRole('管理员,客服,码商')" title="资金变化" :column="3" direction="horizontal" border>
        <el-descriptions-item label="产生利润" label-class-name="my-label" content-class-name="my-content">{{orderInfo.businessProfit}}</el-descriptions-item>
      </el-descriptions>
      <div style="display: flex;justify-content: space-around;margin-top: 10px">
        <div v-if="$checkRole('管理员,客服,码商')">
          <span class="font-weight-bold">USDT资金流水</span>
          <el-timeline v-if="$checkRole('管理员,客服,码商')" style="margin-top: 10px;">
            <el-timeline-item
                v-for="(item,index) in orderInfo.walletList"
                v-if="item.fundType===1||item.fundType===2||item.fundType===3||item.fundType===6||item.fundType===9"
                icon="el-icon-more" type="primary" size="large"
                :timestamp="item.createTime" placement="top">
              <el-card shadow="hover">
                <div style="display: flex">
                  <div style="width: 250px">
                    <span style="line-height: 30px">订单状态: <el-tag size="mini">{{item.payState|payStateTransfer}}</el-tag></span><br>
                    <span style="line-height: 30px;" v-if="$checkRole('管理员,客服')">操作人昵称: </span><span v-if="$checkRole('管理员,客服')" style="font-weight: bold;">{{item.operateNickName}}</span><br>
                    <div v-role="'管理员'">
                      <span style="line-height: 30px;">操作人帐号: </span><span style="font-weight: bold;">{{item.operateAccount}}</span><br>
                    </div>
                    <span style="line-height: 30px">查看附件: </span><span>{{item.imgName}}</span><br>
                  </div>
                  <div style="border-left: solid 1px #DCDFE6;">
                    <span style="line-height: 30px;margin-left: 10px">资金类型: <el-tag size="mini">{{item.fundType|dictTransfer(fundStreamList)}}</el-tag></span><br>
                    <span style="line-height: 30px;margin-left: 10px">操作完成前余额: </span><span style="font-weight: bold">{{item.balanceBefore}}</span><br>
                    <span style="line-height: 30px;margin-left: 10px">操作完成后余额: </span><span style="font-weight: bold;">{{item.balanceAfter}}</span><br>
                    <span style="line-height: 30px;margin-left: 10px">操作备注: </span><span>{{item.remark}}</span><br>
                  </div>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <el-empty v-else/>
        </div>
        <div>
          <span class="font-weight-bold" v-if="!$checkRole('管理员,客服,码商')">服务费流水</span>
          <span class="font-weight-bold" v-else>佣金流水</span>
          <el-timeline v-if="$checkRole('管理员,客服,码商')" style="margin-top: 10px;">
            <el-timeline-item v-for="(item,index) in orderInfo.walletList"
                              v-if="item.fundType===4||item.fundType===5||item.fundType===7||item.fundType===8"
                              icon="el-icon-more"
                              type="primary"
                              size="large"
                              :timestamp="item.createTime"
                              placement="top">
              <el-card shadow="hover">
                <div style="display: flex">
                  <div style="width: 250px">
                    <span style="line-height: 30px">订单状态: <el-tag size="mini">{{item.payState|payStateTransfer}}</el-tag></span><br>
                    <span style="line-height: 30px;" v-if="$checkRole('管理员,客服')">操作人昵称: </span><span v-if="$checkRole('管理员,客服')" style="font-weight: bold;">{{item.operateNickName}}</span><br>
                    <div v-role="'管理员'">
                      <span style="line-height: 30px;">操作人帐号: </span><span style="font-weight: bold;">{{item.operateAccount}}</span><br>
                    </div>
                    <span style="line-height: 30px">查看附件: </span><span>{{item.imgName}}</span><br>
                  </div>
                  <div style="border-left: solid 1px #DCDFE6;">
                    <span style="line-height: 30px;margin-left: 10px">资金类型: <el-tag size="mini">{{item.fundType|dictTransfer(fundStreamList)}}</el-tag></span><br>
                    <span style="line-height: 30px;margin-left: 10px">操作完成前余额: </span><span style="font-weight: bold">{{item.balanceBefore}}</span><br>
                    <span style="line-height: 30px;margin-left: 10px">操作完成后余额: </span><span style="font-weight: bold;">{{item.balanceAfter}}</span><br>
                    <span style="line-height: 30px;margin-left: 10px">操作备注: </span><span>{{item.remark}}</span><br>
                  </div>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <el-empty v-else/>
        </div>
      </div>
      <el-divider v-if="$checkRole('管理员,客服,码商')"/>
      <div class="stimulate-div">
        <span class="paragraph bold">说明</span>
        <p class="paragraph text-line-height">1.如对账单有疑问请联系平台运营人员</p>
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <el-image :src="previewUrl"></el-image>
      </el-dialog>
    </div>
    <div v-else>
      <el-tabs v-if="false" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane style="margin-top: 0" label="账户信息" name="first">
          <div class="h5-content" v-if="$checkRole('管理员,客服')">
            <div class="h5-row">
              <div class="label-height">
                码商昵称
              </div>
              <Copy :text="orderInfo.bNickName"/>
            </div><hr>
            <div class="h5-row" v-if="$checkRole('管理员,客服,码商')">
              <div>
                收款人姓名
              </div>
              <Copy :text="orderInfo.bRealName"/>
            </div><hr>
            <div class="h5-row">
              <div>
                码商收款账号
              </div>
              <Copy :text="orderInfo.bCollAccount"/>
            </div><hr>
            <div v-if="$checkRole('管理员,客服')" class="h5-row">
              <div>
                商户昵称
              </div>
              <Copy :text="orderInfo.cPayName"/>
            </div><hr>
            <div class="h5-row">
              <div v-if="$checkRole('管理员,客服,手动获取,自动获取,码商')">
                付款人姓名
              </div>
              <Copy :text="orderInfo.cRealName"/>
            </div><hr>
            <div class="h5-row">
              <div v-if="$checkRole('管理员,客服,手动获取,自动获取,码商')">
                付款人支付帐号
              </div>
              <Copy :text="orderInfo.cPayAccount"/>
            </div><hr>
            <div class="h5-row" v-if="$checkRole('管理员,客服,手动获取,自动获取')">
              <div>
                手动获取帐号
              </div>
              <Copy :text="orderInfo.consumerAccount"/>
            </div><hr>
            <div class="h5-row" v-if="$checkRole('管理员,客服')">
              <div>
                码商帐号
              </div>
              <Copy :text="orderInfo.businessAccount"/>
            </div><hr>
          </div>
        </el-tab-pane>
        <el-tab-pane style="margin-top: 0" label="订单信息" name="second">
          <div class="h5-content">
            <div class="h5-row">
              <div class="label-height">
                订单号
              </div>
              <Copy :text="orderInfo.id"/>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                支付状态
              </div>
              <el-tag size="mini">{{orderInfo.state|orderStateTransfer()}}</el-tag>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                最后更新时间
              </div>
              <div>{{orderInfo.closeTime}}</div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                支付方式
              </div>
              <div>{{orderInfo.payType|payTypeTransfer()}}</div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                交易金额
              </div>
              <div>
                {{orderInfo.amount}}
              </div>
            </div><hr>
            <div v-if="$checkRole('码商,管理员')" class="h5-row">
              <div class="label-height">
                平台奖励
              </div>
              <div>{{orderInfo.businessAcquire}}</div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                创建时间
              </div>
              <div>
                {{orderInfo.createTime}}
              </div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                支付时间
              </div>
              <div>
                {{orderInfo.payTime}}
              </div>
            </div><hr>
            <div class="h5-row">
              <div>
                关闭时间
              </div>
              <div>
                {{orderInfo.closeTime}}
              </div>
            </div><hr>
            <el-timeline style="margin-top: 10px;">
              <el-timeline-item v-for="(item,index) in orderInfo.historyList" icon="el-icon-more" type="primary" size="large" :timestamp="item.createTime" placement="top">
                <el-card shadow="hover">
                  <div style="display: flex">
                    <div style="width: 100%">
                      <span style="line-height: 30px">订单状态: <el-tag size="mini">{{item.state|payStateTransfer()}}</el-tag></span><br>
                      <span v-if="$checkRole('管理员,客服')" style="line-height: 30px">操作人昵称: </span><span v-if="$checkRole('管理员,客服')" style="font-weight: bold">{{item.operateNickName}}</span><br>
                      <div v-role="'管理员'">
                        <span style="line-height: 30px">操作人帐号: </span><span style="font-weight: bold">{{item.operateAccount}}</span><br>
                      </div>
                      <span style="line-height: 30px">查看附件: </span>
                      <el-image v-if="item.imgName"
                                style="margin-right: 10px;cursor: pointer"
                                @click="dialogVisible=true;previewUrl=item.imgName"
                                :src="item.imgName"></el-image><br>
                      <span style="line-height: 30px;">操作备注: </span><span v-if="item.remark ">{{item.remark}}</span>
                    </div>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="$checkRole('管理员,客服,码商')" style="margin-top: 0" label="资金变化" name="third">
          <div class="h5-content" v-if="$checkRole('管理员,客服,码商')">
            USDT资金流水
          </div>
          <div class="h5-content" v-if="$checkRole('管理员,客服,码商')">
            <el-timeline>
              <el-timeline-item
                  v-for="(item,index) in orderInfo.walletList"
                  v-if="item.fundType===1||item.fundType===2||item.fundType===3||item.fundType===6||item.fundType===9"
                  icon="el-icon-more" type="primary" size="large"
                  :timestamp="item.createTime" placement="top">
                <el-card shadow="hover">
                  <div style="width: 100%">
                    <span style="line-height: 30px">订单状态: <el-tag size="mini">{{item.payState|payStateTransfer}}</el-tag></span><br>
                    <span v-if="$checkRole('管理员,客服')" style="line-height: 30px;">操作人昵称: </span><span v-if="$checkRole('管理员,客服')" style="font-weight: bold;">{{item.operateNickName}}</span><br>
                    <div v-role="'管理员'">
                      <span style="line-height: 30px;">操作人帐号: </span><span style="font-weight: bold;">{{item.operateAccount}}</span><br>
                    </div>
                    <span style="line-height: 30px;">查看附件: </span><span>{{item.imgName}}</span><br>
                    <span style="line-height: 30px;">资金类型: <el-tag size="mini">{{item.fundType|dictTransfer(fundStreamList)}}</el-tag></span><br>
                    <span style="line-height: 30px;">操作完成前余额: </span><span style="font-weight: bold">{{item.balanceBefore}}</span><br>
                    <span style="line-height: 30px;">操作完成后余额: </span><span style="font-weight: bold;">{{item.balanceAfter}}</span><br>
                    <span style="line-height: 30px;">操作备注: </span><span>{{item.remark}}</span><br>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <el-empty v-if="orderInfo.walletList ===undefined || orderInfo.walletList.length ===0"></el-empty>
          </div>
          <div class="h5-content" v-if="!$checkRole('管理员,客服,码商')">
            服务费流水
          </div>
          <div class="h5-content" v-else>
            佣金流水
          </div>
          <div class="h5-content" v-if="$checkRole('管理员,客服,手动获取,自动获取')">
            <el-timeline>
              <el-timeline-item v-for="(item,index) in orderInfo.walletList"
                                v-if="item.fundType===4||item.fundType===5||item.fundType===7||item.fundType===8"
                                icon="el-icon-more"
                                type="primary"
                                size="large"
                                :timestamp="item.createTime"
                                placement="top">
                <el-card shadow="hover">
                  <div style="width: 100%">
                    <span style="line-height: 30px">订单状态: <el-tag size="mini">{{item.payState|payStateTransfer}}</el-tag></span><br>
                    <span v-if="$checkRole('管理员,客服')" style="line-height: 30px;">操作人昵称: </span><span v-if="$checkRole('管理员,客服')" style="font-weight: bold;">{{item.operateNickName}}</span><br>
                    <div v-role="'管理员'">
                      <span style="line-height: 30px;">操作人帐号: </span><span style="font-weight: bold;">{{item.operateAccount}}</span><br>
                    </div>
                    <span style="line-height: 30px">查看附件: </span><span>{{item.imgName}}</span><br>
                    <span style="line-height: 30px;">资金类型: <el-tag size="mini">{{item.fundType|dictTransfer(fundStreamList)}}</el-tag></span><br>
                    <span style="line-height: 30px;">操作完成前余额: </span><span style="font-weight: bold">{{item.balanceBefore}}</span><br>
                    <span style="line-height: 30px;">操作完成后余额: </span><span style="font-weight: bold;">{{item.balanceAfter}}</span><br>
                    <span style="line-height: 30px;">操作备注: </span><span>{{item.remark}}</span><br>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <el-empty v-if="orderInfo.walletList ===undefined || orderInfo.walletList.length ===1"></el-empty>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="h5-content">
        <div class="h5-row">
          <div class="label-height">
            码商昵称
          </div>
          <Copy :text="orderInfo.bNickName"/>
        </div><hr>
        <div class="h5-row" v-if="$checkRole('管理员,客服,码商')">
          <div>
            收款人姓名
          </div>
          <Copy :text="orderInfo.bRealName"/>
        </div><hr>
        <div class="h5-row">
          <div>
            码商收款账号
          </div>
          <Copy :text="orderInfo.bCollAccount"/>
        </div><hr>
        <div v-if="$checkRole('管理员,客服')" class="h5-row">
          <div>
            商户昵称
          </div>
          <Copy :text="orderInfo.cPayName"/>
        </div><hr>
        <div class="h5-row">
          <div v-if="$checkRole('管理员,客服,手动获取,自动获取,码商')">
            付款人姓名
          </div>
          <Copy :text="orderInfo.cRealName"/>
        </div><hr>
        <div class="h5-row">
          <div v-if="$checkRole('管理员,客服,手动获取,自动获取,码商')">
            付款人支付帐号
          </div>
          <Copy :text="orderInfo.cPayAccount"/>
        </div><hr>
        <div class="h5-row" v-if="$checkRole('管理员,客服,手动获取,自动获取')">
          <div>
            手动获取帐号
          </div>
          <Copy :text="orderInfo.consumerAccount"/>
        </div><hr>
        <div class="h5-row" v-if="$checkRole('管理员,客服,码商')">
          <div>
            码商帐号
          </div>
          <Copy :text="orderInfo.businessAccount"/>
        </div><hr>
      </div>
      <div class="h5-content">
        <div class="h5-row">
          <div class="label-height">
            订单号
          </div>
          <Copy :text="orderInfo.id"/>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            支付状态
          </div>
          <el-tag size="mini">{{orderInfo.state|orderStateTransfer()}}</el-tag>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            支付方式
          </div>
          <div>{{orderInfo.payType|payTypeTransfer()}}</div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            交易金额
          </div>
          <div>
            {{orderInfo.amount}}
          </div>
        </div><hr>
        <div v-if="$checkRole('码商,管理员')" class="h5-row">
          <div class="label-height">
            平台奖励
          </div>
          <div>{{orderInfo.businessAcquire}}</div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            创建时间
          </div>
          <div>
            {{orderInfo.createTime}}
          </div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            支付时间
          </div>
          <div>
            {{orderInfo.payTime}}
          </div>
        </div><hr>
        <div class="h5-row">
          <div>
            关闭时间
          </div>
          <div>
            {{orderInfo.closeTime}}
          </div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            最后更新时间
          </div>
          <div>{{orderInfo.closeTime}}</div>
        </div><hr>
        <el-timeline style="margin-top: 10px;">
          <el-timeline-item v-for="(item,index) in orderInfo.historyList" icon="el-icon-more" type="primary" size="large" :timestamp="item.createTime" placement="top">
            <el-card shadow="hover">
              <div style="display: flex">
                <div style="width: 100%">
                  <span style="line-height: 30px">通知状态: <el-tag size="mini">{{item.state|payStateTransfer()}}</el-tag></span><el-button id="pay-btn" v-if="(item.state===2||item.state===18) && orderInfo.historyList.length===2" class="common-left-space" @click="orderClose" type="primary" size="mini">确认收款</el-button><br>
                  <span v-if="$checkRole('管理员,客服')" style="line-height: 30px">操作人昵称: </span><span v-if="$checkRole('管理员,客服')" style="font-weight: bold">{{item.operateNickName}}</span><br>
                  <div v-role="'管理员'">
                    <span style="line-height: 30px">操作人帐号: </span><span style="font-weight: bold">{{item.operateAccount}}</span><br>
                  </div>
                  <span style="line-height: 30px">查看附件: </span>
                  <el-image v-if="item.imgName"
                            style="margin-right: 10px;cursor: pointer"
                            @click="callback=dialogVisible=true;previewUrl=item.imgName"
                            :src="item.imgName"></el-image><br>
                  <span style="line-height: 30px;">操作备注: </span><span v-if="item.remark ">{{item.remark}}</span>
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
      <el-drawer
          :show-close="false"
          :withHeader="false"
          size="80%"
          :visible.sync="callback"
          direction="btt">
        <button class="el-button--danger" @click="callback=false" style="position: absolute;right: 10px;top: 10px;">关闭</button>
        <img style="width: 100%;margin-top: 40px" :src="previewUrl">
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {orderInfoAPI, orderModifyAPI} from "@/api/order";
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import Copy from "@/components/Copy.vue";
import constant from "@/constant";

export default {
  name: "OrderInfo",
  components: {Copy},
  mixins:[common,dictTransfer],
  data() {
    return {
      orderInfo:{},
      fundStreamList: constant.fundType,
      dialogVisible:false,
      previewUrl:'',
      activeName: 'first',
      callback: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      if (this.$route.query.id === undefined) {
        this.$router.back()
        this.error('请先选择订单')
        return
      }
      orderInfoAPI(this.$route.query.id).then(res=>{
        if (res.code === 200) {
          this.orderInfo=res.data
          if (this.orderInfo.state === 2) {
            this.$nextTick(()=>{
              window.scrollBy({
                top: document.body.scrollHeight,
                behavior: 'smooth'
              });
            })
          }
        }
      });
    },
    orderClose(){
      let form = {
        id:this.orderInfo.id,
        state:constant.payState[0].type,
        payType:this.orderInfo.payType,
        userId:this.orderInfo.userId,
        businessId:this.orderInfo.businessId,
        orderVersion:this.orderInfo.orderVersion,
      };
      form.state=constant.payState[10].type
      form.messageType=constant.payState[10].type
      this.$confirm('是否确认收款?').then(()=> {
        orderModifyAPI(form).then(res=>{
          if (res.code === 200) {
            this.popUp(res);
            this.$router.back()
          }
        })
      }).catch(()=>{})
    },
    handleClick(){}
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
::v-deep .el-card__body ,.el-main{
  padding: 10px;
}
</style>

