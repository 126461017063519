<template>
  <div>
    <!--PC-->
    <div v-if="$terminal">
      <div style="display: flex;width: 100%">
        <div style="flex: 0 0 40%;">
          <div class="team-title" style="margin-bottom: 10px">请选择支付渠道</div>
          <div class="header-d">
            <template v-for="(item,index) in payTypeList">
              <div class="item-type"
                   v-if="item.disabled===false"
                   :class="form.payType===item.type?'item-type-active':''"
                   @click="checkType(item.type)">
                <img :src="item.icon"
                     :style="item.name === '银行卡'?{'width':'40px'}:''"
                     class="img-pay"
                     loading="eager"
                     alt="32">
                <span class="type-pay">{{item.name}}</span>
              </div>
            </template>
          </div>
          <el-form :model="form"
                   :rules="rules"
                   ref="ruleForm"
                   label-width="140px"
                   style="text-align: center;
               padding-top: 10px;
               width: 440px;
               margin: 0 auto">
            <!--支付宝-->
            <div v-if="form.payType===1">
              <el-form-item prop="rmbAmount" label="金额">
                <el-input-number v-model="form.rmbAmount"
                          placeholder="请输入购买金额"
                          :min="limit.aliMinLimit"
                          clearable
                          :step="0.01"
                          :max="limit.aliMaxLimit"
                          style="width: 300px;"></el-input-number>
              </el-form-item>
              <el-form-item prop="cRealName" label="姓名">
                <el-input v-model="form.cRealName"
                          placeholder="请输入姓名"
                          clearable
                          style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item v-if="false" prop="cPayAccount" label="支付宝帐号">
                <el-input v-model="form.cPayAccount"
                          placeholder="支付宝帐号"
                          :maxlength="11"
                          :minlength="11"
                          show-word-limit
                          clearable
                          style="width: 300px;"></el-input>
              </el-form-item>
            </div>
            <!--银行卡-->
            <div v-if="form.payType===2">
              <el-form-item prop="rmbAmount" label="金额">
                <el-input-number v-model="form.rmbAmount"
                                 placeholder="请输入购买数量"
                                 :step="0.01"
                                 :min="limit.bankMinLimit"
                                 :max="limit.bankMaxLimit"
                                 clearable
                                 style="width: 300px;"></el-input-number>
              </el-form-item>
              <el-form-item prop="cRealName" label="姓名">
                <el-input v-model="form.cRealName"
                          placeholder="请输入姓名"
                          clearable
                          style="width: 300px;"></el-input>
              </el-form-item>
              <el-form-item prop="cPayAccount" label="银行卡号后6位">
                <el-input v-model="form.cPayAccount"
                          placeholder="请输入银行卡号后6位"
                          clearable
                          show-word-limit
                          :maxlength="6"
                          :minlength="6"
                          style="width: 300px;"></el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button type="warning"
                         style="float:right;"
                         @click="submitForm('ruleForm')">创建订单</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="stimulate-div" style="flex: 1;">
          <p class="paragraph bold">说明</p>
          <p class="paragraph text-line-height">1.为降低支付宝风控的影响,当订单金额为<span class="bold">整数</span>的时候，平台会在原订单金额的基础上随机增加￥1-5元。此操作是为了降低支付宝风控的影响，请以系统显示的<span class="bold">最终数量为准</span> 。</p>
          <p class="paragraph text-line-height">2.当选择<span class="bold">支付宝</span>渠道时，请输入您的<span class="bold">真实姓名</span>和<span class="bold">支付宝帐号</span>，留待核验码商核验订单状态。最低交易金额为¥<span class="bold"> {{limit.aliMinLimit}} </span>元，最高交易金额为¥<span class="bold"> {{limit.aliMaxLimit}} </span>元。</p>
          <p class="paragraph text-line-height">3.当选择<span class="bold">银行卡</span>支付渠道时，请输入您的<span class="bold">真实姓名</span>和<span class="bold">银行卡后6位</span>，留待核验码商核验订单状态。最低交易金额为¥<span class="bold"> {{limit.bankMinLimit}} </span>元，最高交易金额为¥<span class="bold"> {{limit.bankMaxLimit}} </span>元。</p>
          <p class="paragraph text-line-height">4.平台最低购买金额为<span class="bold">{{limit.aliMinLimit < limit.bankMinLimit?limit.aliMinLimit:limit.bankMinLimit}}</span>，最高购买金额为<span class="bold">{{limit.aliMaxLimit>limit.bankMaxLimit?limit.aliMaxLimit:limit.bankMaxLimit}}</span>，请根据实际需求选择购买数量。</p>
        </div>
      </div>
      <el-divider/>
    </div>
    <!--移动端-->
    <div v-else>
      <div class="h5-content">
        <div id="text-container" class="stimulate-div collapse-content" @click="toggleText" style="margin-top: 0;margin-bottom: 5px">
          <p class="paragraph bold">展开说明</p>
          <p class="paragraph text-line-height-h5">1.为降低支付宝风控的影响,当订单金额为<span class="bold">整数</span>的时候，平台会在原订单金额的基础上随机增加￥1-5元。此操作是为了降低支付宝风控的影响，请以系统显示的<span class="bold">最终数量为准</span> 。</p>
          <p class="paragraph text-line-height-h5">2.当选择<span class="bold">支付宝</span>渠道时，请输入您的<span class="bold">真实姓名</span>和<span class="bold">支付宝帐号</span>，留待核验码商核验订单状态。最低交易金额为¥<span class="bold"> {{limit.aliMinLimit}} </span>元，最高交易金额为¥<span class="bold"> {{limit.aliMaxLimit}} </span>元。</p>
          <p class="paragraph text-line-height-h5">3.当选择<span class="bold">银行卡</span>支付渠道时，请输入您的<span class="bold">真实姓名</span>和<span class="bold">银行卡后6位</span>，留待核验码商核验订单状态。最低交易金额为¥<span class="bold"> {{limit.bankMinLimit}} </span>元，最高交易金额为¥<span class="bold"> {{limit.bankMaxLimit}} </span>元。</p>
          <p class="paragraph text-line-height-h5">4.平台最低购买金额为<span class="bold">{{limit.aliMinLimit < limit.bankMinLimit?limit.aliMinLimit:limit.bankMinLimit}}</span>，最高购买金额为<span class="bold">{{limit.aliMaxLimit>limit.bankMaxLimit?limit.aliMaxLimit:limit.bankMaxLimit}}</span>，请根据实际需求选择购买数量。</p>
        </div>
        <div style="display: flex;justify-content: space-evenly;align-items: center">
          <template v-for="(item,index) in payTypeList">
            <div class="h5-img-pay-item"
                 v-if="item.disabled===false"
                 :class="form.payType===item.type?'h5-img-pay-list':''"
                 @click="checkType(item.type)">
              <img :src="item.icon"
                   :style="item.name === '银行卡'?{'width':'40px'}:{'width':'40px'}"
                   class="img-pay"
                   loading="eager"
                   alt="32">
              <span class="type-pay">{{item.name}}</span>
            </div>
          </template>
        </div>
        <el-form :model="form"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="0px"
                 style="text-align: center;
               padding-top: 10px;
               margin: 0 auto">
          <!--支付宝-->
          <div v-if="form.payType===1">
            <el-form-item prop="rmbAmount">
              <el-input-number v-model="form.rmbAmount"
                               placeholder="请输入购买金额"
                               :min="limit.aliMinLimit"
                               clearable
                               :step="0.01"
                               :max="limit.aliMaxLimit"
                               style="width: 300px;"></el-input-number>
            </el-form-item>
            <el-form-item prop="cRealName">
              <el-input v-model="form.cRealName"
                        placeholder="请输入姓名"
                        clearable
                        style="width: 300px;"></el-input>
            </el-form-item>
            <el-form-item v-if="false" prop="cPayAccount">
              <el-input v-model="form.cPayAccount"
                        placeholder="支付宝帐号"
                        :maxlength="11"
                        :minlength="11"
                        show-word-limit
                        clearable
                        style="width: 300px;"></el-input>
            </el-form-item>
          </div>
          <!--银行卡-->
          <div v-if="form.payType===2">
            <el-form-item prop="rmbAmount">
              <el-input-number v-model="form.rmbAmount"
                               placeholder="请输入购买数量"
                               :step="0.01"
                               :min="limit.bankMinLimit"
                               :max="limit.bankMaxLimit"
                               clearable
                               style="width: 300px;"></el-input-number>
            </el-form-item>
            <el-form-item prop="cRealName">
              <el-input v-model="form.cRealName"
                        placeholder="请输入姓名"
                        clearable
                        style="width: 300px;"></el-input>
            </el-form-item>
            <el-form-item prop="cPayAccount">
              <el-input v-model="form.cPayAccount"
                        placeholder="请输入银行卡号后6位"
                        clearable
                        show-word-limit
                        :maxlength="6"
                        :minlength="6"
                        style="width: 300px;"></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div style="width: 100%;display: flex;align-items: end;flex-direction: row-reverse;">
              <el-button type="warning" style="margin-left: 5px" @click="submitForm('ruleForm')">创建订单</el-button>
              <el-button @click="toggleText" size="mini" type="danger">下单须知<i class="el-icon-question"></i></el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import constant from "@/constant";
import {orderLimitAPI, orderSaveAPI} from "@/api/order";
import common from "@/mixin/common";

export default {
  name: "CreateOrder",
  watch: {},
  components: {},
  mixins: [common],
  data() {
    return {
      payTypeList: constant.payType,
      form:{
        payType:JSON.parse(localStorage.getItem('userInfo')).payType,
        rmbAmount:0,
        cRealName:'',
        cPayAccount:'',
        id:new Date().getTime()
      },
      rules: {
        cRealName:[
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 2, max: 10, message: '长度在 2 到 8 个字符', trigger: 'blur' }
        ],
        cPayAccount:[
          { required: false, message: '必填项', trigger: 'blur' },
        ],
      },
      limit:{
        aliMaxLimit:0,
        aliMinLimit:0,
        bankMaxLimit:0,
        bankMinLimit:0,
      },
    }
  },
  mounted() {
    this.orderLimit()
  },
  methods: {
    checkType(type){
      this.form.payType=type
    },
    toggleText(){
      let container = document.getElementById('text-container');
      if (container.classList.contains('expanded')) {
        container.classList.remove('expanded');
      } else {
        container.classList.add('expanded');
      }
    },
    orderLimit(){
      orderLimitAPI().then(res=>{
        this.limit=res.data
      })
    },
    submitForm(formName){
      if (this.form.payType === 2 && this.form.cPayAccount.length !== 6) {
        this.error('请填写银行卡号后6位')
        return
      }
      if (this.form.cRealName === '') {
        //this.form.cRealName ='佚名'
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          orderSaveAPI(this.form).then(res=>{
            if (res.code === 200) {
              this.resOk(res);
              this.$emit('refresh',false)
            }
          })
        }
      });
    },
  },
  filters:{},
  beforeDestroy() {

  },
}
</script>

<style scoped lang="scss">
@import "../assets/style/conmmon-style.scss";
.header-d{
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  .img-pay{
    width: 30px;
  }
  .type-pay{
    position: relative;
    top: -6px;
    left: 10px;
  }
  .item-type{
    text-align: center;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    padding: 10px;
  }
  .item-type-active{
    background-color: #E1F3D8;
  }
}
.h5-img-pay-list{
  background-color: #E1F3D8;
}
.h5-img-pay-item{
  cursor: pointer;
  width: 100px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
</style>
