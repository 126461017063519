<template>
  <el-dialog
      :top="this.form.roleId=='2'?'1%':'10%'"
      width="60%"
      :title="form.id===''? '创建用户':disabled?'详细信息':'修改信息'"
      :modal="true"
      :append-to-body="true"
      @close="resetForm('ruleForm')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogUser">
    <el-form :model="form"
             :rules="rules"
             :disabled="disabled"
             ref="ruleForm"
             label-width="220px"
             class="demo-ruleForm">
      <div class="form-div">
        <!--1-->
        <div class="form-col">
          <el-form-item label="帐号"
                        prop="account">
            <el-input clearable
                      @blur="checkAccount"
                      :disabled="form.id!==''"
                      v-model.trim="form.account"></el-input>
          </el-form-item>
        </div>
        <div class="form-col">
          <el-form-item label="昵称"
                        prop="nickName">
            <el-input clearable :disabled="form.roleId===3||form.roleId===4" v-model.trim="form.nickName"></el-input>
          </el-form-item>
        </div>
        <!--2-->
        <div v-if="roleId==1" class="form-col" v-role="'管理员'" >
          <el-form-item label="角色"
                        prop="roleId">
            <el-select style="width: 100%" v-model="form.roleId" placeholder="请选择">
              <el-option
                  v-for="item in roleList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="roleId==4" class="form-col" v-role="'管理员'" >
          <el-form-item label="角色"
                        prop="roleId">
            <el-select style="width: 100%" v-model="form.roleId" placeholder="请选择">
              <el-option
                  v-if="item.auth.includes('4')"
                  v-for="item in roleList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-col">
          <el-form-item label="令牌"
                        prop="otpSecret">
            <el-button type="danger"
                       @click="resetOTP"
                       size="small">重新生成</el-button>
            <el-button :disabled="false"
                       type="success"
                       @click="showOTP"
                       size="small">添加到手机</el-button>
            <el-button type="primary"
                       v-if="false"
                       @click="genOTP"
                       size="small">将令牌添加到手机</el-button>
          </el-form-item>
        </div>
        <!--3-->
        <div class="form-col" v-role="'码商,管理员'" v-if="false" >
          <el-form-item label="支付宝" prop="aliAccount">
            <el-input show-word-limit
                      maxlength="11"
                      minlength="11"
                      clearable v-model.trim="form.aliAccount"></el-input>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'码商,管理员'" v-if="false">
          <el-form-item label="真实姓名" prop="realName">
            <el-input clearable v-model.trim="form.realName"></el-input>
          </el-form-item>
        </div>
        <!--4-->
        <div class="form-col" v-role="'码商,管理员'" v-if="false">
          <el-form-item label="银行卡号"
                        prop="bankAccount">
            <el-input clearable show-word-limit maxlength="18" v-model.trim="form.bankAccount"></el-input>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'码商,管理员'" v-if="false">
          <el-form-item label="开户行" prop="bankAddress">
            <el-input clearable v-model.trim="form.bankAddress"></el-input>
          </el-form-item>
        </div>
        <!--5-->
        <div v-if="false" class="form-col">
          <el-form-item label="邮箱" prop="email">
            <el-input clearable v-model.trim="form.email"></el-input>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'码商,管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item v-if="false" label="汇款地址"
                        prop="remittanceAddress">
            <el-input clearable v-model.trim="form.remittanceAddress"></el-input>
          </el-form-item>
          <el-form-item v-if="false" label="备注"
                        prop="remark">
            <el-input clearable v-model.trim="form.remark"></el-input>
          </el-form-item>
        </div>
        <!--6 !isConsumer && isBusiness-->
        <div class="form-col" v-role="'管理员'" v-if="false">
          <el-form-item label="账户余额" prop="usdtBalance">
            <el-input-number :min="0" :step="0.001" style="width: 100%" v-model.number="form.usdtBalance"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="usdt充值地址" prop="usdtAddress">
            <el-input clearable v-model.number="form.usdtAddress"></el-input>
          </el-form-item>
        </div>
        <!--!isConsumer && isBusiness-->
        <div class="form-col" v-role="'管理员'" v-if="false">
          <el-form-item v-if="true" label="佣金余额" prop="rmbBalance">
            <el-input clearable v-model.number="form.rmbBalance"></el-input>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员,手动获取'" v-if="!isBusiness">
          <el-form-item v-if="true" label="服务费累计" prop="rmbBalance">
            <el-input clearable disabled v-model.number="form.rmbBalance"></el-input>
          </el-form-item>
        </div>
        <div v-role="'管理员'" class="form-col">
          <el-form-item label="谷歌验证器">
            <el-radio-group v-model="form.openVerify">
              <el-radio v-for="item in openVerifyList"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!--7-->
        <div class="form-col" v-role="'码商,管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="商户状态" prop="businessState">
            <el-radio-group v-model="form.businessState">
              <el-radio v-for="item in businessState"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'">
          <el-form-item label="支付方式"
                        prop="payType">
            <el-radio-group v-model="form.payType">
              <el-radio v-for="item in payType"
                        v-if="!item.disabled"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness">
          <el-form-item label="允许修改状态"
                        prop="payType">
            <el-radio-group v-model="form.authBusinessState">
              <el-radio v-for="item in openStateList"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!--8-->
        <div class="form-col" v-role="'管理员'">
          <el-form-item label="帐户状态"
                        prop="userState">
            <el-select style="width: 100%" v-model="form.userState" placeholder="请选择">
              <el-option
                  v-for="item in userState"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!--<div class="form-col" v-role="'管理员'" v-if="!isConsumer">
          <el-form-item label="平台计算开关">
            <el-radio-group v-model="form.transactionRateOpen">
              <el-radio v-for="item in openVerifyList"
                        :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>-->
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness">
          <el-form-item label="充值奖励比例">
            <el-input-number style="width: 100%" :min="0" :step="0.001" v-model.trim="form.platformRate">
            </el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness">
          <el-form-item label="平台补贴比例(银行卡)">
            <el-input-number style="width: 100%" :min="0" :step="0.001" v-model.trim="form.awardRateBank">
            </el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness">
          <el-form-item label="平台补贴比例(支付宝)">
            <el-input-number style="width: 100%" :min="0" :step="0.001" v-model.trim="form.awardRateAli">
            </el-input-number>
          </el-form-item>
        </div>
<!--        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness">
          <el-form-item label="上级提成比例(银行卡最大比例)">
            <el-input-number style="width: 100%" :min="0" :step="0.001" v-model.trim="form.juniorRateBankMax">
            </el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness">
          <el-form-item label="上级提成比例(支付宝最大比例)">
            <el-input-number style="width: 100%" :min="0" :step="0.001" v-model.trim="form.juniorRateAliMax">
            </el-input-number>
          </el-form-item>
        </div>-->
        <!--9 !isConsumer && isBusiness-->
<!--        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="上级提成比例(银行卡)" prop="juniorRateBank">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.juniorRateBank"></el-input-number>
          </el-form-item>
        </div>-->
        <!--!isConsumer && isBusiness-->
<!--        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="上级提成比例(支付宝)" prop="juniorRateAli">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.juniorRateAli"></el-input-number>
          </el-form-item>
        </div>-->
        <div class="form-col" v-role="'管理员'" v-if="isConsumer && false" >
          <el-form-item label="支付宝手续费" prop="platformRate">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.awardRateAli"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="isConsumer && false" >
          <el-form-item label="银行卡手续费" prop="platformRate">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.awardRateBank"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="isConsumer" >
          <el-form-item label="费率" prop="aliMinLimit">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.aliMinLimit"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="isConsumer" >
          <el-form-item label="回U上浮" prop="aliMaxLimit">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.aliMaxLimit"></el-input-number>
          </el-form-item>
        </div>
        <!--10-->
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="支付宝最低支付金额" prop="aliMinLimit">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.aliMinLimit"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="支付宝最高支付金额" prop="aliMaxLimit">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.aliMaxLimit"></el-input-number>
          </el-form-item>
        </div>
        <!--11-->
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="银行卡最低支付金额" prop="bankMinLimit">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.bankMinLimit"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="银行卡最高支付金额" prop="bankMaxLimit">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.bankMaxLimit"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness" >
          <el-form-item label="上级id" prop="bankMaxLimit">
            <el-input-number style="width: 100%"
                             :min="0" :step="0.001"
                             v-model.trim="form.registerCode"></el-input-number>
          </el-form-item>
        </div>
        <div class="form-col" v-role="'管理员'" v-if="!isConsumer && isBusiness">
          <el-form-item label="后台认证开关" prop="bankMaxLimit">
            <el-switch
                v-model="form.infoAuth"
                active-color="#13ce66"
                :active-value="3"
                :inactive-value="1">
            </el-switch>当开启时才可以接收订单(码商无感知)
          </el-form-item>
        </div>
        <!--12-->
        <div style="width: 100%">
          <el-button type="primary"
                     @click="submitForm('ruleForm')"
                     class="common-form-btn common-left-space">保存</el-button>
          <el-button type="info"
                     :disabled="false"
                     @click="resetForm('ruleForm')"
                     class="common-form-btn">取消</el-button>
        </div>
      </div>
    </el-form>
    <el-dialog title="使用Authenticator应用扫描二维码"
               :top="'18%'"
               :visible.sync="dialogOTP"
               :modal="false"
               width="20%">
      <QRCode :text="otpSecret" ref="qrcode"/>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {Message, MessageBox} from "element-ui";

const validateNumber =  (rule, value, callback)=>{
  if (value === undefined || value === '') {
    callback();
    return
  }
  if (!/^\d+(\.\d+)?$/.test(value)) {
    callback(new Error('请输入有效的数字'));
  } else {
    callback();
  }
}
import constant from "@/constant";
import {encryptText} from "@/utils/jsencrypt";
import {
  dynamicAdminAPI,
  dynamicAPI,
  existAPI,
  modifyPwdAPI,
  onePwdAPI,
  resetDynamicAPI,
  verifyPwdAPI
} from "@/api/Login";
// import QRcode from "qrcodejs2";
import QRCode from "@/components/QRCode.vue";
import common from "@/mixin/common";
import {userInfoAPI, userModifyAPI, userSaveAPI} from "@/api/user";
import userInfo from "@/views/info/UserInfo.vue";
let qrcode = null;
let that=null
export default {
  name: "SaveOrUpdateUser",
  components: {QRCode},
  mixins:[common],
  props:{
    roleId:{
      type:Number|String,
      default:1
    }
  },
  data(){
    return{
      dialogOTP: false,
      dialogUser: false,
      disabled:false,
      payType:constant.payType,
      openStateList:constant.openStateList,
      businessState:constant.businessState,
      userState:constant.userState,
      roleList:constant.roleList,
      openVerifyList:constant.phoneVerify,
      form:{
        openVerify:1,
        roleId: 2,
        pageNum:1,
        pageSize:10,
        orderType:'asc',
        account:'',
        aliAccount: '',
        email: '',
        nickName: '',
        realName: '',
        bankAccount: '',
        bankAddress: '',
        usdtAddress: '',
        remittanceAddress: '',
        rmbBalance: '',
        //邀请码
        inviteCode: '',
        registerCode: '',
        remark: '',
        usdtBalance: '',
        businessState: 0,
        payType: 1,
        id: '',
        userState:1,
        otpSecret: '',
        version: '',
        //码商汇率
        platformRate:0.08,
        //码商汇率计算 开关
        transactionRateOpen:0,
        //手续费
        handlingRate:0,
        //支付宝最大限额
        aliMaxLimit:20000,
        //支付宝最小限额
        aliMinLimit:500,
        //银行最大限额
        bankMaxLimit:50000,
        //银行最小限额
        bankMinLimit:1000,
        //上级提成比例
        juniorRateBank:0.03,
        juniorRateAli:0.03,
        infoAuth:3,
        //上级提成比例最大
        juniorRateAliMax:0,
        juniorRateBankMax:0,
        awardRateBank:0.028,
        awardRateAli:0.026,
        authBusinessState: 1,
      },
      rules: {
        account: [
          { required: true, message: '必填项', trigger: 'blur' },
          { min: 6, max: 30, message: '长度在 6 到 15 个字符', trigger: 'blur' },
          { type: 'email', message: '请输入有效的邮箱地址', trigger: ['blur', 'change'] }
        ],
        nickName: [
          { required: true, message: '必填项', trigger: 'blur' },
          { min: 1, max: 15, message: '长度在 1 到 6 个字符', trigger: 'blur' }
        ],
        aliAccount:[
          { required: false, message: '必填项', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号' }
        ],
        email: [
          { required: false, message: '必填项', trigger: 'blur' },
          { type: 'email', message: '请输入有效的邮箱地址', trigger: ['blur', 'change'] }
        ],
        remittanceAddress: [

        ],
        bankAccount: [
          { required: false, message: '必填项', trigger: 'blur' },
          { max:18,min:13, message: '请输入有效的银行卡号',trigger: ['blur', 'change'] }
        ],
        usdtBalance: [
          { validator: validateNumber, trigger: 'blur' }
        ],
      },
      otpSecret: '',
      formBack:{},
      tempAddress: '',
    }
  },
  mounted() {
    that=this
    // noinspection EqualityComparisonWithCoercionJS
    if (this.roleId != 1) {
      this.form.roleId=this.roleId
    }
    this.init();
    this.formBack=this.form
  },
  computed: {
    isConsumer(){
      return this.form.roleId===constant.roleMap.consumer || this.form.roleId===constant.roleMap.api
    },
    isBusiness(){
      return this.form.roleId===constant.roleMap.business
    }
  },
  watch: {
    'form.roleId':{
      handler(val){
        if (val===3||val===4) {
          if (this.form.aliMaxLimit === 20000) {
            this.form.aliMaxLimit=0;
            this.form.aliMinLimit=0
            this.form.openVerify=0
            this.form.businessState=1
            this.rules.nickName[0].required=false
          }
        } else {
          this.form.aliMaxLimit=20000
          this.form.aliMinLimit=500
          this.rules.nickName[0].required=true
        }
      },
      deep:true,
      immediate:true
    },
  },
  methods: {
    init(){},
    info(){
      userInfoAPI(this.form.id).then(res=>{
        if (res.code === 200) {
          this.form=res.data
          this.form.roleId=this.$store.state.userInfo.roleId
          this.dialogUser=true
        }
      })
    },
    genOTP(){
      let a={password:encryptText('1')}
      onePwdAPI(a).then(res=>{
        this.form.otpSecret=res.data.split('=')[1]
        setTimeout(()=>{
          this.dialogOTP=true
          this.otpSecret=res.data
          setTimeout(()=>{
            that.$refs.qrcode.qrcode()
          },200)
        },500)
      })
    },
    resetOTP(){
      let id='?userId='+this.form.id
      resetDynamicAPI(id).then(res=>{
        if (res.code === 200) {
          Message.success('重置成功');
          this.dialogOTP=true
          this.otpSecret=res.data
          setTimeout(()=>{
            that.$refs.qrcode.qrcode()
          },200)
        }
      })
    },
    showOTP(){
      if (this.form.id) {
        dynamicAdminAPI(this.form.id).then(res=>{
          if (res.code === 200) {
            this.dialogOTP=true
            this.otpSecret=res.data
            setTimeout(()=>{
              that.$refs.qrcode.qrcode()
            },200)
          }
        });
      }
    },
    submitForm(formName) {
      this.form.id===''?'':this.rules.account[1].min=1
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.authBusinessState === 0) {
            this.form.businessState=0
          }
          if (this.form.id) {
            //修改
            let form = JSON.parse(JSON.stringify(this.form))
            form.roleId=this.formBack.roleId
            form.newRoleId=this.form.roleId
            userModifyAPI(form).then(res=>{
              this.popUp(res)
              this.dialogUser=false
              this.$emit('reload')
            });
          } else {
            //保存
            userSaveAPI(this.form).then(res=>{
              this.popUp(res)
              this.dialogUser=false
              this.$emit('reload')
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogUser=false
      this.disabled=false
    },
    checkAccount(){
      const {account}=this.form
      if (account) {
        existAPI({account}).then(res=>{
          this.popUpError(res)
          if (res.code === 200) {
            if (res.data === false) {
              this.ok('帐号未重复')
            } else {
              this.form.account=''
              this.error('帐号重复, 请修改后再添加')
            }
          }
        });
      }
    },
  },
  filters: {},
  beforeDestroy() {
    qrcode=null
    that=null
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";

::v-deep .el-form-item__label {
  font-weight: bold;
}
</style>
