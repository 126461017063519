<template>
  <div style="display: flex">
    <input class="input-d-1" type="datetime-local" required pattern="\d{4}-\d{2}-\d{2}" v-model="startTime" placeholder="开始时间">
    <input class="input-d-1" type="datetime-local" required pattern="\d{4}-\d{2}-\d{2}" v-model="endTime" placeholder="结束时间">
  </div>
</template>

<script>
import common from "@/mixin/common";
function convertToCustomFormat(datetimeString) {
  // 创建 Date 对象
  const date = new Date(datetimeString);

  // 定义补零函数
  const pad = (n) => (n < 10 ? '0' + n : n);

  // 获取各个时间部分
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // 月份从0开始
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  // 返回格式化的字符串
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export default {
  name: "DateRange",
  mixins:[common],
  data(){
    return{
      times:[],
      startTime: '',//this.now('start')
      endTime: '',//this.now('end')
    }
  },
  mounted() {
  },
  watch:{
    startTime:{
      handler(){
        if (this.times[0] !== null || this.times[0] !== undefined) {
          this.times[0]=this.startTime;
          let timeArr = JSON.parse(JSON.stringify(this.times))
          const startTime = timeArr[0];
          timeArr[0]=convertToCustomFormat(startTime)

          if (timeArr[1].includes('T')) {
            const endTime = timeArr[1];
            timeArr[1]=convertToCustomFormat(endTime)
          }
          this.$emit('input',timeArr)
        }
      },
      immediate:false
    },
    endTime:{
      handler(){
        if (this.times.length > 1) {
          this.times[1]=this.endTime;
          let timeArr = JSON.parse(JSON.stringify(this.times))
          const endTime = timeArr[1];
          timeArr[1]=convertToCustomFormat(endTime)

          if (timeArr[0].includes('T')) {
            const startTime = timeArr[0];
            timeArr[0]=convertToCustomFormat(startTime)
          }
          this.$emit('input',timeArr);
        }
      },
      immediate:false
    }
  },
  methods:{
    now(start){
      return formatDateToDatetimeLocal(new Date())
      function formatDateToDatetimeLocal(date) {
        const pad = (n) => (n < 10 ? '0' + n : n);
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1); // 月份从0开始
        const day = pad(date.getDate());
        let hours=date.getHours()
        let minutes=date.getMinutes()
        if (start === 'start') {
          hours = '00'
          minutes = '00'
        }
        if (start === 'end') {
          hours=23
          minutes=59
        }
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.no-keyboard input {
  pointer-events: none; /* 禁用输入框的指针事件 */
}
input[type="datetime-local"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="datetime-local"]::-webkit-clear-button {
  display: none;
}
.input-d-1 {
  width: 100px;
  border: solid 1px #DCDFE6;
  border-radius: 5px;
  background-color: white;
  font-size: 10px;
}
</style>
