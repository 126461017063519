<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <el-descriptions class="margin-top" title="" :column="4" border>
        <el-descriptions-item label="未领取">
          {{currentUser.rmbBalance}}<el-button style="margin-left: 10px"
                                               type="primary"
                                               @click="withdraw"
                                               size="mini">提取利润</el-button>
        </el-descriptions-item>
        <el-descriptions-item label="总成交量(含自己)">{{currentUser.amount}}</el-descriptions-item>
        <el-descriptions-item label="累计获利">{{currentUser.totalProfile}}</el-descriptions-item>
        <el-descriptions-item label="自己成交量">{{currentUser.orderAmountSelf}}</el-descriptions-item>
      </el-descriptions>
      <div v-if="true" style="margin-top: 10px;">
        <div class="team-title" style="margin-bottom: 10px">&nbsp;团队管理</div>
        <div style="margin-top: 10px;margin-bottom: 10px">
          <span class="text-color-red">支付宝</span>可分配点数<span class="text-color-red">{{((currentUser.awardRateAli)*10000).toFixed(2)}}</span><br><br>
          <span class="text-color-red">银行卡</span>可分配点数<span class="text-color-red">{{((currentUser.awardRateBank)*10000).toFixed(2)}}</span>
        </div>
        <div style="display: flex">
          <el-date-picker
              v-model="timeArr"
              type="daterange"
              align="left"
              @change="init"
              format="yyyy-MM-dd"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
          </el-date-picker>
          <el-input placeholder="昵称搜索" class="common-left-space" style="width: 300px" clearable v-model.trim="form.nickName"></el-input>
          <el-button @click="init" circle class="el-button--primary common-left-space">
            <i class="el-icon-search"/>
          </el-button>
        </div>
        <el-table
            ref="multipleTable"
            :header-row-style="{'color':'gray'}"
            border
            lazy
            :data="tableData.list"
            style="width: 100%;margin-top: 10px"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            @sort-change="sortChange"
            @expand-change="handleExpandChange"
            :row-class-name="tableRowClassName">
          <el-table-column v-if="false"
                           type="selection"
                           width="55">
          </el-table-column>
          <template v-for="(item,index) in columnList">
            <el-table-column :align="item.align"
                             v-if="!item.template"
                             :show-overflow-tooltip="true"
                             :width="item.width"
                             :prop="item.prop"
                             :key="index"
                             :label="item.label">
            </el-table-column>
            <el-table-column :align="item.align"
                             v-else
                             :show-overflow-tooltip="true"
                             :width="item.width"
                             :prop="item.prop"
                             :key="index"
                             :label="item.label">
              <template slot-scope="scope">
                <template v-if="item.params==='control'">
                  <el-link type="primary" size="mini" @click="rowClick(scope.row)">设置</el-link>
                </template>
                <template v-else-if="item.params==='payState'">
                  {{scope.row.payState|payStateTransfer()}}
                </template>
                <template v-else-if="item.params==='payType'">
                  {{scope.row.payType|payTypeTransfer()}}
                </template>
                <template v-else-if="item.params==='total'">
                  {{scope.row.amountAli+scope.row.amountBank}}
                </template>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <paging
            :page-sizes="[10,20,25,30,40]"
            :update:page="form.pageNum"
            :update:limit="form.pageSize"
            :total="tableData.total"
            @changeSize="changeSize"
            @pagination="pagination"/>
        <div v-if="false" id="echarts" style="width: 100%;height: 400px;margin-top: 10px"></div>
        <div v-if="false" class="stimulate-div">
          <p class="paragraph">“激励计划”是指通过邀请团队成员，按照团队成员的销售额的一定比例提供的收益计划。
            希望通过团队成员的推广，让更多的人获得收益，从而实现团队的盈利。具体的规则如下：</p>
          <p class="paragraph">1. 通过分享自己的推广链接，通过专项推广链接注册的新用户即是自己的团队成员。
            团队成员每售出一个USDT货币，您可以获取1%的收益。平台获取0.1%的收益。</p>
          <p class="paragraph">2.“激励计划”仅针对由推广链接注册的用户，才能获得“激励计划”。不具有推广链接的用户，无法获得“激励计划”。</p>
        </div>
        <div v-if="false" class="earnings-d">
          累计收益:
          <span></span>

        </div>
      </div>
      <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <el-form :model="dataForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="支付宝佣金" prop="juniorRateAli">
            <el-input-number :class="{'apply-shake': valid.juniorRateAli}"
                             placeholder="请输入支付宝佣金"
                             clearable
                             :step="0.001"
                             :min="0"
                             v-model.trim="dataForm.juniorRateAli"></el-input-number><br>
            <span>当前佣金点数(以10000为例)：<span class="font-red">{{dataForm.juniorRateAli}}</span> * 10000 = </span>
            <span class="font-red">{{dataForm.juniorRateAli*10000}}</span><br>
            <span>最高佣金(以10000为例)：{{currentUser.juniorRateAliMax +' * 10000 = '+currentUser.juniorRateAliMax*10000}}</span>
          </el-form-item>
          <el-form-item label="银行卡佣金" prop="juniorRateBank">
            <el-input-number :class="{'apply-shake': valid.juniorRateBank}"
                             :step="0.001"
                             :min="0"
                             placeholder="请输入银行卡佣金"
                             v-model.trim="dataForm.juniorRateBank"></el-input-number><br>
            <span>当前佣金点数(以10000为例)：<span class="font-red">{{dataForm.juniorRateBank}}</span> * 10000 = </span>
            <span class="font-red">{{dataForm.juniorRateBank*10000}}</span><br>
            <span>最高佣金(以10000为例)：{{currentUser.juniorRateBankMax +' * 10000 = '+currentUser.juniorRateBankMax*10000}}</span>
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="handleClose">取  消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">确  定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <div v-else>
      <div class="h5-content">
        <h5-row class="m-title" label="团队总业绩">
          <template slot="value">
            <el-button @click="withdraw" size="mini" type="primary">提取利润</el-button>
          </template>
        </h5-row>
        <h5-row label="未领取">
          <template slot="value">
            <div>
              <span>{{currentUser.rmbBalance}}</span>
            </div>
          </template>
        </h5-row>
        <h5-row label="总成交量(含自己)" :value="currentUser.amount"/>
        <h5-row label="累计获利" :value="currentUser.totalProfile"/>
        <h5-row label="自己成交量" :value="currentUser.orderAmountSelf"/>
      </div>
      <div class="h5-content">
        <span class="m-title">团队管理</span><br>
        <div style="margin-top: 10px">
          <span class="text-color-red">支付宝</span>可分配点数<span class="text-color-red">{{((currentUser.awardRateAli)*10000).toFixed(2)}}</span><br><br>
          <span class="text-color-red">银行卡</span>可分配点数<span class="text-color-red">{{((currentUser.awardRateBank)*10000).toFixed(2)}}</span>
        </div>
      </div>
      <div v-if="false" class="h5-content" style="display: flex">
        <DateRange v-model="timeArr"/>
        <el-input placeholder="昵称搜索" clearable v-model.trim="form.nickName" size="mini" style="width: 100%;"></el-input>
        <button @click="init" class="el-button--primary">
          <i class="el-icon-search"/>
        </button>
      </div>
      <div class="h5-content">
        <table class="m-table">
          <thead class="m-table-title-border">
          <tr>
            <th style="width: 80px" class="m-table-title">下级昵称</th>
            <th class="m-table-title">支付宝</th>
            <th class="m-table-title">银行卡</th>
            <th class="m-table-title">总成交</th>
            <th class="m-table-title">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr class="m-tr" v-for="(item,index) in tableData.list">
            <td class="m-th-td">{{item.nickName}}</td>
            <td class="m-th-td">{{item.amountAli}}</td>
            <td class="m-th-td">
              {{item.amountBank}}
            </td>
            <td class="m-th-td">
              {{ item.amountAli+item.amountBank }}
            </td>
            <td class="m-th-td">
              <button class="el-button--primary"
                         @click="rowClick(item)">
                设置
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <paging
          class="h5-content"
          :component-size="true"
          :background="false"
          :layout="'total,prev, pager,next'"
          :page-size-show="false"
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
      <el-drawer
          :show-close="false"
          :withHeader="false"
          size="70%"
          :visible.sync="dialogVisible"
          direction="btt">
        <div class="h5-content">
          <el-form :model="dataForm"
                   :rules="rules"
                   ref="ruleForm"
                   size="mini"
                   label-width="100px"
                   class="demo-ruleForm">
            <el-form-item label="支付宝佣金" prop="juniorRateAli">
              <el-input-number :class="{'apply-shake': valid.juniorRateAli}"
                               placeholder="请输入支付宝佣金"
                               clearable
                               :step="0.001"
                               :min="0"
                               v-model.trim="dataForm.juniorRateAli"></el-input-number><br>
              <span>当前佣金点数(以10000为例)：<span class="font-red">{{dataForm.juniorRateAli}}</span> * 10000 = </span>
              <span class="font-red">{{dataForm.juniorRateAli*10000}}</span><br>
              <span>最高佣金(以10000为例)：{{currentUser.juniorRateAliMax +' * 10000 = '+currentUser.juniorRateAliMax*10000}}</span>
            </el-form-item>
            <el-form-item label="银行卡佣金" prop="juniorRateBank">
              <el-input-number :class="{'apply-shake': valid.juniorRateBank}"
                               :step="0.001"
                               :min="0"
                               placeholder="请输入银行卡佣金"
                               v-model.trim="dataForm.juniorRateBank"></el-input-number><br>
              <span>当前佣金点数(以10000为例)：<span class="font-red">{{dataForm.juniorRateBank}}</span> * 10000 = </span>
              <span class="font-red">{{dataForm.juniorRateBank*10000}}</span><br>
              <span>最高佣金(以10000为例)：{{currentUser.juniorRateBankMax +' * 10000 = '+currentUser.juniorRateBankMax*10000}}</span>
            </el-form-item>
            <el-form-item>
              <el-button type="info" @click="handleClose">取  消</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')">确  定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dictTransfer from "@/mixin/DictTransfer";
import paging from "@/components/Paging";
import constant from "@/constant";
import echarts from "@/mixin/echarts";
import H5Row from "@/components/h5-row.vue";
import {commissionAPI, memberPageAPI, userInfoAPI, userModifyAPI, withdrawAPI} from "@/api/user";
import DateRange from "@/components/DateRange.vue";
import Copy from "@/components/Copy.vue";
import common from "@/mixin/common";
let that=''
const checkAli =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.toAccount=true
    setTimeout(()=>{
      if (that) {
        that.valid.juniorRateAli=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkBank =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.toAccount=true
    setTimeout(()=>{
      if (that) {
        that.valid.juniorRateBank=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
export default {
  name: "TeamTable",
  mixins:[dictTransfer,common],
  components:{Copy, DateRange, H5Row, paging},
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        {align:"center",label:"下级昵称",prop:"nickName",width:150,template:false,params:''},
        {align:"center",label:"支付宝销售金额",prop:"amountAli",width:180,template:false,params:''},
        {align:"center",label:"银行卡销售金额",prop:"amountBank",width:180,template:false,params:''},
        {align:"center",label:"总销售金额",prop:"total",width:180,template:true,params:'total'},
        {align:"center",label:"操作",prop:"answer",width:180,template:true,params:'control'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        nickName:'',
        startTime: '',
        endTime: '',
      },
      ids:[],
      options:constant.payState,
      option:{
        title: {
          text: '团队报表'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['张三', '李四', '赛文', '大炮', '铁柱']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '张三',
            type: 'line',
            stack: '总量',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '李四',
            type: 'line',
            stack: '总量',
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '赛文',
            type: 'line',
            stack: '总量',
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: '大炮',
            type: 'line',
            stack: '总量',
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: '铁柱',
            type: 'line',
            stack: '总量',
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      },
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dialogVisible: false,
      dataForm:{
        juniorRateBank: 0,
        juniorRateAli:0,
        id: '',
        superiorsId: '',
      },
      rules:{
        juniorRateAli:[{validator:checkAli, trigger: "blur"},],
        juniorRateBank: [{validator:checkBank, trigger: "blur"},],
      },
      valid:{
        juniorRateBank: false,
        juniorRateAli:false,
      },
      currentUser: '',
    }
  },
  mounted() {
    this.init()
    //this.initEcharts()
    this.$bus.$on('refreshLayout',()=>{
      this.init()
    })
    that=this
  },
  computed: {},
  watch: {},
  methods: {
    init(){
      if (this.$terminal) {
        this.form.startTime=this.formatDate(this.timeArr[0],'start');
        this.form.endTime=this.formatDate(this.timeArr[1],'end')
      } else {
        this.form.startTime=this.timeArr[0];
        this.form.endTime=this.timeArr[1]
      }
      memberPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
        }
      })
      userInfoAPI(JSON.parse(localStorage.getItem('userInfo')).id).then(res=>{
        if (res.code === 200) {
          this.currentUser=res.data
        }
      })
    },
    rowClick(row) {
      //todo 扩展点
      this.dialogVisible=true
      let form = JSON.parse(JSON.stringify(row));
      this.dataForm.juniorRateAli=form.juniorRateAli
      this.dataForm.juniorRateBank=form.juniorRateBank
      this.dataForm.brokerageAliId=row.brokerageAliId
      this.dataForm.brokerageBankId=row.brokerageBankId
    },
    handleClose(){
      this.dialogVisible=false
      this.$refs['ruleForm'].resetFields();
    },
    withdraw(params){
      let id
      if (this.loginInfo.roleId === 1) {
        id=params.id
      } else {
        id=this.loginInfo.id
      }
      withdrawAPI(id).then(res=>{
        if (res.code === 200) {
          this.ok(res.msg)
          this.init()
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dataForm.roleId=2
          commissionAPI(this.dataForm).then(res=>{
            if (res.code === 200) {
              this.init()
              this.dialogVisible=false
            }
          })
        } else {
          return false;
        }
      });
    },
    initEcharts(){
      let chartDom = document.getElementById('echarts');
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
  },
  filters: {},
  beforeDestroy() {
    this.$bus.$off('refreshLayout')
    that=null
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.stimulate-div{
  margin-top: 10px;
  background-color: #c2c4ca;
  border-radius: 10px;
  //height: 30px;
  padding: 10px;
  .paragraph{
    text-indent: 2em;
    font-size: 14px;
    margin: 10px 0;
  }
}
.earnings-d{
  margin-top: 10px;
}
</style>
