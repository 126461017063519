import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.settings.showSpinner = false
import Login from "@/views/login/Login";
import {routerhandle} from "@/router/routesmap";
Vue.use(VueRouter)
import i18n from "@/i18n";
import store from "@/store";

let routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {title: 'login'}
    },
    /*{
        path: '/',
        name: '',
        component:()=>import('@/components/Layout') ,
        children: [
            {
                path: 'marketplace',
                name: 'marketplace',
                component: () => import("@/views/marketplace/Marketplace"),
                meta: {title: 'marketplace', icon: 'el-icon-position'}
            },
            {
                path: '/trading',
                name: 'trading',
                component: () => import('@/views/trading/Trading'),
                meta: {title: 'trading', icon: 'el-icon-shopping-cart-1'}
            },
            {
                path: '/history',
                name: 'history',
                component: () => import('@/views/history/History'),
                meta: {title: 'history', icon: 'el-icon-notebook-2'}
            },
            {
                path: '/userinfo',
                name: 'userinfo',
                component: () => import('@/views/info/UserInfo'),
                meta: {title: 'userinfo', icon: 'el-icon-user'}
            },
            {
                path: 'admin',
                name: 'admin',
                component: () => import('@/views/admin/Admin'),
                meta: {title: 'admin', icon: 'el-icon-setting'},
                children: [
                    {
                        path: 'user',
                        name: 'adminUser',
                        component: () => import('@/views/admin/user/User'),
                        meta: {title: 'adminUser', icon: 'el-icon-user'}
                    },
                    {
                        path: '/admin/business',
                        name: 'adminBusiness',
                        component: () => import('@/views/admin/business/Business.vue'),
                        meta: {title: 'adminBusiness', icon: 'el-icon-shopping-cart-1'}
                    },
                    {
                        path: '/admin/business-list',
                        name: 'businessList',
                        component: () => import('@/views/admin/business/BusinessList.vue'),
                        meta: {title: 'businessList', icon: 'el-icon-goods'}
                    },
                    {
                        path: '/admin/setting',
                        name: 'adminSetting',
                        component: () => import('@/views/admin/setting/Setting.vue'),
                        meta: {title: 'adminSetting', icon: 'el-icon-setting'}
                    },
                ]
            },
        ]
    },*/
    {
        path: '*', //会匹配所有路径
        name: 'notFound',
        component: () => import('../components/NotFound'),
        meta: {title: 'notFound'}
    },
    {
        path: '/register',
        component: Login,
        meta: {title: 'register'}
    },
]
const menuList = JSON.parse(localStorage.getItem('menuList'))
if(menuList){
    const rt = routerhandle(menuList)
    routes = [...routes,...rt]
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    store.dispatch('isH5')
    NProgress.start()
    next()
})
router.afterEach((to, from) => {
    NProgress.done()
    changeTitle(to.meta.title)
})

/**
 * 每一次跳转路由时 修改标题的内容
 * @param name
 */
export function changeTitle(name) {
    document.title = i18n.t('router.' + name)
}

/**
 * 用于在切换国际化时 修改标题的内容
 */
export function getPath() {
    document.title = i18n.t('router.' + router.currentRoute.meta.title)
}

/**
 * 获得路由列表
 * @returns {*[]}
 */
export function getRouteList() {
    let arr = []
    // routes[1].children.forEach(item => {
    //     arr.push({name: i18n.t('router.' + item.meta.title), path: item.path, icon: item.meta.icon})
    // })
    router.getRoutes().forEach(item=>{
        if (item.meta.id && item.meta.show===1) {
            if (item.meta.id>=3 && item.meta.id<=7) {
                arr.push({name: i18n.t('router.' + item.meta.title), path: item.path, icon: item.meta.icon})
            }
        }
    })
    return arr
}

export function getAdminRouteList() {
    let arr = []
    // routes[1].children[4].children.forEach(item => {
    //     arr.push({name: i18n.t('router.' + item.meta.title), path: item.path, icon: item.meta.icon})
    // })
    /*router.getRoutes().forEach(item=>{
        if (item.meta.id) {
            if (item.meta.id>=8 && item.meta.id<=11) {
                arr.push({name: i18n.t('router.' + item.meta.title), path: item.path, icon: item.meta.icon})
            }
        }
    })*/

    JSON.parse(localStorage.getItem('menuList'))[0].children.forEach(item=>{
        if (item.name === 'admin') {
            item.children.forEach(a=>{
                arr.push({name: i18n.t('router.' + a.name), path: a.path, icon: a.icon})
            })
        }
    })
    return arr
}

export default router
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
