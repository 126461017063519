<template>
  <div>
    <!--pc-->
    <div v-if="$terminal">
      <span class="team-title">收银台</span>
      <div style="display: flex;width: 100%">
        <div style="flex: 0 0 40%;line-height: 31px">
          <span>订单号: </span><span title="复制" class="font-fine copy-underline" v-copy>{{form.id}} <i class="el-icon-copy-document"/></span><br>
          <span>付款方式: </span><span class="font-fine">{{form.payType|payTypeTransfer}}</span><br>
          <div v-role="'管理员,客服,手动获取,自动获取'">
            <!--            <span v-if="form.businessAccount">码商帐号: </span><span title="复制" class="copy-underline font-fine" v-copy>{{form.businessAccount}} <i class="el-icon-copy-document"/><br></span>-->
            <span >码商姓名: </span><span title="复制" class="copy-underline font-fine" v-copy>{{form.bRealName}} <i class="el-icon-copy-document"/><br></span>
            <span>码商收款帐号: </span><span title="复制" class="copy-underline font-fine" v-copy>{{form.bCollAccount}} <i class="el-icon-copy-document"/><br></span>
            <span v-if="form.payType===2">银行名称: </span><span v-if="form.payType===2" title="复制" class="copy-underline font-fine" v-copy>{{businessInfo.bankName}} <i class="el-icon-copy-document"/><br></span>
            <span v-if="form.payType===2">开户行: </span><span v-if="form.payType===2" title="复制" class="copy-underline font-fine" v-copy>{{businessInfo.bankAddress}} <i class="el-icon-copy-document"/><br></span>
          </div>
          <!--<span>码商昵称: </span><span title="复制" class="copy-underline font-fine" v-copy>{{form.bNickName}} <i class="el-icon-copy-document"/></span><br>-->
          <!--<span>平台昵称: </span><span title="复制" class="copy-underline font-fine" v-copy>{{form.cPayName}}<i class="el-icon-copy-document"/></span><br>-->
          <div v-role="'管理员,客服,码商'">
            <div v-if="form.consumerAccount">
              <span>手动获取帐号: </span><span title="复制" class="copy-underline font-fine" v-copy>{{form.consumerAccount}}<i class="el-icon-copy-document"/><br></span>
            </div>
            <span>手动获取支付帐号: </span><span title="复制" class="copy-underline font-fine" v-copy>{{form.cPayAccount}}<i class="el-icon-copy-document"/><br></span>
<!--            <div v-if="form.payType===2 && form.cPayName">
              <span>手动获取,自动获取开户行地址: </span><span v-if="form.payType===2" title="复制" class="copy-underline font-fine" v-copy>{{form.cPayName}}<i class="el-icon-copy-document"/></span>
            </div>-->
          </div>
          <span>订单创建时间: </span><span>{{form.createTime}}</span>
        </div>
        <div style="flex: 1;">
          <span @click="newOrder" class="money-btn-payed" style="background-color: #E6A23C">再来一单</span>
          <span @click="checkedPay" class="money-btn-payed">已支付</span>
          <span class="money-btn">结算¥: {{form.amount}} 元</span>
          <span>请在5分钟内完成付款 ,否则系统将自动取消订单</span><br/>
          <span>剩余支付时间</span>
          <div style="color: red;margin-top: 10px">
            <span style="font-size: 54px">{{countdown.minutes}}</span>
            <span style="font-size: 25px">分</span>
            <span style="font-size: 54px">{{countdown.seconds}}</span>
            <span style="font-size: 25px">秒</span>
          </div>
          <div class="stimulate-div" style="height: auto">
            <span class="paragraph bold">说明</span>
            <p class="paragraph text-line-height">1.金额错误或者重复打款将无法到账!!!</p>
            <p class="paragraph text-line-height">2.请勿使用「花呗支付」会多收手续费</p>
            <p class="paragraph text-line-height">3.「花呗支付」请自费手续费</p>
          </div>
        </div>
      </div>
      <el-divider/>
      <el-dialog
          v-if="$terminal"
          title="上传支付凭证"
          :visible.sync="dialogVisible"
          width="30%"
          :modal="false"
          :before-close="handleClose">
        <m-upload @success="handleSuccess" :upload-url="uploadUrl"></m-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-popconfirm
              :disabled="this.submitForm.urlId===''"
              class="common-left-space"
              confirm-button-text='确定'
              cancel-button-text='重新上传'
              icon="el-icon-info"
              icon-color="red"
              @confirm="payed"
              title="确认上传完成了吗？">
<!--          <el-button type="primary" :disabled="this.submitForm.urlId===''" slot="reference">保 存</el-button>-->
          <el-button type="primary" slot="reference">保 存</el-button>
        </el-popconfirm>
        </span>
      </el-dialog>
    </div>
    <!--移动端-->
    <div v-else>
      <div class="h5-content">
        <h5-row label="订单号">
          <template slot="value">
            <Copy :text="form.id"/>
          </template>
        </h5-row>
        <h5-row label="付款方式">
          <template slot="value">
            {{form.payType|payTypeTransfer}}
          </template>
        </h5-row>
        <h5-row v-if="form.payType===2" label="银行名称" :value="businessInfo.bankName"></h5-row>
        <h5-row label="码商昵称">
          <template slot="value">
            <Copy :text="form.bNickName"/>
          </template>
        </h5-row>
        <h5-row label="码商姓名">
          <template slot="value">
            <Copy :text="form.bRealName"/>
          </template>
        </h5-row>
        <h5-row label="码商收款帐号">
          <template slot="value">
            <Copy :text="form.bCollAccount"/>
          </template>
        </h5-row>
        <h5-row v-if="form.payType===2" label="码商开户行" :value="businessInfo.bankAddress"></h5-row>
        <h5-row label="平台昵称" :value="form.cPayName"></h5-row>
        <h5-row label="手动获取支付帐号" :value="form.cPayAccount">
          <template slot="value">
            <Copy :text="form.cPayAccount"/>
          </template>
        </h5-row>
        <h5-row label="创建时间" :value="form.createTime"></h5-row>
        <h5-row label="订单金额" :value="form.amount"></h5-row>
        <div style="display: flex;justify-content: space-between;align-items: end">
          <div class="label-height">
            <div style="margin-bottom: 5px">剩余支付时间</div>
          </div>
          <div slot="value" style="color: red;margin-top: 10px">
            <span style="font-size: 54px">{{countdown.minutes}}</span>
            <span style="font-size: 25px">分</span>
            <span style="font-size: 54px">{{countdown.seconds}}</span>
            <span style="font-size: 25px">秒</span>
          </div>
        </div><hr>
        <div class="h5-row-item-right" style="margin-top: 10px">
          <button @click="newOrder" class="el-button--warning">再来一单</button>
          <el-button type="success" size="small" class="common-left-space" @click="checkedPay">已支付</el-button>
          <button v-if="form.payType===1&&false" @click="openAliPay" class="el-button--success common-left-space">启动支付宝</button>
        </div>
        <div class="stimulate-div" style="height: auto">
          <p class="paragraph bold">支付流程</p>
          <p class="paragraph text-line-height-h5">首页-转帐-转到支付宝</p>
          <p class="paragraph text-line-height-h5">输入页面上提供的支付宝帐户-输入页面上提供的支付宝姓氏首字</p>
          <p style="color: red" class="paragraph text-line-height-h5">※如使用'花呗'付款，需自行负担衍生之手续费</p>
          <p style="color: red" class="paragraph text-line-height-h5">{{`请在${countdown.minutes}分${countdown.seconds}秒内完成支付，超时将无法到账！！`}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import constant from "@/constant";
import dictTransfer from "@/mixin/DictTransfer";
import common from "@/mixin/common";
import H5Row from "@/components/h5-row.vue";
import Copy from "@/components/Copy.vue";
import MUpload from "@/components/M-Upload.vue";
import {orderFileAPI} from "@/api/order";
let start
export default {
  name: "CheckStand",
  watch: {
    'countdown.totalSeconds':{
      handler(val,oldVal){
        if (val <= 0) {
          clearInterval(this.countdown.timer)
          this.countdown.timer = null
        }
      },
      immediate:false
    }
  },
  components: {MUpload, Copy, H5Row},
  mixins: [dictTransfer,common],
  props: {
    form:{
      type: Object,
      default(){
        return {
          id:'',
          userId:'',
          state:'',
          amount:'',
          payType:2,
          createTime:'',
          payTime:'',
          businessId:'',
          remark:'',
          changeRemark:'',
          uCount:0,
          closeTime:'',
          updateTime:'',
          orderVersion:0,
          transactionRate:0,
          bUsername: "",
          bRealName: "",
          bCollAccount: "",
          businessAccount: '',
          consumerAccount: '',
          cRealName: '',
          cPayAccount: '',
          cPayName: '',
        }
      }
    }
  },
  data() {
    return {
      payTypeList: constant.payType,
      countdown:{
        minutes:0,
        seconds:0,
        timer:null,
        totalSeconds:0
      },
      businessInfo:{},
      dialogVisible:false,
      uploadUrl: '',
      submitForm:{
        urlId:'',
        cRealName:'',
        cPayAccount:'',
        remark: '',
      }
    }
  },
  mounted() {
    start = setInterval(()=>{
      if (this.form.totalSeconds > 0) {
        clearInterval(start)
        this.countdown.totalSeconds=this.form.totalSeconds
        this.startCountDown();
      }
    },100)
    if (this.form.bUsername !== '' && this.form.bUsername.startsWith('{')) {
      this.businessInfo=JSON.parse(this.form.bUsername)
    }
  },
  methods: {
    handleClose(){
      this.submitForm.urlId=''
      this.dialogVisible=false
      this.dialogModifyVisible=false
      this.remark=''
    },
    handleSuccess(){
      this.submitForm.urlId=this.uploadUrl.substring(this.uploadUrl.lastIndexOf('/') + 1, this.uploadUrl.indexOf('.png'))
    },
    getLoadLink(params){
      orderFileAPI().then(res=>{
        if (res.code === 200) {
          this.uploadUrl=res.data
          this.dialogVisible=true
          this.tempParam=params
        }
      })
    },
    payed(){

    },
    startCountDown(){
      // noinspection JSCheckFunctionSignatures
      let m = parseInt(this.countdown.totalSeconds / 60 % 60);
      this.countdown.minutes = m < 10 ? "0" + m : m
      // noinspection JSCheckFunctionSignatures
      let s = parseInt(this.countdown.totalSeconds % 60);
      this.countdown.seconds = s < 10 ? "0" + s : s
      //this.count = m + '分' + s + '秒'
      if (this.countdown.totalSeconds <= 0) {
        this.newOrder()
        clearInterval(this.countdown.timer)
        clearInterval(start)
        this.countdown.timer = null
        start=null
      }
      if (this.countdown.totalSeconds > 0) {
        if (this.countdown.timer === null) {
          this.countdown.timer = setInterval(() => {
            this.countdown.totalSeconds--
            this.startCountDown()
          }, 1000);
        }
      }
      if (this.form.state !== 0) {
        clearInterval(this.countdown.timer)
        clearInterval(start)
        this.countdown.timer = null
        start=null
      }
    },
    newOrder(){
      this.$emit('refresh',true)
    },
    checkedPay() {
      this.$emit('refresh',true)
      this.$bus.$emit('payedApi',this.form)
    },
    openAliPay(){
      // window.open(`alipays://platformapi/startapp?appId=20000067&url=`)
      window.open(`alipays://platformapi/startapp`)
    },
  },
  filters:{},
  beforeDestroy() {
    clearInterval(this.countdown.timer)
    clearInterval(start)
    this.countdown.timer = null
    start=null
  },
}

</script>

<style scoped lang="scss">
@import "../assets/style/conmmon-style.scss";
.font-fine{
  font-weight: 400;
}
.money-btn{
  background-color: #F56C6C;
  padding: 10px 27px;
  margin: 33px auto;
  font-size: 28px;
  border-radius: 30px;
  color: white;
  float: right;
}
.money-btn-payed {
  background-color: #67C23A;
  padding: 10px 27px 10px 27px;
  font-size: 20px;
  border-radius: 30px;
  color: white;
  float: right;
  margin: 42px auto 33px 10px;
  cursor: pointer;
}
</style>
