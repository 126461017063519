//npm install crypto-js
const CryptoJS = require('crypto-js');  //引用AES源码js
const key = CryptoJS.enc.Utf8.parse("1234123412ABCDEF");  //十六位十六进制数作为密钥
// const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412');   //十六位十六进制数作为密钥偏移量 可用可不用

//加密方法
export function Encrypt(word){
    // 法一：加密后转化为base64
    // let srcs = CryptoJS.enc.Utf8.parse(word);
    // let encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    // return encrypted.ciphertext.toString();

    // 法二：不转
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
}

export function AESKey(){
    //const key = CryptoJS.lib.WordArray.random(32);
    const key = CryptoJS.lib.WordArray.random(16);
    let keyStringHex = key.toString(CryptoJS.enc.Hex);
    console.log("密钥（十六进制字符串）:", keyStringHex);
    return key;//256位
}

//console.log(AESKey())
//解密方法
export function Decrypt(word){
    // 法一：对应解密
    // let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    // let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    // let decrypt = CryptoJS.AES.decrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    // let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    // return decryptedStr.toString();

    // 法二：
    const decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
