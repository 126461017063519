import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import elementUI, {Message} from 'element-ui'
import './assets/style/reset.css'
import 'element-ui/lib/theme-chalk/index.css';
import i18n from "@/i18n";

Vue.config.productionTip = false
Vue.use(elementUI);
Vue.use(elementUI, {
  size: 'default',
  i18n: (key, value) => i18n.t(key, value)
})
//https://mint-ui.github.io/docs/#/zh-cn2/repositories
//import MintUI from 'mint-ui'
//import 'mint-ui/lib/style.css'
//Vue.use(MintUI)

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    Vue.prototype.$bus=this
  },
  render: h => h(App)
}).$mount('#app')
function checkPermission (key) {
  if (store.state.permissions.length === 0) {
    store.state.permissions=JSON.parse(localStorage.getItem('userInfo')).permissions
  }
  let index = store.state.permissions.indexOf(key)
  return index > -1;
}

Vue.prototype.$checkRole = checkRole;
function checkRole (role) {
  if (store.state.userInfo === null) {
    store.state.userInfo=JSON.parse(localStorage.getItem('userInfo'));
  }
  let name = store.state.userInfo.roleName
  if (name === '超级管理员') {
    name='管理员'
  }
  return role.includes(name);
}

Vue.directive("role",{
  bind () {
    // 普通的log 在bind周期
  },
  inserted(el, binding, vnode) {
    // Vue.nextTick(() => {
    //
    // });

    let role = binding.value;
    if (role) {
      let hasRole = checkRole(role);
      if (!hasRole) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  },
  update () {
  },
  componentUpdated(el, binding) {
  },

  unbind () {
  }
})

Vue.directive("auth",{
  inserted(el,binding){
    // 获取到 v-permission的值
    let permission = binding.value;
    console.log(binding)
    if (permission) {
      let hasPermission = checkPermission(permission);
      if (!hasPermission) { // 没有权限 移除Dom元素
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  }
})
import clipboard from 'clipboard';
Vue.directive("copy",{
  bind(el, binding) {
    const copyHandler = () => {
      clipboard.copy(el.innerText)
      Message.success({
        message: '复制成功',
        showClose:true,
        duration:500
      })

    };
    el.addEventListener('click', copyHandler);
  },
  unbind(el) {
    const copyHandler = () => {};
    el.removeEventListener('click',copyHandler);
  }
})

// npm install vconsole
// import VConsole from 'vconsole'
// let vConsole = new VConsole();
// Vue.use(vConsole)
import {Alert, Confirm, Toast} from 'wc-messagebox'
Vue.use(Confirm)
// import 'wc-messagebox/style.css'
// Vue.use(Alert, options)
// Vue.use(Toast, options)
