<template>
  <Business/>
</template>
<script>
import paging from "@/components/Paging";
import SaveOrUpdateUser from "@/views/info/SaveOrUpdateUser";
import constant from "@/constant";
import {businessPageAPI, businessSortAPI, userDeleteAPI, userPageAPI} from "@/api/user";
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import {controlAPI} from "@/api/Login";
import Business from "@/views/admin/business/Business.vue";
export default {
  name: "AdminBusiness",
  components: {Business}
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
  }
}
</style>
