<template>
  <div>
    <div class="content-main-padding">
      <el-select v-model="form.roleId"
                 clearable
                 @change="init"
                 class="common-left-space"
                 placeholder="用户类别">
        <el-option
            v-for="item in roleList"
            :key="item.type"
            :label="item.name"
            :value="item.type">
        </el-option>
      </el-select>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 600px;margin-top: 10px"
          tooltip-effect="dark"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         align="center"
                         v-if="false"
                         width="55">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template"
                           :show-overflow-tooltip="false"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :fixed="item.fixed"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else-if="item.template"
                           :show-overflow-tooltip="false"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link type="danger"
                         class="common-left-space"
                         size="mini"
                         @click="logout(scope.row)">下线</el-link>
              </template>
              <template v-if="item.params==='userState'">

              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>

import {controlUserAPI, loginUserListAPI} from "@/api/user";
import constant from "@/constant";

export default {
  name: "LoginUser",
  data() {
    return {
      tableData: {
        list: [],
        total: 0
      },
      options:constant.userState,
      roleList:constant.roleList,
      form:{
        pageNum:1,
        pageSize:10,
        userState: 1,
        account: '',
        roleId: '',
      },
      columnList:[
        {align:"center",label:"昵称",prop:"nickName",width:'auto',template:false,params:'juniorCount',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"角色",prop:"roleName",width:'auto',template:false,params:'',fixed:false,type:'支付宝,银行卡'},
        // {align:"center",label:"盈利状态",prop:"businessState",width:'auto',template:false,params:'',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"操作",prop:"answer",width:180,template:true,params:'control',fixed:'',type:'支付宝,银行卡'},
      ],
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      let form=JSON.parse(JSON.stringify(this.form))
      console.log(form,this.form.roleId == "3"||this.form.roleId == "4")
      // noinspection EqualityComparisonWithCoercionJS
      if (this.form.roleId == "3"||this.form.roleId == "4") {
        form.roleId='3,4'
      }
      loginUserListAPI(form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data
        }
      })
    },
    logout(params){
      let form = {
        token:params.token,
        type:0
      }
      controlUserAPI(form).then(res=>{
        if (res.code === 200) {
          this.$message.success({message:res.msg,showClose:true})
          this.init()
        }
      })
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
  }
}
</style>
