import request from "@/utils/request";
import {Encrypt} from "@/utils/CryptoJS";

/**
 * 登录
 */
export function loginAPI(data) {
  return request({
    url: 'login',
    method: 'post',
    data: data,
    headers:{
      'terminal':Encrypt(1)
    }
  })
}
/**
 * 验证码
 */
export function codeAPI(){
  return request({
    url: 'code',
    method: 'get',
  })
}
/**
 *退出登录
 */
export function logoutAPI() {
  return request({
    url: 'logout',
    method: 'delete',
  })
}

export function registerAPI(data) {
  return request({
    url: 'register',
    method: 'post',
    data:data
  })
}

/**
 * 生成随机验证码
 * @param data
 * @returns {*}
 */
export function onePwdAPI(data) {
  return request({
    url: 'oneTimePwd',
    method: 'post',
    data:data
  })
}

/**
 * 验证动态密码
 * @returns {*}
 */
export function verifyOnePwdAPI(data) {
  return request({
    url: 'verify/'+data,
    method: 'post'
  })
}

export function existAPI(data) {
  return request({
    url: 'exist',
    method: 'post',
    data:data
  })
}

export function modifyPwdAPI(data) {
  return request({
    url: 'user/pwd',
    method: 'put',
    data:data
  })
}

export function verifyPwdAPI(data) {
  return request({
    url: 'auth',
    method: 'post',
    data:data
  })
}

export function resetDynamicAPI(id) {
  return request({
    url: 'reset/dynamic'+id,
    method: 'put',
  })
}

export function dynamicAPI() {
  return request({
    url: 'dynamic',
    method: 'get',
  })
}

export function dynamicAdminAPI(userId) {
  return request({
    url: 'dynamic?userId='+userId,
    method: 'get',
  })
}

/**
 * 控制
 * @param control 0通过申请 1重置密码
 * @param id
 * @returns {*}
 */
export function controlAPI(control, id) {
  return request({
    url: `opt/${control}/${id}`,
    method: 'put',
  })
}

/**
 * 发送邮件验证码
 * @param data
 * @returns {*}
 */
export function sendMailAPI(data) {
  return request({
    url: `mail`,
    method: 'post',
    data:data
  })
}

/**
 * 验证邮件验证码
 * @param data
 * @returns {*}
 */
export function mailCodeVerifyAPI(data) {
  return request({
    url: `mail`,
    method: 'put',
    data:data
  })
}


