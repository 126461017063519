<template>
  <div>
    <span style="font-size: 16px">{{ countdown.minutes }}</span>
    <span style="font-size: 16px">分</span>
    <span style="font-size: 16px">{{ countdown.seconds }}</span>
    <span style="font-size: 16px">秒</span>
  </div>
</template>

<script>
export default {
  name: "Countdown",
  props: {
    total: {
      type: Number,
      default: 0
    },
    start:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      countdown: {
        totalSeconds: 0,
        minutes: 0,
        seconds: 0,
        timer: null
      }
    }
  },
  mounted() {
    this.countdown.totalSeconds = this.total
    if (this.total > 0) {
      this.startCountDown();
    }
  },
  methods: {
    startCountDown() {
      // noinspection JSCheckFunctionSignatures
      let m = parseInt(this.countdown.totalSeconds / 60 % 60);
      this.countdown.minutes = m < 10 ? "0" + m : m
      // noinspection JSCheckFunctionSignatures
      let s = parseInt(this.countdown.totalSeconds % 60);
      this.countdown.seconds = s < 10 ? "0" + s : s
      if (this.countdown.totalSeconds <= 0) {
        this.$emit('refresh',true);
        clearInterval(this.countdown.timer);
        this.countdown.timer = null;
      }
      if (this.countdown.totalSeconds > 0) {
        if (this.countdown.timer === null) {
          this.countdown.timer = setInterval(() => {
            this.countdown.totalSeconds--
            this.startCountDown()
          }, 1000);
        }
      }
      if (!this.start) {
        clearInterval(this.countdown.timer);
        this.countdown.timer = null;
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.countdown.timer)
    this.countdown.timer = null
  }
}
</script>

<style scoped lang="scss">

</style>
