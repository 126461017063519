<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="充值" name="one" style="margin-top: 15px">
          <div v-role="'管理员,客服'" >
            <el-date-picker
                v-model="timeArr"
                type="daterange"
                align="left"
                @change="init"
                format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>


            <el-select clearable class="common-left-space" @change="init" v-model="queryForm.state" placeholder="请选择">
              <el-option
                  v-for="item in topUpStateList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type">
              </el-option>
            </el-select>
            <el-button @click="queryForm.userId='';init()" type="primary" class="common-left-space">{{$t('table.resetSearch')}} <i class="el-icon-search"></i> </el-button>
            <el-button icon="el-icon-orange"
                       @click="syncRecords"
                       v-role="'管理员'"
                       type="success"
                       class="common-left-space">同步充值记录</el-button>
            <el-input
                @keyup.enter.native="init"
                clearable
                show-word-limit
                style="width: 300px;float:right;"
                v-model="queryForm.hash"
                placeholder="输入交易hash查询">
              <el-button @click="init" slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
          <div style="margin: 10px 0" v-role="'码商'">
            <span style="font-weight: bold">充值汇率: </span>{{userInfo.platformRate}}&nbsp;&nbsp;&nbsp;
            <span style="font-weight: bold">可用资产: </span>{{userInfo.usdtBalance}}&nbsp;TCCOIM &nbsp;&nbsp;&nbsp;
            <span style="font-weight: bold">充值地址: </span><Copy :text="usdtAddress"></Copy>
          </div>
          <div v-role="'管理员,客服'" class="flex-around">
            <ConsumerUser @refresh="init" style="width: 10%;height: fit-content"/>
            <div style="width: 89%">
              <el-table
                  ref="multipleTable"
                  :header-row-style="{'color':'gray'}"
                  border
                  lazy
                  :data="tableData.records"
                  style="width: 100%;margin-top: 10px"
                  tooltip-effect="dark"
                  @selection-change="handleSelectionChange"
                  @sort-change="sortChange"
                  @expand-change="handleExpandChange"
                  :row-class-name="tableRowClassName">
                <el-table-column type="selection"
                                 v-if="false"
                                 width="40">
                </el-table-column>
                <template v-for="(item,index) in columnList">
                  <el-table-column :align="item.align"
                                   v-if="!item.template"
                                   :show-overflow-tooltip="true"
                                   :width="item.width"
                                   :prop="item.prop"
                                   :key="index"
                                   :label="item.label">
                  </el-table-column>
                  <el-table-column :align="item.align"
                                   v-else
                                   :show-overflow-tooltip="false"
                                   :width="item.width"
                                   :prop="item.prop"
                                   :key="index"
                                   :label="item.label">
                    <template slot-scope="scope">
                      <template v-if="item.params==='control'">
                        <!--详细信息-->
                        <el-link type="primary" @click="goOfficialWebsite(scope.row)">官网验证</el-link>
                        <el-popconfirm
                            v-if="scope.row.state===0||scope.row.state===3"
                            v-role="'管理员,客服'"
                            class="common-left-space"
                            confirm-button-text='确定'
                            cancel-button-text='取消'
                            icon="el-icon-info"
                            icon-color="red"
                            @confirm="topUpPass(scope.row)"
                            title="确定到账吗？">
                          <el-link type="danger" slot="reference">确认到账</el-link>
                        </el-popconfirm>
                        <el-popconfirm
                            v-if="scope.row.state===0"
                            v-role="'管理员,客服'"
                            class="common-left-space"
                            confirm-button-text='确定'
                            cancel-button-text='取消'
                            icon="el-icon-info"
                            icon-color="red"
                            @confirm="topUpPassd(scope.row)"
                            title="确定忽略吗？">
                          <el-link type="success" slot="reference">忽略</el-link>
                        </el-popconfirm>
                      </template>
                      <template v-else-if="item.params==='payState'">
                        <el-tag :type="scope.row.state===0 ?
                'warning' : scope.row.state===1 ?
                'success':scope.row.state===2 ?
                'danger':scope.row.state===3 ? 'warning' : ''"
                                size="mini">{{scope.row.state|dictTransfer(topUpStateList)}}</el-tag>
                      </template>
                      <template v-else-if="item.params==='payType'">
                        {{scope.row.payType|payTypeTransfer()}}
                      </template>
                      <template v-else-if="item.params==='id'">
                        <span v-copy class="font-fine copy-underline">{{scope.row.id}}</span>
                      </template>
                      <template v-else-if="item.params==='cPayAccount'">
                        <span v-copy class="font-fine copy-underline">{{scope.row.cPayAccount}}</span>
                      </template>
                      <template v-else-if="item.params==='block_timestamp'">
                        {{formatDate(new Date(scope.row.block_timestamp))}}
                      </template>
                      <template v-else-if="item.params==='rate'">
                        {{scope.row.realRate}}
                      </template>
                      <template v-else-if="item.params==='platformAmount'">
                        {{ Number(scope.row.platformAmount).toFixed(2) }}
                      </template>
                      <template v-else-if="item.params==='amount'">
                        {{ Number(scope.row.amount).toFixed(2) }}
                      </template>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
              <paging
                  :page-sizes="[10,20,25,30,40]"
                  :update:page="queryForm.pageNum"
                  :update:limit="queryForm.pageSize"
                  :total="tableData.total"
                  @changeSize="changeSize"
                  @pagination="pagination"/>
            </div>
          </div>
          <div v-role="'码商'">
            <el-table
                ref="multipleTable"
                :header-row-style="{'color':'gray'}"
                border
                lazy
                :data="tableData.records"
                style="width: 100%;margin-top: 10px"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                @sort-change="sortChange"
                @expand-change="handleExpandChange"
                :row-class-name="tableRowClassName">
              <el-table-column type="selection"
                               v-if="false"
                               width="40">
              </el-table-column>
              <template v-for="(item,index) in columnList">
                <el-table-column :align="item.align"
                                 v-if="!item.template"
                                 :show-overflow-tooltip="true"
                                 :width="item.width"
                                 :prop="item.prop"
                                 :key="index"
                                 :label="item.label">
                </el-table-column>
                <el-table-column :align="item.align"
                                 v-else
                                 :show-overflow-tooltip="false"
                                 :width="item.width"
                                 :prop="item.prop"
                                 :key="index"
                                 :label="item.label">
                  <template slot-scope="scope">
                    <template v-if="item.params==='control'">
                      <!--详细信息-->
                      <el-link type="primary" @click="goOfficialWebsite(scope.row)">官网验证</el-link>
                      <el-popconfirm
                          v-if="scope.row.state===0||scope.row.state===3"
                          v-role="'管理员,客服'"
                          class="common-left-space"
                          confirm-button-text='确定'
                          cancel-button-text='取消'
                          icon="el-icon-info"
                          icon-color="red"
                          @confirm="topUpPass(scope.row)"
                          title="确定到账吗？">
                        <el-link type="danger" slot="reference">确认到账</el-link>
                      </el-popconfirm>
                      <el-popconfirm
                          v-if="scope.row.state===0"
                          v-role="'管理员,客服'"
                          class="common-left-space"
                          confirm-button-text='确定'
                          cancel-button-text='取消'
                          icon="el-icon-info"
                          icon-color="red"
                          @confirm="topUpPassd(scope.row)"
                          title="确定忽略吗？">
                        <el-link type="success" slot="reference">忽略</el-link>
                      </el-popconfirm>
                    </template>
                    <template v-else-if="item.params==='payState'">
                      <el-tag :type="scope.row.state===0 ?
                'warning' : scope.row.state===1 ?
                'success':scope.row.state===2 ?
                'danger':scope.row.state===3 ? 'warning' : ''"
                              size="mini">{{scope.row.state|dictTransfer(topUpStateList)}}</el-tag>
                    </template>
                    <template v-else-if="item.params==='payType'">
                      {{scope.row.payType|payTypeTransfer()}}
                    </template>
                    <template v-else-if="item.params==='id'">
                      <span v-copy class="font-fine copy-underline">{{scope.row.id}}</span>
                    </template>
                    <template v-else-if="item.params==='cPayAccount'">
                      <span v-copy class="font-fine copy-underline">{{scope.row.cPayAccount}}</span>
                    </template>
                    <template v-else-if="item.params==='block_timestamp'">
                      {{formatDate(new Date(scope.row.block_timestamp))}}
                    </template>
                    <template v-else-if="item.params==='rate'">
                      {{scope.row.realRate}}
                    </template>
                    <template v-else-if="item.params==='platformAmount'">
                      {{ scope.row.platformAmount }}
                    </template>
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <paging
                :page-sizes="[10,20,25,30,40]"
                :update:page="queryForm.pageNum"
                :update:limit="queryForm.pageSize"
                :total="tableData.total"
                @changeSize="changeSize"
                @pagination="pagination"/>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="$checkRole('码商')" label="转账" name="two" style="margin-top: 15px">
          <div class="m-title">转账</div>
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   label-width="100px"
                   style="width: 380px"
                   class="demo-ruleForm">
            <el-form-item
                label="帐号"
                prop="toAccount">
              <el-input :class="{'apply-shake': valid.toAccount}"
                        placeholder="请输入对方帐号"
                        v-model.trim="ruleForm.toAccount"></el-input>
            </el-form-item>
            <el-form-item
                label="金额"
                prop="amount">
              <el-input-number :class="{'apply-shake': valid.sign}"
                               placeholder="金额"
                               style="width: 100%"
                               :min="0"
                               v-model.trim="ruleForm.amount"></el-input-number>
            </el-form-item>
            <el-form-item
                label="密码"
                prop="password">
              <el-input :class="{'apply-shake': valid.password}"
                        placeholder="请输入您的密码"
                        show-password
                        v-model.trim="password"></el-input>
            </el-form-item>
            <el-form-item
                label="谷歌验证器"
                prop="otpNum">
              <el-input :class="{'apply-shake': valid.otpNum}"
                        placeholder="请输入谷歌验证器验证码"
                        v-model.trim="ruleForm.otpNum"></el-input>
            </el-form-item>
            <el-form-item v-if="false" label="验证码" prop="captcha">
              <el-row>
                <el-col :span="15">
                  <el-input :class="{'apply-shake': valid.captcha}" v-model.trim="ruleForm.captcha" placeholder="验证码"></el-input>
                </el-col>
                <el-col :span="8">
                  <img  @click="getCode" class="login-code" :src="codeImageUrl" alt="">
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button class="common-form-btn common-left-space"
                         type="primary"
                         size="small"
                         @click="submitForm('ruleForm')">转   账</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--手机端-->
    <div v-else>
      <el-tabs style="margin-top: 10px" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane style="margin-top: 0" label="充值" name="one">
          <div class="h5-content">
            <h5-row label="充值汇率" :value="userInfo.platformRate"/>
            <h5-row label="可用资产" :value="userInfo.usdtBalance" />
            <h5-row label="TRC20充值地址">
              <template slot="value">
                <Copy class="text-long-dot" :text="userInfo.usdtAddress"/>
              </template>
            </h5-row>
          </div>
          <!--header-->
          <div class="h5-content">
            <div v-if="false"  style="display: flex">
              <DateRange v-model="timeArr"/>
              <el-input
                  size="mini"
                  @keyup.enter.native="init"
                  clearable
                  show-word-limit
                  style="width: 300px;float:right;"
                  v-model="queryForm.hash"
                  placeholder="输入交易hash查询">
              </el-input>
              <button v-if="false" @click="init" class="el-button--primary">
                <i class="el-icon-search"></i>
              </button>
            </div>
            <div style="text-align: end;margin-top: 5px">
              <el-button size="mini" @click="dialogVisible=true" type="warning">充值地址</el-button>
              <el-button icon="el-icon-orange"
                         size="mini"
                         v-if="false"
                         @click="syncRecords"
                         type="success">同步充值记录</el-button>
              <el-button v-if="false"  size="mini" @click="queryForm.userId='';init()" type="primary">搜索 <i class="el-icon-search"></i> </el-button>
            </div>
          </div>
          <div class="h5-content" v-for="(item,index) in tableData.records">
            <h5-row :label="'充值编号'" :value="item.id">
              <template slot="value">
                <Copy :text="item.id" class="text-long-dot"/>
              </template>
            </h5-row>
            <h5-row :label="'发送方'">
              <template slot="value">
                <Copy :text="item.fromAdd" class="text-long-dot"/>
              </template>
            </h5-row>
            <h5-row :label="'接收方'" :value="item.toAdd">
              <template slot="value">
                <Copy :text="item.toAdd" class="text-long-dot"/>
              </template>
            </h5-row>
            <h5-row :label="'USDT'" :value="item.amount"/>
            <h5-row :label="'标准汇率'" :value="item.standardRate"/>
            <h5-row :label="'奖励汇率'" :value="item.brokerageRate"/>
            <h5-row :label="'TCCOIM'" :value="item.platformAmount"/>
            <h5-row :label="'支付时间'" :value="item.blockTimestamp">
              <template slot="value">
                {{formatDate(new Date(item.blockTimestamp))}}
              </template>
            </h5-row>
            <h5-row :label="'同步时间'" :value="item.createTime"/>
            <h5-row :label="'支付状态'" :value="item.state">
              <template slot="value">
                <el-tag :type="item.state===0 ?
                'warning' : item.state===1 ?
                'success':item.state===2 ?
                'danger':item.state===3 ? 'warning' : ''"
                        size="mini">{{item.state|dictTransfer(topUpStateList)}}</el-tag>
              </template>
            </h5-row>
            <div class="h5-row" style="display: flex;justify-content: flex-end">
              <el-link type="primary" @click="goOfficialWebsite(item)">官网验证</el-link>
              <el-popconfirm
                  v-if="item.state===0"
                  v-role="'管理员,客服'"
                  class="common-left-space"
                  confirm-button-text='确定'
                  cancel-button-text='取消'
                  icon="el-icon-info"
                  icon-color="red"
                  @confirm="topUpPass(scope.row)"
                  title="确定到账吗？">
                <el-link type="danger" slot="reference">确认到账</el-link>
              </el-popconfirm>
            </div>
          </div>
          <el-empty v-if="tableData.records===undefined||tableData.records.length===undefined||tableData.records.length===0" class="h5-content"/>
          <paging
              v-if="tableData.total!==0"
              class="h5-content"
              :layout="'total,prev, pager,next'"
              :page-sizes="[10,20,25,30,40]"
              :update:page="queryForm.pageNum"
              :component-size="true"
              :update:limit="queryForm.pageSize"
              :total="tableData.total"
              @changeSize="changeSize"
              @pagination="pagination"/>
        </el-tab-pane>
        <el-tab-pane v-if="$checkRole('码商')" style="margin-top: 0" label="转账" name="two">
          <div class="h5-content">
            <el-form :model="ruleForm"
                     :rules="rules"
                     ref="ruleForm"
                     label-width="80px"
                     style="width: 100%"
                     class="demo-ruleForm">
              <el-form-item
                  label="帐号"
                  prop="toAccount">
                <el-input :class="{'apply-shake': valid.toAccount}"
                          size="small"
                          placeholder="请输入对方帐号"
                          v-model.trim="ruleForm.toAccount"></el-input>
              </el-form-item>
              <el-form-item
                  label="金额"
                  prop="amount">
                <el-input-number :class="{'apply-shake': valid.sign}"
                                 size="small"
                                 placeholder="金额"
                                 style="width: 100%"
                                 :min="0"
                                 :step="0.01"
                                 v-model.trim="ruleForm.amount"></el-input-number>
              </el-form-item>
              <el-form-item
                  size="small"
                  label="密码"
                  prop="password">
                <el-input :class="{'apply-shake': valid.password}"
                          placeholder="请输入您的密码"
                          show-password
                          v-model.trim="password"></el-input>
              </el-form-item>
              <el-form-item
                  size="small"
                  label="验证器"
                  prop="otpNum">
                <el-input :class="{'apply-shake': valid.otpNum}"
                          placeholder="请输入谷歌验证器验证码"
                          v-model.trim="ruleForm.otpNum"></el-input>
              </el-form-item>
              <el-form-item v-if="false" label="验证码" prop="captcha">
                <el-row>
                  <el-col :span="15">
                    <el-input size="small" :class="{'apply-shake': valid.captcha}" v-model.trim="ruleForm.captcha" placeholder="验证码"></el-input>
                  </el-col>
                  <el-col :span="8">
                    <img style="width: 90px" @click="getCode" class="login-code" :src="codeImageUrl" alt="">
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button class="common-form-btn common-left-space"
                           type="primary"
                           size="mini"
                           @click="submitForm('ruleForm')">转   账</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-drawer
          :show-close="false"
          :withHeader="false"
          size="50%"
          :visible.sync="dialogVisible"
          direction="btt">
        <div class="h5-content">
          <span style="font-weight: bold">充值地址:</span><br>
          <Copy :text="usdtAddress"></Copy>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dictTransfer from "@/mixin/DictTransfer.js";
import constant from "@/constant";
import common from "@/mixin/common";
import {codeAPI, verifyOnePwdAPI} from "@/api/Login";
import {topUpPageAPI, topUpPassAPI, topUpSaveAPI, transferMoneyAPI, userInfoAPI} from "@/api/user";
import Copy from "@/components/Copy.vue";
import paging from "@/components/Paging.vue";
import ConsumerUser from "@/components/ConsumerUser.vue";
import H5Row from "@/components/h5-row.vue";
import DateRange from "@/components/DateRange.vue";
import {encryptText} from "@/utils/jsencrypt";
let that=''
const checkAccount =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.toAccount=true
    setTimeout(()=>{
      if (that) {
        that.valid.toAccount=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkSign =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.sign=true
    setTimeout(()=>{
      if (that) {
        that.valid.sign=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkpassword =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.password=true
    setTimeout(()=>{
      if (that) {
        that.valid.password=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkotpNum =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.otpNum=true
    setTimeout(()=>{
      if (that) {
        that.valid.otpNum=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkcaptcha =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.captcha=true
    setTimeout(()=>{
      if (that) {
        that.valid.captcha=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
export default {
  name: "TopUp",
  components: {DateRange, H5Row, ConsumerUser, paging, Copy},
  mixins:[dictTransfer,common],
  props:{
    form:{
      required:false,
      type:Object,
      default:()=>{
        return{
          payType: 2,
          toAccount: '',
          amount:'',
          bankAddress:'',
          bankAccount:'',
        }
      }
    },
    type:{
      required: false,
      type:String,
      default:'money'
    },
  },
  data() {
    return {
      payType:constant.payType,
      usdtAddress:'',
      shakeCode:false,
      codeImageUrl: '',
      password: '',
      ruleForm: {
        amount: '',
        toAccount: '',
        fromId:JSON.parse(localStorage.getItem('userInfo')).id,
        uuid: '',
        captcha: '',
        otpNum: '',
        password:''
      },
      valid:{
        toAccount:false,
        sign:false,
        password:false,
        otpNum:false,
        captcha:false,
      },
      rules: {
        toAccount:[{validator:checkAccount, trigger: "blur"},],
        amount: [{validator:checkSign, trigger: "blur"},],
        password: [{validator:checkpassword, trigger: "blur"},],
        otpNum: [{validator:checkotpNum, trigger: "blur"},],
        captcha: [{validator:checkcaptcha, trigger: "blur"},],
      },
      activeName: 'one',
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      queryForm:{
        hash:'',
        pageSize:10,
        pageNum:1,
        userId: JSON.parse(localStorage.getItem('userInfo')).id,
        startTime: '',
        endTime: '',
        state:0
      },
      tableData:{
        records:[],
        total:0
      },
      columnList:[
        {align:"center",label:"充值编号",prop:"id",width:160,template:true,params:'id',show:''},
        {align:"center",label:"昵称",prop:"nickName",width:120,template:false,params:'id',show:''},
        {align:"center",label:"发送方",prop:"fromAdd",width:'auto',template:false,params:'',show:''},
        {align:"center",label:"接收方",prop:"toAdd",width:'auto',template:false,params:'payType',show:''},
        {align:"center",label:"USDT ",prop:"amount",width:'150',template:false,params:'payType',show:'amount'},
        {align:"center",label:"标准汇率",prop:"standardRate",width:100,template:false,params:'rate',show:''},
        {align:"center",label:"奖励汇率",prop:"brokerageRate",width:100,template:false,params:'rate',show:''},
        {align:"center",label:"TCCOIM",prop:"platformAmount",width:'100',template:true,params:'platformAmount',show:''},
        {align:"center",label:"支付时间",prop:"block_timestamp",width:180,template:true,params:'block_timestamp',show:''},
        {align:"center",label:"同步时间",prop:"createTime",width:180,template:false,params:'',show:''},
        {align:"center",label:"支付状态",prop:"state",width:100,template:true,params:'payState',show:''},
        {align:"center",label:"操作",prop:"answer",width:200,template:true,params:'control',show:''},
      ],
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      topUpStateList:constant.topUpState,
      dialogVisible: false,
    }
  },
  watch:{
    password:{
      handler(n, o) {
        this.ruleForm.password=encryptText(n)
      },
      immediate:false,
    }
  },
  mounted() {
    if (this.loginInfo.roleName.includes('管理员')) {
      this.queryForm.userId=''
    }
    this.init();
    userInfoAPI(JSON.parse(localStorage.getItem('userInfo')).id).then(res=>{
      if (res.code === 200) {
        this.usdtAddress=res.data.usdtAddress
        this.userInfo=res.data
      }
    })
    that=this
    this.$bus.$on('refreshLayout',()=>{
      this.init()
    })
  },
  methods:{
    init(params){
      console.log(params)
      if (params !== undefined) {
        this.queryForm.userId=params.id
      }
      if (this.$terminal) {
        this.queryForm.startTime=this.formatDate(this.timeArr[0],'start');
        this.queryForm.endTime=this.formatDate(this.timeArr[1],'end')
      } else {
        this.queryForm.startTime=this.timeArr[0];
        this.queryForm.endTime=this.timeArr[1]
      }
      topUpPageAPI(this.queryForm).then(res=>{
        if (res.code === 200) {
          this.tableData=res.data
        }
      });
    },
    async getCode(){
      let res= await codeAPI();
      this.codeImageUrl = res.data.verifyCode;
      this.ruleForm.uuid = res.data.uuid
    },
    topUpPass(params){
      topUpPassAPI(params.id,1).then(res=>{
        if (res.code === 200) {
          this.ok(res.msg)
          this.init()
        }
      })
    },
    topUpPassd(params){
      topUpPassAPI(params.id,3).then(res=>{
        if (res.code === 200) {
          this.ok(res.msg)
          this.init()
        }
      })
    },
    syncRecords(){
      const parse = JSON.parse(localStorage.getItem('userInfo'));
      let userId
      if (parse.roleName.includes('管理员')) {
        userId = this.queryForm.userId;
      } else {
        userId=parse.id
      }
      topUpSaveAPI(userId).then(res=>{
        if (res.code === 200) {
          this.ok(res.msg)
          this.init()
        }
      })
    },
    goOfficialWebsite(params){
      window.open(`https://tronscan.org/#/transaction/${params.hash}`)
    },
    handleClick(tab, event) {
      if (tab.name === 'two') {
        this.getCode()
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          transferMoneyAPI(this.ruleForm).then(res=>{
            if (res.code === 200) {
              this.ok(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    pagination(obj){
      this.queryForm.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.queryForm.pageNum=obj.page
      this.init()
    },
  },
  beforeDestroy() {
    that=null
    this.$bus.$off('refreshLayout')
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.m-money-d-d{
  margin-top: 20px;
}
.money-label{
  font-weight: bold;
}

</style>
