<template>
  <Setting />
</template>
<script>

import Setting from "@/views/admin/setting/Setting.vue";

export default {
  name: "AdminSetting",
  components: {Setting}
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
</style>
