<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <el-card shadow="hover" class="box-card">
        <div slot="header">
          <span style="font-weight: bold">用户信息</span>
          <el-link v-if="false" style="float:right;"
                   @click="updateUser"
                   type="primary">
            <i class="el-icon-edit"></i>
            编辑
          </el-link>
        </div>
        <el-descriptions border>
          <el-descriptions-item label="帐号">{{userInfo.account}}</el-descriptions-item>
          <el-descriptions-item :label="loginInfo.roleName==='码商'?'昵称':'id'">{{userInfo.nickName}}</el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('码商,管理员')" label="收款账号">{{userInfo.collAccount}}</el-descriptions-item>
          <el-descriptions-item v-if="false" label="邮箱地址">{{userInfo.email}}</el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('手动获取,自动获取')" label="绑定验证码">
            <el-button size="mini" @click="genOTP" type="primary">绑定验证码</el-button>
            <el-button @click="resetOTP" type="danger" size="mini" class="common-left-space">重置验证器</el-button>
          </el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('码商,管理员')"
                                label="USDT转入地址">
            <span class="copy-underline"
                  title="点击复制"
                  @click="copyText(userInfo.usdtAddress)">{{userInfo.usdtAddress}}</span>
            </el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('码商,管理员')" label="邀请链接">
            <span class="copy-underline"
                  title="点击复制"
                  @click="copyText(registerLink)">{{registerLink}}</span>
          </el-descriptions-item>
          <el-descriptions-item v-if="false" label="备注">
            <el-tag size="small">{{userInfo.remark}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('码商,管理员')" :label-style="{'background-color': '#FAFAFA', 'text-align': 'left','font-weight': 'bold','color': '#000000'}"
                                :contentStyle="{'background-color': '#FDE2E2', 'text-align': 'left','font-weight': 'bold','color': '#000000'}"
                                label="账户余额" >{{userInfo.usdtBalance}} TCCOIM</el-descriptions-item>
          <el-descriptions-item v-if="$checkRole('码商,管理员')" :label-style="{'background-color': '#FAFAFA', 'text-align': 'left','font-weight': 'bold','color': '#000000'}"
                                :contentStyle="{'background-color': '#FDE2E2', 'text-align': 'left','font-weight': 'bold','color': '#000000'}"
                                label="下级佣金" >{{userInfo.rmbBalance}}</el-descriptions-item>
          <el-descriptions-item label="平台认证">
            <el-tag :type="userInfo.infoAuth===0 ?
                'danger' : userInfo.infoAuth===1 ?
                'warning':userInfo.infoAuth===2 ?
                'primary':userInfo.infoAuth===3 ? 'success' : ''"
                    size="mini">{{userInfo.infoAuth|dictTransfer(infoAuthList)}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item v-if="false" label="佣金">
            {{userInfo.brokerage}}
            <el-button size="mini" type="primary">提现</el-button>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <div class="card-list">
        <el-card shadow="hover"
                 v-if="item.auth.includes(infoRole)"
                 @click.native="type(item.label)"
                 class="box-card" v-for="(item,index) in columnList">
          <div class="text item">
            <img width="50px" :src="item.icon" loading="eager" alt=""/>
            <div style="margin-top: 10px">{{item.label}}</div>
          </div>
        </el-card>
      </div>
      <el-divider v-if="showTab && $checkRole('码商,管理员')" content-position="left">{{activeMenu}}</el-divider>
      <el-divider v-if="$checkRole('手动获取,自动获取') ? activeMenu='客服':''" content-position="left">{{activeMenu}}</el-divider>
      <!--钱包记录-->
      <WalletRecord v-if="activeMenu === columnList[0].label && $checkRole('码商,管理员')"/>
      <!--客服-->
      <CustomerService v-else-if="activeMenu ===columnList[5].label"/>
      <!--收款-->
      <ReceiveMoney v-else-if="activeMenu ===columnList[2].label"/>
      <!--团队报表-->
      <TeamTable v-else-if="activeMenu === columnList[3].label"/>
      <!--充值-->
      <TopUp :type="'usdt'" v-else-if="activeMenu === columnList[1].label"/>
      <el-dialog title="使用Authenticator应用扫描二维码"
                 :top="'18%'"
                 :visible.sync="dialogOTP"
                 :modal="false"
                 width="30%">
        <QRCode :text="otpSecret" ref="qrcode2"/>
      </el-dialog>
      <SaveOrUpdateUser ref="updateUser"/>
    </div>
    <div v-else>
      <div v-role="'码商'" class="m-money-d h5-content">
        <div class="m-money" style="text-align: left;text-indent:1em;">
          账户余额<br>
          <div style="margin-top: 4px;text-align: center">{{userInfo.usdtBalance}} TCCOIM</div>
        </div>
      </div>
      <div class="h5-content">
        <h5-row label="帐号">
          <template slot="value">
            <Copy :text="userInfo.account"/>
          </template>
        </h5-row>
        <h5-row :label="loginInfo.roleName==='码商'?'昵称':'id'">
          <template slot="value">
            <el-input disabled style="width: 200px" size="mini" v-model="userInfo.nickName"></el-input>
          </template>
        </h5-row>
        <h5-row v-if="false" label="邮箱">
          <template slot="value">
            <el-input style="width: 200px" size="mini" v-model="userInfo.email"></el-input>
          </template>
        </h5-row>
        <h5-row v-if="false" label="谷歌验证器">
          <template slot="value">
            <el-switch
                v-model="userInfo.openVerify"
                active-color="#13ce66"
                :active-value="1"
                @change="changeState(userInfo)"
                :inactive-value="0">
            </el-switch>
          </template>
        </h5-row>
        <h5-row v-role="'码商'" label="邀请链接">
          <template slot="value">
            <!--<el-input style="width: 200px" size="mini" v-model="userInfo.email">
              <template slot="append">
                复制<i class="el-icon-document-copy"/>
              </template>
            </el-input>-->
            <span @click="copyText(registerLink)" class="copy-underline">复制邀请链接</span>
          </template>
        </h5-row>
        <div style="text-align: end;width: 100%;margin-top: 10px">
          <el-button v-role="'手动获取,自动获取'" @click="genOTP" type="success" size="mini">添加验证器</el-button>
          <el-button v-role="'手动获取,自动获取'" @click="resetOTP" type="danger" size="mini" class="common-left-space">重置验证器</el-button>
          <el-button @click="updateUserInfoH5" type="primary" size="mini" class="common-left-space">保存</el-button>
        </div>
      </div>
      <div class="m-card-list h5-content">
        <el-row v-if="false" :gutter="20">
          <el-col :span="6" v-for="(item,index) in columnList1">
            <div @click="type(item.label)"
                 class="box-card">
              <div style="width: 60px;text-align: center">
                <img width="30px" :src="item.icon" loading="eager" alt=""/>
                <div style="margin-top: 8px;font-size: 12px">{{item.label}}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="6" v-for="(item,index) in columnList2">
            <div @click="type(item.label)"
                 class="box-card">
              <div style="width: 60px;text-align: center">
                <img width="30px" :src="item.icon" loading="eager" alt=""/>
                <div style="margin-top: 8px;font-size: 12px">{{item.label}}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-drawer
          :show-close="false"
          :withHeader="false"
          size="70%"
          :visible.sync="dialogOTP"
          direction="btt">
        <button @click="dialogOTP=false" class="el-button--danger" style="position: absolute;top: 10px;right: 10px">关闭</button>
        <QRCode style="margin-top: 20px" :s-width="10" :text="otpSecret" ref="qrcode2"/>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import SaveOrUpdateUser from "@/views/info/SaveOrUpdateUser";
import WalletRecord from "@/views/info/WalletRecord";
import CustomerService from "@/views/info/CustomerService";
import TeamTable from "@/views/info/TeamTable";
import ReceiveMoney from "@/views/info/ReceiveMoney";
import common from "@/mixin/common";
import TopUp from "@/views/info/TopUp";
import QRCode from "@/components/QRCode.vue";
import {encryptText} from "@/utils/jsencrypt";
import {dynamicAPI, onePwdAPI, resetDynamicAPI, verifyPwdAPI} from "@/api/Login";
import purse from "@/assets/userinfo/purse.svg"
import currency from "@/assets/userinfo/currency.svg"
import setting from "@/assets/userinfo/setting.svg"
import table from "@/assets/userinfo/table.svg"
import pwd from "@/assets/userinfo/pwd.svg"
import user from "@/assets/userinfo/user.svg"
import logout from "@/assets/userinfo/logout.svg"
import {userInfoAPI, userModifyAPI} from "@/api/user";
import dictTransfer from "../../mixin/DictTransfer";
import constant from "@/constant";
import img from "@/assets/userinfo/img.png"
import H5Row from "@/components/h5-row.vue";
import Copy from "@/components/Copy.vue";
import {Message, MessageBox} from "element-ui";
let that
export default {
  name: "UserInfo",
  mixins:[common,dictTransfer],
  components:{Copy, H5Row, QRCode, TopUp, ReceiveMoney, TeamTable, CustomerService, WalletRecord, SaveOrUpdateUser},
  data() {
    return {
      dialogOTP:false,
      otpSecret:'',
      columnList:[
        {label:"钱包记录",path:"id",icon:purse,auth:'码商,管理员'},
        {label:"充值 | 转账",path:"balance",icon:currency,auth:'码商,管理员'},
        {label:"码商设置",path:"payType",icon:setting,auth:'管理员'},
        {label:"团队报表",path:"answer",icon:table,auth:'码商,管理员'},
        {label:"修改密码",path:"answer",icon:pwd,auth:'码商,管理员,手动获取,自动获取'},
        {label:"客服",path:"answer",icon:user,auth:'码商,管理员,手动获取,自动获取'},
        {label:"退出登录",path:"answer",icon:logout,auth:'码商,管理员,手动获取,自动获取'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        orderType:'asc',
        state: '',
      },
      activeMenu:'钱包记录',
      userInfo:{
        openVerify:0,
      },
      infoAuthList:constant.infoAuthList,
      infoRole:constant.userRoleName==='超级管理员'?'管理员':constant.userRoleName,
      img,
      registerLink:'',
    }
  },
  mounted() {
    this.init()
    that=this
    //atob() 解码
    //btoa() 编码
    let url=`code=${this.loginInfo.id}&register=false`
    this.registerLink=`${window.location.protocol}//${window.location.host}?${btoa(url)}`
  },
  computed: {
    dictTransfer() {
      return dictTransfer
    },
    showTab(){
      return [
          this.columnList[0].label,
          this.columnList[1].label,
          this.columnList[2].label,
          this.columnList[3].label,
          this.columnList[5].label,
      ].includes(this.activeMenu)
    },
    columnList1(){
      return this.columnList.slice(0,4)
    },
    columnList2(){
      return this.columnList.slice(4,this.columnList.length)
    },
  },
  watch: {},
  methods: {
    init(){
      userInfoAPI(JSON.parse(localStorage.getItem('userInfo')).id).then(res=>{
        if (res.code === 200) {
          this.userInfo=res.data
        }
      })
    },
    type(type){
      this.activeMenu=type
      switch (type) {
        case '钱包记录':
          break;
        case 'USDT充值':
          break;
        case '收款设定':
          break;
        case '团队报表':
          break;
        case '修改密码':
          this.$store.dispatch('updatePwd')
          break;
        case '客服':
          break;
        case '退出登录':
          this.$store.dispatch('logout')
          break;
      }
    },
    updateUser(){
      this.$refs.updateUser.form.id=JSON.parse(localStorage.getItem('userInfo')).id
      this.$refs.updateUser.info()
    },
    genOTP(){
      dynamicAPI().then(res=>{
        if (res.code === 200) {
          this.dialogOTP=true
          this.otpSecret=res.data
          setTimeout(()=>{
            that.$refs.qrcode2.qrcode()
          },200)
          // setTimeout(()=>{
          //   this.dialogOTP=true
          //   this.otpSecret=res.data
          //   setTimeout(()=>{
          //     that.$refs.qrcode2.qrcode()
          //   },200)
          // },500)
        }
      })
      // let a={password:encryptText('1')}
      // onePwdAPI(a).then(res=>{
      //
      // })
    },
    changeState(params){
      let form={}
      form.id=params.id
      form.roleId=this.loginInfo.roleId
      form.version=params.version
      form.openVerify=params.openVerify
      //修改
      userModifyAPI(form).then(res=>{
        this.popUp(res)
      })
    },
    updateUserInfoH5(){
      let form={}
      form.id=this.userInfo.id
      form.roleId=this.loginInfo.roleId
      form.version=this.userInfo.version
      form.email=this.userInfo.email
      form.nickName=this.userInfo.nickName
      //修改
      userModifyAPI(form).then(res=>{
        this.popUp(res)
      })
    },
    resetOTP(){
      MessageBox.prompt('敏感操作, 请先验证密码', '重置手机令牌', {
        distinguishCancelAndClose: true, // 点击取消和关闭按钮会返回不同的值
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入密码', // 输入框的占位符
        inputErrorMessage: '密码必须包含数字、字母和特殊字符，长度在6-20位之间' // 输入校验失败时的提示信息
      }).then(({value}) => {
        let form={
          id:JSON.parse(localStorage.getItem('userInfo')).id,
          password:encryptText(value)
        };
        verifyPwdAPI(form).then(res=>{
          if (res.data) {
            Message.success('验证成功');
            resetDynamicAPI('').then(res=>{
              if (res.code === 200) {
                Message.success('重置成功');
                this.dialogOTP=true
                this.otpSecret=res.data
                setTimeout(()=>{
                  that.$refs.qrcode2.qrcode()
                },200)
              }
            })
          } else {
            Message.error('密码错误')
          }
        })
      }).catch(() => {
      });
    },
  },
  filters: {},
  beforeDestroy() {
    that=null
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
@mixin common-margin{
  margin: 0 0 0 0;
}
.box-card{
  @include common-margin;
  margin-bottom: 10px;
  .my-balance{
    background: #52b71f;
  }
}
.my-balance{
  background: #52b71f;
}
.card-list{
  display: flex;
  text-align: center;
  .box-card{
    cursor: pointer;
    @include common-margin;
    margin: 0 10px 0 0;
    width: 180px;
  }
}

.my-label {
  background: #E1F3D8;
}

.my-content {
  background: #FDE2E2;
}
.el-divider__text, .el-image__error, .el-link, .el-timeline, .el-transfer__button i, .el-transfer__button span{
  font-size: 16px;
}
.m-user-info{
  .img-info{
    height: 50px;
    border-radius: 5px;
  }
  display: flex;
  .m-user-info-text{
    margin-left: 10px;
    width: 100%;
    .m-user-info-text-name{
      font-size: 20px;
    }
    .m-user-info-text-phone{
      margin-top: 8px;
      width: 100%;
      font-size: 14px;
    }
  }
}
.m-money-d{
  border-radius: 10px;
  height: 80px;
  width: 80%;
  margin: 10px auto 0 auto;
  .m-money{
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 8px;
  }
}
.m-card-list{
  //display: flex;
  margin-top: 20px;
}
::v-deep .el-input-group__append,::v-deep .el-input-group__prepend{
  padding: 0 10px;
}
</style>
