<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <div v-if="false" v-role="'自动获取'" style="width: 100%;text-align: right">
        <el-button @click="showPage=(!showPage)" type="primary">显示收银台</el-button>
      </div>
      <el-descriptions v-if="$checkRole('码商')" class="margin-top" :column="3" border style="margin-bottom: 10px">
        <el-descriptions-item label="盈利开关">
          <el-switch
              @change="businessStateOpen"
              style="display: block"
              v-model="loginInfo.businessState"
              active-color="#13ce66"
              inactive-color="#DCDFE6"
              :active-value="1"
              :inactive-value="0"
              active-text="盈利"
              inactive-text="关闭">
          </el-switch>
        </el-descriptions-item>
        <el-descriptions-item label="更新时间">{{loginInfo.updateTime}}</el-descriptions-item>
        <el-descriptions-item label="帐号"><Copy :text="loginInfo.account" /></el-descriptions-item>
        <el-descriptions-item label="可用余额">{{loginInfo.usdtBalance===undefined?'0.00':loginInfo.usdtBalance.toFixed(2)}}<span class="tccoim"> TCCOIM</span></el-descriptions-item>
        <el-descriptions-item label="冻结余额">{{tradingInfo.freezeAmount===undefined?'0.00':tradingInfo.freezeAmount.toFixed(2)}}<span class="tccoim"> TCCOIM</span></el-descriptions-item>
        <el-descriptions-item label="正在交易金额">{{tradingInfo.liveAmount===undefined?'0.00':tradingInfo.liveAmount.toFixed(2)}}<span class="tccoim"> TCCOIM</span></el-descriptions-item>
      </el-descriptions>
      <CreateOrder v-if="$checkRole('手动获取')&&showCreateOrder" @refresh="init"/>
      <CheckStand v-if="$checkRole('手动获取')&&!showCreateOrder"
                  :key="new Date().getTime()"
                  @refresh="init"
                  @payed="controls"
                  :form="tableData.list[0]"
                  v-loading="tableData.list && tableData.list.length ===0"/>
      <div>
        <span class="team-title" style="margin-bottom: 10px">
          {{roleName === '码商'?'正在卖币中':'正在交易'}}
        </span>
        <div style="margin-top: 10px">
          <el-input
              @keyup.enter.native="init"
              clearable
              show-word-limit
              style="width: 300px"
              v-model.trim="form.orderId"
              placeholder="根据订单号搜索">
            <el-button @click="init" slot="append" icon="el-icon-search"></el-button>
          </el-input>
          <el-date-picker
              class="common-left-space"
              v-model="timeArr"
              type="daterange"
              align="left"
              @change="init"
              v-role="'手动获取,自动获取'"
              format="yyyy-MM-dd"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
          </el-date-picker>
          <el-select v-model="form.state"
                     v-role="'手动获取,自动获取'"
                     v-if="false"
                     class="common-left-space"
                     clearable
                     @change="init"
                     placeholder="订单状态">
            <el-option
                v-for="item in options"
                v-if="item.scope.includes('history')"
                :key="item.type"
                :label="item.name"
                :value="item.type">
            </el-option>
          </el-select>
          <el-button type="info"
                     @click="init()"
                     style="float:right;margin-bottom: 10px">
            <i class="el-icon-refresh"></i>
            {{$t('table.resetSearch')}}
          </el-button>
        </div>
      </div>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px;margin-bottom: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         v-if="true"
                         :selectable="selectable"
                         align="center"
                         width="40">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           :fixed="item.fixed"
                           v-if="(!item.template) && $checkRole(item.auth)"
                           :show-overflow-tooltip="item.detail"
                           :min-width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           :fixed="item.fixed"
                           v-else-if="$checkRole(item.auth)"
                           :min-width="item.width"
                           :prop="item.prop"
                           :fit="true"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <OrderControl @refresh="init" :row="scope.row"/>
              </template>
              <template v-else-if="item.params==='payState'">
                <el-tag size="mini">{{scope.row.state|orderStateTransfer(loginInfo)}}</el-tag>
              </template>
              <template v-else-if="item.params==='payType'">
                {{scope.row.payType|payTypeTransfer()}}
              </template>
              <template v-else-if="item.params==='totalSeconds'">
                <Countdown :start="scope.row.state === 0" :key="scope.row.id" @refresh="init" :total="scope.row.totalSeconds"/>
              </template>
              <template v-else-if="item.params==='notice'">
                <el-tag size="mini" :type="scope.row.notice === 1 ? 'success' : 'warning'">{{scope.row.state|noticeStateTransfer}}</el-tag>
              </template>
              <template v-else-if="item.params==='bCollAccount'">
                <Copy :text="scope.row.bCollAccount"/>
              </template>
              <template v-else-if="item.params==='id'">
                <Copy :text="scope.row.id"/>
              </template>
              <template v-else-if="item.params==='payTime'">
                <span>通知时间: {{scope.row.payTime}}</span><br>
                <span>最后更新: {{scope.row.updateTime}}</span>
              </template>
              <template v-else-if="item.params==='cRealName'">
                <span v-if="scope.row.remark!==undefined">{{scope.row.remark}}</span>
                <span v-else>{{scope.row.cRealName}}</span>
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <!--暂时停止-->
      <div v-role="'手动获取,自动获取,管理员'" v-if="false" style="width: 100%">
        <span>已勾选 <span class="font-red font-size-25px">{{ids.length}}</span> 件订单</span>&nbsp;
        <span>合计¥ <span class="font-red font-size-25px">{{sum}}</span> 元</span>
        <el-button type="primary" size="small" class="el-right common-left-space" @click="controls('table-warn')"><i class="el-icon-bell"/> 提醒卖家</el-button>
        <el-button type="success" size="small" class="el-right" @click="controls('table-payed')"><i class="el-icon-check"/> 勾选订单已支付</el-button>
      </div>
      <paging
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
      <AbnormalOrder v-if="false" @refresh="init" ref="abnormalOrder"/>
    </div>
    <div v-else>
      <div v-if="$checkRole('码商')" class="h5-content" style="background-color: #F1F1F1;box-shadow:none">
        <!--支付宝最高交易额-->
        <div v-if="false" class="h5-row">
          <div class="label-height">支付宝最高交易额</div>
          <div>
            <input class="h5-mini-super-input" v-model.trim="loginInfo.aliMaxLimit"><button style="background-color: #409EFF;background-color: #409EFF;color: white" class="common-left-space">设置</button>
          </div>
        </div>
        <!--支付宝最低交易额-->
        <div v-if="false" class="h5-row">
          <div class="label-height">支付宝最低交易额</div>
          <div>
            <input class="h5-mini-super-input" v-model.trim="loginInfo.aliMinLimit"><button style="  color: white;background-color: #409EFF;color: white" class="common-left-space">设置</button>
          </div>
        </div>
        <!--银行卡最高交易额-->
        <div v-if="false" class="h5-row">
          <div class="label-height">银行卡最高交易额</div>
          <div>
            <input class="h5-mini-super-input" v-model.trim="loginInfo.bankMaxLimit"><button style="background-color: #409EFF;color: white" class="common-left-space">设置</button>
          </div>
        </div>
        <!--银行卡最低交易额-->
        <div v-if="false" class="h5-row">
          <div class="label-height">银行卡最低交易额</div>
          <div>
            <input class="h5-mini-super-input" v-model.trim="loginInfo.bankMinLimit"><button style="background-color: #409EFF;color: white" class="common-left-space">设置</button>
          </div>
        </div>
        <!--盈利开关-->
        <div class="h5-row">
          <div class="label-height">盈利开关</div>
          <div>
            <el-switch
                @change="businessStateOpen"
                style="display: block"
                v-model="loginInfo.businessState"
                active-color="#13ce66"
                inactive-color="#DCDFE6"
                :active-value="1"
                :inactive-value="0"
                active-text="盈利"
                inactive-text="关闭">
            </el-switch>
          </div>
        </div><hr>
        <!--更新时间-->
        <div class="h5-row">
          <div class="label-height">更新时间</div>
          <div>
            {{loginInfo.updateTime}}
          </div>
        </div><hr>
        <!--帐号-->
        <div class="h5-row">
          <div class="label-height">帐号</div>
          <div>
            <Copy :text="loginInfo.account" />
          </div>
        </div><hr>
        <h5-row v-if="false" label="邮箱">
          <template slot="value">
            <Copy :text="loginInfo.email"/>
          </template>
        </h5-row>
        <!--可用余额-->
        <div class="h5-row">
          <div class="label-height">可用余额</div>
          <div>
            <span v-text="loginInfo.usdtBalance===undefined?'0.00':loginInfo.usdtBalance.toFixed(2)"/><span class="tccoim"> TCCOIM</span>
          </div>
        </div><hr>
        <!--冻结金额-->
        <div class="h5-row">
          <div class="label-height">冻结金额</div>
          <div>
            {{tradingInfo.freezeAmount===undefined?'0.00':tradingInfo.freezeAmount.toFixed(2)}}<span class="tccoim"> TCCOIM</span>
          </div>
        </div>
      </div><hr>
      <el-card v-if="$checkRole('码商')" class="h5-content">
        <div slot="header">
          <span style="font-weight: bold">卖币进行中</span>
        </div>
        <div class="h5-row">
          <div>金额</div>
          <div>
            {{tradingInfo.liveAmount===undefined?'0.00':tradingInfo.liveAmount.toFixed(2)}}<span class="tccoim"> TCCOIM</span>
          </div>
        </div>
      </el-card>
      <CreateOrder @refresh="init"
                   v-role="'手动获取,自动获取,管理员'"
                   v-if="showCreateOrder"/>
      <CheckStand v-role="'手动获取,自动获取,管理员'"
                  :key="new Date().getTime()"
                  @refresh="init"
                  :form="tableData.list[0]"
                  v-loading="tableData.list && tableData.list.length ===0"
                  v-else/>
      <el-card v-if="$checkRole('手动获取,自动获取')" class="h5-content">
        <div slot="header">
          <span>交易进行中</span>
        </div>
        <div class="h5-row">
          <div class="label-height">
            金额
          </div>
          <div>{{tradingInfo.liveAmount.toFixed(2)}}<span class="tccoim"> TCCOIM</span></div>
        </div>
      </el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-if="$checkRole('码商')" style="margin-top: 0" label="卖币订单" name="first">
          <!--列表-->
          <div class="h5-content" v-for="(item,index) in tableData.list">
            <div class="h5-row">
              <div class="label-height">
                订单号
              </div>
              <Copy :text="item.id"/>
            </div><hr>
            <div v-if="false" class="h5-row">
              <div class="label-height">
                平台昵称
              </div>
              <div>{{item.cNickName}}</div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                订单金额
              </div>
              <div>{{item.amount.toFixed(2)}}</div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                成交数量
              </div>
              <div>
                {{item.amount-item.bonus}}
              </div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                付款人姓名
              </div>
              <div>
                <span v-if="item.remark!==undefined">{{item.remark}}</span>
                <span v-else>{{item.cRealName}}</span>
              </div>
            </div><hr>
            <!--付款人帐号-->
            <div v-if="item.payType===2" class="h5-row">
              <div class="label-height">付款人帐号</div>
              <div>{{item.cPayAccount}}</div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">
                支付状态
              </div>
              <div>
                <el-tag :type="item.state===7 ?
                'warning' : item.state===-1 ?
                'success':item.state===6 ?
                'danger':item.state===3 ? 'warning' : ''"
                        size="mini">{{item.state|orderStateTransfer(loginInfo)}}</el-tag>
              </div>
            </div><hr>
            <div class="h5-row">
              <div class="label-height">创建时间</div>
              <div>{{item.createTime}}</div>
            </div><hr>
            <div v-if="false" class="h5-row">
              <div class="label-height">更新时间</div>
              <div>{{item.updateTime}}</div>
            </div><hr>
            <div class="h5-row-item-right">
              <OrderControl @refresh="init" :row="item"/>
            </div>
          </div>
          <!--分页-->
          <paging
              v-if="tableData.total !== 0"
              class="h5-content"
              :layout="'total,prev, pager,next'"
              :component-size="true"
              :page-sizes="[10,20,25,30,40]"
              :update:page="form.pageNum"
              :update:limit="form.pageSize"
              :total="tableData.total"
              @changeSize="changeSize"
              @pagination="pagination"/>
          <el-empty class="h5-content" v-if="tableData.total === 0" description="暂时没有新订单"></el-empty>
        </el-tab-pane>
        <el-tab-pane v-if="false" style="margin-top: 0" label="异常订单" name="second">
          <AbnormalOrder v-if="activeName==='second'"/>
        </el-tab-pane>
        <el-tab-pane v-if="$checkRole('手动获取,自动获取')" label="正在交易" name="first">
          <!--列表-->
          <div class="h5-content" v-for="(item,index) in tableData.list">
            <div class="h5-row">
              <div class="label-height">
                订单号
              </div>
              <Copy :text="item.id"/>
            </div><hr>
            <div class="h5-row" v-if="false">
              <div class="label-height">
                平台昵称
              </div>
              <div>{{item.cNickName}}</div>
            </div><hr v-show="false">
            <div class="h5-row">
              <div class="label-height">
                订单金额
              </div>
              <div>{{item.amount.toFixed(2)}}</div>
            </div><hr>
            <h5-row label="支付类型">
              <template slot="value">
                {{item.payType|payTypeTransfer()}}
              </template>
            </h5-row>
            <h5-row label="收款人帐号">
              <template slot="value">
                <Copy :text="item.bCollAccount"/>
              </template>
            </h5-row>
            <h5-row label="收款人姓名">
              <template slot="value">
                <Copy :text="item.bRealName"/>
              </template>
            </h5-row>
            <h5-row v-if="item.payType===2" label="银行名称">
              <template slot="value">
                {{JSON.parse(item.bUsername).bankName}}
              </template>
            </h5-row>
            <h5-row v-if="item.payType===2" label="开户行">
              <template slot="value">
                {{JSON.parse(item.bUsername).bankAddress}}
              </template>
            </h5-row>
            <div class="h5-row" v-if="false">
              <div class="label-height">
                成交数量
              </div>
              <div>
                {{item.amount-item.bonus}}
              </div>
            </div><hr v-show="false">
            <div class="h5-row" v-if="false">
              <div class="label-height">
                付款人姓名
              </div>
              <div>
                {{item.cRealName}}
              </div>
            </div><hr v-show="false">
            <!--付款人帐号-->
            <div class="h5-row" v-if="false">
              <div class="label-height">付款人帐号</div>
              <div>{{item.cPayAccount}}</div>
            </div><hr v-show="false">
            <div class="h5-row">
              <div class="label-height">
                状态
              </div>
              <div>
                <el-tag :type="item.state===7 ?
                'warning' : item.state===-1 ?
                'success':item.state===6 ?
                'danger':item.state===3 ? 'warning' : ''"
                        size="mini">{{item.state|orderStateTransfer(loginInfo)}}</el-tag>
              </div>
            </div><hr>
            <div class="h5-row" v-if="false">
              <div class="label-height">创建时间</div>
              <div>{{item.createTime}}</div>
            </div><hr v-show="false">
            <div class="h5-row" v-if="false">
              <div class="label-height">更新时间</div>
              <div>{{item.updateTime}}</div>
            </div><hr v-show="false">
            <div class="h5-row-item-right">
              <OrderControl class="OrderControl" @refresh="init" :row="item"/>
            </div>
          </div>
          <!--分页-->
          <paging
              v-if="tableData.total !== 0"
              class="h5-content"
              :layout="'total,prev, pager,next'"
              :component-size="true"
              :page-sizes="[10,20,25,30,40]"
              :update:page="form.pageNum"
              :update:limit="form.pageSize"
              :total="tableData.total"
              @changeSize="changeSize"
              @pagination="pagination"/>
          <el-empty class="h5-content" v-if="tableData.total === 0" description="暂时没有新订单"></el-empty>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import paging from "@/components/Paging";
import constant from "@/constant";
import dictTransfer from "@/mixin/DictTransfer";
import {orderModifyAPI, orderPageAPI, tradingInfoAPI} from "@/api/order";
import common from "@/mixin/common";
import CreateOrder from "@/components/CreateOrder.vue";
import CheckStand from "@/components/CheckStand.vue";
import Countdown from "@/components/Countdown.vue";
import Copy from "@/components/Copy.vue";
import MUpload from "@/components/M-Upload.vue";
import AbnormalOrder from "@/views/trading/AbnormalOrder.vue";
import OrderControl from "@/components/OrderControl.vue";
import H5Row from "@/components/h5-row.vue";
import {userInfoAPI, userModifyAPI} from "@/api/user";

export default {
  name: "Trading",
  components:{H5Row, OrderControl, AbnormalOrder, MUpload, Copy, Countdown, CheckStand, CreateOrder, paging},
  mixins:[dictTransfer,common],
  data() {
    return {
      roleName:JSON.parse(localStorage.getItem('userInfo')).roleName,
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        {align:"center",label:"订单号",prop:"id",width:100,template:true,params:'id',show:true,auth:constant.authAll,fixed:''},
        {align:"center",label:"支付方式",prop:"payType",width:100,template:true,params:'payType',show:true,auth:constant.authAll,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"交易金额",prop:"amount",width:100,template:false,params:'',show:true,auth:constant.authAll,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"支付状态",prop:"payState",width:110,template:true,params:'payState',show:true,auth:constant.authAll,fixed:'left',autoWidth:false,detail:true,},
        // {align:"center",label:"码商昵称",prop:"bNickName",width:'100',template:false,params:'account',show:true,auth:constant.authAdmin,fixed:'',autoWidth:false,detail:true,},
        // {align:"center",label:"平台昵称",prop:"cNickName",width:'100',template:false,params:'account',show:true,auth:constant.authAdmin,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"通知状态",prop:"notice",width:110,template:true,params:'notice',show:true,auth:constant.authAll,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"创建时间",prop:"createTime",width:'100',template:false,params:'',show:true,auth:constant.authAll,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"通知时间",prop:"payTime",width:'100',template:false,params:'payTime',show:true,auth:constant.authAll,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"码商姓名",prop:"bRealName",width:'100',template:false,params:'account',show:true,auth:constant.authConsumer,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"收款帐号",prop:"bCollAccount",width:'100',template:true,params:'bCollAccount',show:true,auth:constant.authConsumer,fixed:'',autoWidth:false,detail:true,},
        // {align:"center",label:"购买数量",prop:"uCount",width:100,template:false,params:'usdtCount',show:true,auth:constant.authAll,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"付款人姓名",prop:"cRealName",width:'120',template:true,params:'cRealName',show:true,auth:constant.authAll,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"付款人帐号",prop:"cPayAccount",width:'100',template:false,params:'address',show:true,auth:constant.authAdmin,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"支付倒计时",prop:"totalSeconds",width:'100',template:true,params:'totalSeconds',show:true,auth:constant.authConsumer,fixed:'',autoWidth:false,detail:true,},
        {align:"center",label:"操作",prop:"answer",width:'150',template:true,params:'control',show:true,auth:constant.authAll,fixed:'right',autoWidth:false,detail:false,},
      ],
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      form:{
        pageNum:1,
        pageSize:10,
        urlId:'',
        startTime: '',
        endTime: '',
        state: '',
        fileType: constant.fileType.order,
      },
      ids:[],
      sum:0,
      options:constant.payState,
      //首先展示创建订单页面
      showCreateOrder:true,
      showPage:JSON.parse(localStorage.getItem('userInfo')).roleName==='手动获取',
      noticeList:constant.notice,
      activeName: 'first',
      tradingInfo: {
        "liveAmount":0,
        "freezeAmount":0
      },

    }
  },
  created() {

  },
  mounted() {
    this.showPage=JSON.parse(localStorage.getItem('userInfo')).roleName==='手动获取'
    this.init()
    this.$bus.$on('refreshOrder',()=>{
      this.init()
    })
    this.$bus.$on('refreshLayout',()=>{
      this.init('all')
    })

  },
  updated() {

  },
  computed: {
    isConsumer(){
      return JSON.parse(localStorage.getItem('userInfo')).roleName === '手动获取,自动获取'
    }
  },
  watch: {},
  methods: {
    handleClick(tab, event) {
      if (tab.paneName === 'first') {
        this.init()
      }
    },
    init(params){
      if (params === false) {
        this.showCreateOrder=params
      } else if (params === true){
        this.showCreateOrder=params
      } else if (params === 'all' && this.$terminal) {
        // this.$refs.abnormalOrder.init()
      }
      let form = JSON.parse(JSON.stringify(this.form));
      if (this.loginInfo.roleName.includes('手动获取') || this.loginInfo.roleName.includes('自动')) {
        if (this.timeArr) {
          if (this.$terminal) {
            if (this.timeArr.length > 0) {
              this.form.startTime=this.formatDate(this.timeArr[0],'start');
              this.form.endTime=this.formatDate(this.timeArr[1],'end');
            }
          } else {
            this.form.startTime=this.timeArr[0]
            this.form.endTime=this.timeArr[1]
          }
        }
        form.state='0,1,2,3,4,5,13,20,6,7,-1,14,11,21'
      } else {
        form.state='0,1,2,3,4,5,13,20';
      }
      // let form = JSON.parse(JSON.stringify(this.form));
      orderPageAPI(form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=[]
          if (res.data.records) {
            this.tableData.list=res.data.records;
          } else {
            this.showCreateOrder=true
          }
          this.tableData.total=res.data.total
          this.$store.state.eventNum=res.data.total
          if (!this.$terminal && res.data.total>0) {
            this.$nextTick(()=>{
              //let orderControlList=document.getElementsByClassName('OrderControl');
              // let orderControlList=document.getElementsByName('a')
              // console.log(orderControlList)
              // for (let orderControlListElement of orderControlList) {
              //   console.log(orderControlListElement)
              // }
            })
          }
        }
      });
      tradingInfoAPI().then(res=>{
        if (res.code === 200) {
          this.tradingInfo=res.data
        }
      })
      userInfoAPI(this.loginInfo.id).then(res=>{
        if (res.code === 200) {
          this.loginInfo.usdtBalance=res.data.usdtBalance
          this.loginInfo.businessState=res.data.businessState
        }
      })
    },
    handleSelectionChange(rows) {
      this.ids=[]
      let sum=0;
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
        if (value.amount) {
          sum+=value.amount
        }
      })
      this.sum=sum
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    selectable(row, index){
      return row.state===constant.payState[0].type
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
    },
    controls(type,params){
      console.log(type,params)
      let form
      if (params) {
        form = {
          id:params.id,
          state:constant.payState[0].type,
          payType:params.payType,
          userId:params.userId,
          businessId:params.businessId,
          orderVersion:params.orderVersion,
        };
      }
      switch (type) {
        case 'cancelPay':
          form.state=constant.payState[7].type
          form.messageType=constant.payState[7].type
          this.modify(form)
          break;
        case 'closeOrder':
          this.$confirm('确认当前订单收到款项了吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            form.state=constant.payState[10].type
            form.messageType=constant.payState[10].type
            this.modify(form)
          }).catch(() => {});
          break;
        case 'see':
          this.$router.push({path:'orderInfo',query:{id:params.id}})
          break;
        case 'payed':
          form.state=constant.payState[2].type;
          form.messageType=constant.payState[2].type;
          this.modify(form)
          break;
        case 'lockOrder':
          console.log(params)
          if (params.uCount) {
            form.state=constant.payState[1].type
            form.messageType=constant.payState[1].type
            form.uCount=String(params.uCount)
            this.modify(form)
          } else {
            this.error('购买数量不少于0')
          }
          break;
        case 'warn':
          form.businessId=params.businessId
          form.messageType=constant.messageType[3].type
          form.state=constant.payState[9].type
          this.modify(form)
          break;
        case 'table-payed':
          if (this.ids.length === 0) {
            this.error('请选择要操作的订单')
            return;
          }

          break
        case 'table-warn':
          if (this.ids.length === 0) {
            this.error('请选择要操作的订单')
            return
          }
          break
        //补单
        case 'replenishment':
          form.state=constant.payState[11].type
          form.messageType=constant.payState[11].type
          this.modify(form)
          break
        case 'applyRefund':
          form.state=constant.payState[4].type
          form.messageType=constant.payState[4].type
          this.modify(form)
          break
        case 'cancelRefund':
          form.state=constant.payState[12].type
          form.messageType=constant.payState[12].type
          this.modify(form)
          break
        case 'modifyAmount':
          form.state=constant.payState[13].type
          form.messageType=constant.payState[13].type
          this.$prompt('请输入修改后的金额', '修改订单金额', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            if (value <= 0) {
              return
            }
            form.amount=value;
            this.modify(form)
          }).catch(() => {});
          break
        case 'applyPass':
          form.state=12
          form.messageType=12
          this.$confirm('订单金额确认无误吗?', '确认订单金额', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form)
          }).catch(() => {
          });
          break
        case 'freeze':
          form.state=16
          form.messageType=16
          this.$confirm('确定要冻结当前订单吗?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form,type)
          }).catch(() => {
          });
          break
      }
    },
    modify(form,type){
      orderModifyAPI(form).then(res=>{
        if (res.code === 200) {
          this.popUp(res);
          this.dialogVisible = false
          this.init()
          if (type === 'freeze') {
            // this.$refs.abnormalOrder.init()
          }
        }
      });
    },
    startCountDown(params) {
      if (params.totalSeconds <= 0) {
        this.init()
        return
      }
      // noinspection JSCheckFunctionSignatures
      let time={minutes:0,seconds:0,timer:null};
      // noinspection JSCheckFunctionSignatures
      let m = parseInt(params.totalSeconds / 60 % 60);
      time.minutes = m < 10 ? "0" + m : m
      // noinspection JSCheckFunctionSignatures
      let s = parseInt(params.totalSeconds % 60);
      time.seconds = s < 10 ? "0" + s : s
      params.totalSeconds=time
      //this.count = m + '分' + s + '秒'
      if (time.timer === null) {
        time.timer = setInterval(() => {
          params.totalSeconds--
          this.startCountDown()
        }, 1000);
      }
    },
    businessStateOpen(params){
      let form={}
      form.id=this.loginInfo.id
      form.roleId=this.loginInfo.roleId
      form.version=this.loginInfo.version
      form.businessState=params
      userInfoAPI(form.id).then(res=>{
        form.version=res.data.version
        if (res.data.authBusinessState === 1) {
          //修改
          userModifyAPI(form).then(res=>{
            if (res.code === 200) {
              this.popUp(res);
              this.loginInfo.updateTime=this.formatDate(new Date())
              this.loginInfo.businessState=params
              localStorage.setItem('userInfo',JSON.stringify(this.loginInfo))
            } else {
              this.loginInfo.businessState=0
            }
          });
        } else {
          this.loginInfo.businessState=res.data.businessState
          this.error('管理员已关闭盈利权限, 您无权限修改')
        }
      })
    },
  },
  filters: {

  },
  beforeDestroy() {
    this.$bus.$off('refreshOrder')
    this.$bus.$off('refreshLayout')
    console.log('beforeDestroy')
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
::v-deep .el-card__body{
  padding: 0;
}

::v-deep .el-card__header{
  padding: 0 0 10px 0;
}
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
    margin-bottom: 10px;
  }
}
.el-table ::v-deep th {
  //表头
  //padding: 0;
  white-space: nowrap;
  min-width: fit-content;
}

.el-table ::v-deep td {
  //padding: 1px;
  //white-space: nowrap;
  //width: fit-content;
}

/** 修改el-card默认paddingL:20px-内边距 **/
>>> .el-card__body {
  //padding: 10px;
}

.el-table ::v-deep .cell {
  //white-space: nowrap;
  //width: fit-content;
}
::v-deep .el-descriptions__header{
  margin-bottom: 10px;
}
::v-deep .el-table__fixed-header-wrapper ,::v-deep .el-table__fixed-right::before, ::v-deep .el-table__fixed::before{
  z-index: 0;
}
.tccoim{
  font-size: 12px;
}
</style>
