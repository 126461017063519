<template>
  <el-upload
      style="margin: 0 auto 10px;"
      drag
      :before-upload="handlePreview"
      :http-request="customRequest"
      ref="upload"
      accept=".png,.jpg,.jpeg"
      :action="action"
      multiple>
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，当文件上传完成后再保存</div>
  </el-upload>
</template>
<script>
export default {
  name: "M-Upload",
  props: {
    uploadUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      action: ''
    }
  },
  methods: {
    customRequest({file, onSuccess, onError}) {
      const reader = new FileReader();
      reader.onload = () => {
        // 使用 fetch API 发送 PUT 请求
        fetch(this.uploadUrl, {
          method: 'PUT',
          body: reader.result, // 直接使用读取的 ArrayBuffer
          headers: {
            'Content-Type': 'application/octet-stream', // 设置请求头
          },
        }).then(response => {
          if (response.ok) {
            this.$message.success({message:'上传成功',duration:800,showClose:true})
            this.$emit('success')
          } else {
            this.$message.error({message:'上传失败, 请重新上传',duration:800,showClose:true})
            onError(new Error('上传失败'));
          }
        }).catch(err => {
          onError(err);
        });
      };
      reader.onerror = () => {
        onError(new Error('文件读取失败'));
      };
      reader.readAsArrayBuffer(file); // 读取文件为 ArrayBuffer
    },
    handleSuccess(response) {
      console.log('上传成功', response);
    },
    handleError(err) {
      console.error('上传失败', err);
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-upload-dragger {
  width: 300px;
  margin: 0 auto;
}
</style>
