import request from "@/utils/request";

/**
 * 用户相关
 */
const modulePrefix = 'user'

/**
 * 分页
 */
export function userPageAPI(data) {
    return request({
        url: modulePrefix,
        method: 'get',
        params: data
    })
}

/**
 * 详情
 * @param id
 * @returns {*}
 */
export function userInfoAPI(id) {
    return request({
        url: modulePrefix + '/' + id,
        method: 'get',
    })
}

/**
 * 保存
 */
export function userSaveAPI(data) {
    return request({
        url: modulePrefix,
        method: 'post',
        data: data
    })
}

/**
 * 修改
 */
export function userModifyAPI(data) {
    delete data.updateTime
    return request({
        url: modulePrefix,
        method: 'put',
        data: data
    })
}

/**
 * 转账
 * @param data
 * @returns {*}
 */
export function transferMoneyAPI(data) {
    return request({
        url: modulePrefix + '/money',
        method: 'put',
        data:data
    })
}

/**
 * 修改余额
 * @param data
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function modifyBalanceAPI(data) {
    return request({
        url: modulePrefix + '/balance',
        method: 'put',
        data:data
    })
}

/**
 * 删除
 */
export function userDeleteAPI(idListStr) {
    return request({
        url: modulePrefix + '/batch?id=' + idListStr,
        method: 'delete'
    })
}

const twoModulePrefix = '/business'

/**
 * 码商分页
 * @param data
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function businessPageAPI(data) {
    return request({
        url: modulePrefix + twoModulePrefix,
        method: 'get',
        params: data
    })
}

/**
 * 码商排序
 * @param id
 * @param oSort
 * @param nSort
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function businessSortAPI(id, oSort, nSort) {
    return request({
        url: modulePrefix + `/sort/${id}/${oSort}/${nSort}`,
        method: 'put',
    })
}


/**
 * 同步充值记录
 * @param userId
 * @returns {*}
 */
export function topUpSaveAPI(userId) {
    return request({
        url: 'topUp/' + 1,
        method: 'post',
    })
}

/**
 * 充值记录分页
 * @param param
 * @returns {*}
 */
export function topUpPageAPI(param) {
    return request({
        url: 'topUp',
        method: 'get',
        params: param
    })
}

/**
 * 获取团长记录
 * @param param
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function leaderPageAPI(param) {
    return request({
        url: '/user/leader',
        method: 'get',
        params: param
    })
}

/**
 * 成员列表
 * @param param
 * @returns {*}
 */
export function memberPageAPI(param) {
    return request({
        url: '/user/junior',
        method: 'get',
        params: param
    })
}

/**
 * 资金审核通过
 */
export function topUpPassAPI(topId, type) {
    return request({
        url: `/topUp/${topId}/${type}`,
        method: 'put',
    })
}

/**
 * 提现
 * @param userId
 * @returns {*}
 */
export function withdrawAPI(userId) {
    return request({
        url: `/topUp/withdraw/${userId}`,
        method: 'post',
    })
}

/**
 * 支付方式列表
 * @param param
 * @returns {*}
 */
export function payTypeListAPI(param) {
    return request({
        url: '/user/host/'+param+'/0',
        method: 'get',
    })
}

/**
 * 添加支付方式
 * @param data
 * @returns {*}
 */
export function hostSaveAPI(data) {
    return request({
        url: '/user/host',
        method: 'post',
        data: data
    })
}

/**
 * 修改支付方式
 * @param data
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function hostModifyAPI(data) {
    return request({
        url: '/user/host',
        method: 'put',
        data: data
    })
}

/**
 * 上级设置下级佣金
 * @param data
 * @returns {*}
 */
export function commissionAPI(data) {
    return request({
        url: '/user/commission',
        method: 'put',
        data: data
    })
}

/**
 * 冻结余额
 * @param data
 * @returns {*}
 */
export function frozenBalanceAPI(data) {
    return request({
        url: '/user/frozen',
        method: 'put',
        data: data
    })
}

/**
 * 登录用户列表
 * @param params
 * @returns {*}
 */
export function loginUserListAPI(params) {
    return request({
        url: '/user/login',
        method: 'get',
        params
    })
}


/**
 * 批量操作 踢人下线
 * @param data
 * @returns {*}
 */
export function controlUserAPI(data) {
    return request({
        url: '/user/control',
        method: 'put',
        data
    })
}
