<template>
  <div>
    <div v-if="$terminal" style="display: flex;justify-content: space-between"  class="content-main-padding">
      <div style="width: 28%;">
        <el-card shadow="hover">
          <div>
            <el-button type="primary"
                       @click="openLog"
                       icon="el-icon-circle-plus">
              添加支付方式
            </el-button>
            <span class="font-weight-bold common-left-space">{{ currentUser }}</span>
          </div>
          <el-empty v-if="payTypeList && payTypeList.length===0" description="当前未添加支付通道"></el-empty>
          <el-table v-else :data="payTypeList" style="width: 100%">
            <el-table-column align="center" prop="collAccount" label="收款帐号">
              <template slot-scope="scope">
                {{ scope.row.userCheckDTO.collAccount }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="collAccount" label="收款人">
              <template slot-scope="scope">
                {{ scope.row.userCheckDTO.realName }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="type" label="支付类型">
              <template slot-scope="scope">
                <el-tag size="mini">{{ scope.row.type|dictTransfer(payTypeDict) }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="state" label="状态">
              <template slot-scope="scope">
                <el-switch
                    v-model="scope.row.state"
                    active-color="#13ce66"
                    :active-value="1"
                    @change="changeState(scope.row)"
                    :inactive-value="0">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-link @click="openLog('0',scope.row)" type="primary">修改</el-link>
                <el-link @click="deleteHost(scope.row)" class="common-left-space" type="danger">删除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
      <div v-role="'管理员'" style="width: 70%">
        <el-table
            ref="multipleTable"
            :header-row-style="{'color':'gray'}"
            border
            lazy
            highlight-current-row
            :data="tableData.records"
            style="width: 100%;"
            tooltip-effect="dark"
            @row-click="rowClick"
            @selection-change="handleSelectionChange"
            @sort-change="sortChange"
            @expand-change="handleExpandChange"
            :row-class-name="tableRowClassName">
          <el-table-column type="selection"
                           v-if="false"
                           :selectable="selectable"
                           align="center"
                           width="40">
          </el-table-column>
          <template v-for="(item,index) in columnList">
            <el-table-column :align="item.align"
                             :min-width="item.width"
                             v-if="!item.template"
                             :prop="item.prop"
                             :label="item.label">
            </el-table-column>
            <el-table-column :align="item.align"
                             v-else
                             :min-width="item.width"
                             :prop="item.prop"
                             :fit="true"
                             :label="item.label">
              <template slot-scope="scope">
                <template v-if="item.params==='control'">
                  <el-link type="danger">删除</el-link>
                </template>
                <template v-if="item.params==='businessState'">
                  {{ scope.row.businessState|businessStateTransfer }}
                </template>
                <template v-if="item.params==='payType'">
                  {{ scope.row.payType|payTypeTransfer() }}
                </template>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <paging
            :page-sizes="[10,20,30,40]"
            :update:page="form.pageNum"
            :update:limit="form.pageSize"
            :total="tableData.total"
            @changeSize="changeSize"
            @pagination="pagination"/>
      </div>
      <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <el-form :model="form"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item
              label="支付类型"
              prop="type">
            <el-select v-model="form.type"
                       :class="{'apply-shake': valid.type}"
                       style="width: 100%;"
                       placeholder="请选择收款方式">
              <el-option
                  v-for="item in payTypeDict"
                  v-if="item.disabled===false"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="false">
            <el-switch
                style="display: block"
                v-model="form.state"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                active-text="开启"
                inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item
              label="帐号"
              prop="collAccount">
            <el-input :class="{'apply-shake': valid.collAccount}"
                      show-word-limit
                      placeholder="请输入收款帐号"
                      v-model.trim="form.collAccount"></el-input>
          </el-form-item>
          <el-form-item
              label="姓名"
              prop="realName">
            <el-input :class="{'apply-shake': valid.sign}"
                      placeholder="请输入真实姓名"
                      v-model.trim="form.realName"></el-input>
          </el-form-item>
          <el-form-item
              v-if="form.type===2"
              label="开户行"
              prop="bankAddress">
            <el-input :class="{'apply-shake': valid.bankAddress}"
                      placeholder="请输入开户行所在地"
                      v-model.trim="form.bankAddress"></el-input>
          </el-form-item>
          <el-form-item
              v-if="form.type===2"
              label="银行名称"
              prop="bankName">
            <el-input :class="{'apply-shake': valid.bankName}"
                      placeholder="请输入银行名称"
                      v-model.trim="form.bankName"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-else>
      <div class="h5-content" style="text-align: end">
        <el-button type="primary"
                   size="mini"
                   @click="openLog"
                   icon="el-icon-circle-plus">
          添加支付方式
        </el-button>
      </div>
      <div class="h5-content" v-for="(item,index) in payTypeList">
        <h5-row :label="'收款帐号'" :value="item.userCheckDTO.collAccount"/>
        <h5-row :label="'姓名'" :value="item.userCheckDTO.realName"/>
        <h5-row :label="'支付类型'">
          <template slot="value">
            <el-tag size="mini">{{ item.type|dictTransfer(payTypeDict) }}</el-tag>
          </template>
        </h5-row>
        <h5-row :label="'状态'" >
          <template slot="value">
            <el-switch
                v-model="item.state"
                active-color="#13ce66"
                :active-value="1"
                @change="changeState(item)"
                :inactive-value="0">
            </el-switch>
          </template>
        </h5-row>
        <div class="h5-row-item-right" style="margin-top: 5px;">
          <el-link @click="openLog('0',item)" type="primary">修改</el-link>
          <el-link type="danger" @click="deleteHost(item)" class="common-left-space">删除</el-link>
        </div>
      </div>
      <el-empty v-if="payTypeList && payTypeList.length===0" description="当前未添加支付通道"></el-empty>
      <el-drawer
          :show-close="false"
          :withHeader="false"
          size="70%"
          :visible.sync="dialogVisible"
          direction="btt">
        <div style="padding:10px;width: 100%;text-align: right;">
          <button class="h5-color-bg-danger" style="color: white" @click="handleClose">关闭</button>
        </div>
        <div style="margin: 20px">
          <el-form :model="form"
                   :rules="rules"
                   ref="ruleForm"
                   style="margin-top: 10px"
                   label-width="100px"
                   class="demo-ruleForm">
            <el-form-item
                label="支付类型"
                prop="type">
              <el-select v-model="form.type"
                         :class="{'apply-shake': valid.type}"
                         style="width: 100%;"
                         placeholder="请选择收款方式">
                <el-option
                    v-for="item in payTypeDict"
                    v-if="item.disabled===false"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
                label="帐号"
                prop="collAccount">
              <el-input :class="{'apply-shake': valid.collAccount}"
                        show-word-limit
                        placeholder="请输入收款帐号"
                        v-model.trim="form.collAccount"></el-input>
            </el-form-item>
            <el-form-item
                label="姓名"
                prop="realName">
              <el-input :class="{'apply-shake': valid.sign}"
                        placeholder="请输入真实姓名"
                        v-model.trim="form.realName"></el-input>
            </el-form-item>

            <el-form-item
                v-if="form.type===2"
                label="开户行"
                prop="bankAddress">
              <el-input :class="{'apply-shake': valid.bankAddress}"
                        placeholder="请输入开户行所在地"
                        v-model.trim="form.bankAddress"></el-input>
            </el-form-item>
            <el-form-item
                v-if="form.type===2"
                label="银行名称"
                prop="bankName">
              <el-input :class="{'apply-shake': valid.bankName}"
                        placeholder="请输入银行名称"
                        v-model.trim="form.bankName"></el-input>
            </el-form-item>
          </el-form>
          <div style="width: 100%;text-align: right">
            <el-button size="mini" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import Copy from "@/components/Copy.vue";
import paging from "@/components/Paging.vue";
import {businessPageAPI, hostModifyAPI, hostSaveAPI, payTypeListAPI, userModifyAPI, userPageAPI} from "@/api/user";
import H5Row from "@/components/h5-row.vue";

let that = ''
const checkAccount = (rule, value, callback) => {
  if (value === undefined || value === '') {
    that.valid.collAccount = true
    setTimeout(() => {
      that.valid.collAccount = false
    }, 820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkSign = (rule, value, callback) => {
  if (value === undefined || value === '') {
    that.valid.sign = true
    setTimeout(() => {
      that.valid.sign = false
    }, 820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkbankAddress = (rule, value, callback) => {
  if (value === undefined || value === '') {
    that.valid.bankAddress = true
    setTimeout(() => {
      that.valid.bankAddress = false
    }, 820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkbankName = (rule, value, callback) => {
  if (value === undefined || value === '') {
    that.valid.bankName = true
    setTimeout(() => {
      that.valid.bankName = false
    }, 820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
export default {
  name: "PayType",
  components: {H5Row, paging, Copy},
  mixins: [common, dictTransfer, constant],
  data() {
    return {
      form: {
        pageSize: 10,
        pageNum: 1,
        collAccount: '',
        realName: '',
        // 1,2 支付宝银行卡
        type: '',
        userId: '',
        // 0 关闭，1开启
        state: '',
        id: '',
        bankAddress: '',
        bankName: '',
      },
      tableData: {
        total: 0,
        records: []
      },
      payTypeList: [],
      payTypeDict: constant.payType,
      payStateList: constant.phoneVerify,
      columnList: [
        {align: "center", label: "帐号", prop: "account", width: 150, template: false, params: 'account'},
        {align: "center", label: "昵称", prop: "nickName", width: 150, template: false, params: ''},
        {align: "center", label: "支付方式", prop: "payType", width: 150, template: true, params: 'payType'},
        {align: "center", label: "支付帐号", prop: "collAccount", width: 150, template: false, params: ''},
        {align: "center", label: "支付姓名", prop: "realName", width: 150, template: false, params: ''},
        {align: "center", label: "盈利状态", prop: "businessState", width: 100, template: true, params: 'businessState'},
      ],
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      ids: [],
      currentUser: '',
      dialogVisible: false,
      rules: {
        collAccount: [{validator: checkAccount, trigger: "blur"},],
        realName: [{validator: checkSign, trigger: "blur"},],
        type: [{validator: checkSign, trigger: "blur"},],
        bankName:[
          {validator: checkbankName, trigger: "blur"}
        ],
        bankAddress:[
            {validator: checkbankAddress, trigger: "blur"}
        ],
      },
      valid: {
        collAccount: false,
        sign: false,
        type: false,
        bankName:false,
        bankAddress:false,
      },
    }
  },
  mounted() {
    this.init()
    that = this
    this.$bus.$on('refreshLayout',()=>{
      this.init()
    })
  },
  methods: {
    init() {
      const parse = JSON.parse(localStorage.getItem('userInfo'));
      if (parse.roleName.includes('管理员')) {
        this.form.roleId = 2;
        this.form.userState = 1
        userPageAPI(this.form).then(res => {
          if (res.code === 200) {
            this.tableData = res.data
          }
        });
      } else {
        this.currentUser=parse
        this.currentUser = parse.nickName
        this.form.userId = parse.id
        payTypeListAPI(parse.id).then(res => {
          if (res.code === 200) {
            this.payTypeList = res.data
          }
        })
      }
      /*businessPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData=res.data
        }
      })*/
    },
    handleClose() {
      this.form.id = ''
      this.form.type = ''
      this.form.collAccount = ''
      this.form.realName = ''
      this.form.state = ''
      this.form.bankName=''
      this.form.bankAddress=''
      this.dialogVisible = false
    },
    openLog(type, params) {
      if (this.currentUser === '请先选择码商') {
        this.$message.error({message: '请先选择码商', showClose: true, duration: 800})
        return
      }
      if (params !== undefined) {
        this.form.id = params.id
        this.form.type = params.type
        this.form.collAccount = params.userCheckDTO.collAccount
        this.form.realName = params.userCheckDTO.realName
        this.form.state = params.state
        this.form.bankName = params.userCheckDTO.bankName
        this.form.bankAddress = params.userCheckDTO.bankAddress
      }
      if (this.form.userId === '') {
        this.$message.error({message: '请先选择用户', showClose: true})
        return;
      }
      this.dialogVisible = true;
    },
    deleteHost(params) {
      this.form.id=params.id
      this.form.type = params.type
      this.form.collAccount = ''
      this.form.realName = ''
      this.form.state = 2
      hostModifyAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.ok(res.msg)
          payTypeListAPI(this.form.userId).then(res => {
            if (res.code === 200) {
              this.payTypeList = res.data
              this.dialogVisible = false
              this.ok(res.msg)
              this.form.type = ''
              this.form.collAccount = ''
              this.form.realName = ''
              this.form.state = ''
              this.form.bankName = ''
              this.form.bankAddress = ''
            }
          })
          // this.init()
        }
      })
    },
    rowClick(row, column, event) {
      this.currentUser = row.nickName
      this.form.userId = row.id
      payTypeListAPI(row.id).then(res => {
        if (res.code === 200) {
          this.payTypeList = res.data
        }
      })
    },
    handleSelectionChange(rows) {
      this.ids = []
      rows.forEach((value, index, array) => {
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order) {
      if (column.order === 'descending') {
        this.form.orderType = ''
      } else {
        this.form.orderType = 'asc'
      }
      this.init()
    },
    changeState(params) {
      this.form.id = params.id
      this.form.type = params.type
      this.form.collAccount = params.userCheckDTO.collAccount
      this.form.realName = params.userCheckDTO.realName
      this.form.state = params.state;
      hostModifyAPI(this.form).then(res => {
        if (res.code === 200) {
          this.dialogVisible = false
          this.ok(res.msg)
          this.form.id = ''
          this.form.type = ''
          this.form.collAccount = ''
          this.form.realName = ''
          this.form.state = ''
          this.init()
        }
        payTypeListAPI(this.form.userId).then(res => {
          if (res.code === 200) {
            this.payTypeList = res.data
          }
        })
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          /*this.ruleForm.roleId=JSON.parse(localStorage.getItem('userInfo')).roleId
          userModifyAPI(this.ruleForm).then(res=>{
            this.resOk(res)
            this.init()
          })*/
          if (this.form.id !== '') {
            hostModifyAPI(this.form).then(res => {
              payTypeListAPI(this.form.userId).then(res => {
                if (res.code === 200) {
                  this.payTypeList = res.data
                }
              })
              if (res.code === 200) {
                this.dialogVisible = false
                this.ok(res.msg)
                this.form.id = ''
                this.form.type = ''
                this.form.collAccount = ''
                this.form.realName = ''
                this.form.state = ''
                this.form.bankName = ''
                this.form.bankAddress = ''
                this.init()
              }
            })
          } else {
            this.form.state=0
            if (this.form.userId === '') {
              this.$message.error({message: '请先选择用户', showClose: true})
            }
            hostSaveAPI(this.form).then(res => {
              if (res.code === 200) {
                this.dialogVisible = false
                this.ok(res.msg)
                this.form.id = ''
                this.form.type = ''
                this.form.collAccount = ''
                this.form.realName = ''
                this.form.state = ''
                payTypeListAPI(this.form.userId).then(res => {
                  if (res.code === 200) {
                    this.payTypeList = res.data
                  }
                })
                this.init()
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleExpandChange(row, expanded) {
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
          return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    selectable(row, index) {
      return row.state === constant.payState[0].type
    },
    pagination(obj) {
      this.form.pageSize = obj.limit
      this.init()
    },
    changeSize(obj) {
      this.form.pageNum = obj.page
      this.init()
    },
  },
  beforeDestroy() {
    that = null
    this.$bus.$off('refreshLayout')
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";

::v-deep .el-card__body {
  padding: 10px;
}

.text-center {
  background: red !important;
}

</style>
