<template>
  <div >
    <div v-if="$terminal" class="content-main-padding">
      <WalletRecord/>
    </div>
    <div v-else>
      <WalletRecord/>
    </div>
  </div>
</template>
<script>
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import WalletRecord from "@/views/info/WalletRecord.vue";

export default {
  name: "FlowStatistics",
  components: {WalletRecord},
  mixins:[common,dictTransfer,constant],
  data(){
    return{
      form:{
        pageSize:10,
        pageNum: 1,
      },
      tableData:{
        total:0,
        records:[]
      },
      columnList:[],
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      ids:[],
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){},
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    selectable(row, index){
      return row.state===constant.payState[0].type
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
</style>
