<script>
export default {
  name: "h5-row",
  props:{
    label:{
      type:String,
      default:''
    },
    value:{
      type:String|Number,
      default:''
    },
    hr:{
      type:Boolean,
      default:true
    }
  }
}
</script>

<template>
  <div>
    <div class="h5-row">
      <div class="label-height">{{label}}</div>
      <slot name="value">{{value}}</slot>
    </div><hr v-show="hr">
  </div>
</template>

<style scoped lang="scss">
@import "../assets/style/conmmon-style.scss";
</style>
