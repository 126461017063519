<template>
  <span v-copy class="font-fine copy-underline">{{text}} <i v-show="text" class="el-icon-copy-document"/> </span>
</template>
<script>
export default {
  name: "Copy",
  props:{
    text:{
      type:String|Number,
      default:''
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/style/conmmon-style.scss";
</style>
