import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import i18n from "@/i18n";
import {changeTitle, getPath} from "@/router";
import router from "@/router";
import {MessageBox} from "element-ui";
import {Message} from "element-ui";
import {logoutAPI, modifyPwdAPI, verifyPwdAPI} from "@/api/Login";
import {encryptText} from "@/utils/jsencrypt";
import {routerhandle} from "@/router/routesmap";

const store = new Vuex.Store({
    state: {
        i18n: 'zh-CN',
        terminal: false,
        permissions:[],
        userInfo:null,
        eventNum:0,
    },
    plugins: [exportTerminal],
    getters: {
        i18n(state) {
            return state.i18n
        }
    },
    mutations: {
        i18n(state) {
        },
        isH5(state, terminal) {
            state.terminal = terminal
            exportTerminal(store)
        },
    },
    actions: {
        i18n({commit, state}) {
            i18n.locale = state.i18n
            getPath()
        },
        logout() {
            logoutAPI().then(res=>{
                if (res.code === 200) {
                    router.push('/')
                    localStorage.clear()
                }
            })
        },
        updatePwd() {
            if (JSON.parse(localStorage.getItem('userInfo'))) {
            } else {
                Message.error({message:'请先登录',showClose:true})
                router.push('/')
                return
            }
            MessageBox.prompt('请先验证旧密码', '修改密码', {
                distinguishCancelAndClose: true, // 点击取消和关闭按钮会返回不同的值
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '请输入旧密码', // 输入框的占位符
                customClass: 'MessageBox-prompt'
            }).then(({value}) => {
                let form={
                    id:JSON.parse(localStorage.getItem('userInfo')).id,
                    password:encryptText(value)
                };
                verifyPwdAPI(form).then(res=>{
                    if (res.data) {
                        Message.success('验证成功');
                        MessageBox.prompt('请输入新密码', '修改密码', {
                            distinguishCancelAndClose: true, // 点击取消和关闭按钮会返回不同的值
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            inputPlaceholder: '请输入新密码', // 输入框的占位符
                            customClass: 'MessageBox-prompt'
                        }).then(({value}) => {
                            let newPwd={
                                id:JSON.parse(localStorage.getItem('userInfo')).id,
                                password:encryptText(value)
                            };
                            modifyPwdAPI(newPwd).then(res=>{
                                Message.success('修改成功');
                            })
                        }).catch(() => {
                        });
                    } else {
                        Message.error('密码错误')
                    }
                })
            }).catch(() => {
            });
        },
        isH5({commit, state}) {
            //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
            const info = navigator.userAgent;
            //通过正则表达式的test方法判断是否包含“Mobile”字符串
            commit('isH5', ((!info.includes('Mobile')) && window.innerWidth > 1210))
        },
        pushPath({commit, state}, route) {
            if (route !== undefined || route !== 'null') {
                const rt = routerhandle(route)
                rt.forEach(r=>{
                    router.addRoute(r);
                })
                router.push(router.getRoutes()[2].path)
            }
        },
        // 新订单
        audioNewOrder(){
            try {
                const music = new Audio('audio/new_order.mp3');
                music.play()
                // let audio = document.getElementById('audioPlayer')
                // audio.src='audio/new_order.mp3'
                // audio.play()
            }catch (e) {
                console.log(e)
            }
        },
        orderCOk(){
            try {
                const music = new Audio('audio/order_c_ok.mp3');
                music.play()
            }catch (e) {
                console.log(e)
            }
        },
        //手动获取,自动获取取消订单
        audioCancelOrderConsumer(){
            try {
                const music = new Audio('audio/order_cancel.mp3');
                music.play()
            }catch (e) {
                console.log(e)
            }
        },
        //码商取消订单
        audioCancelOrderBusiness(){
            try {
                const music = new Audio('audio/business_cancel_order.mp3');
                music.play()
            }catch (e) {
                console.log(e)
            }
        },
        //钱到账
        audioMoney(){
            try {
                const music = new Audio('audio/Money_has_arrived.mp3');
                music.play()
            }catch (e) {
                console.log(e)
            }
        },
    },
    modules: {}
})
export default store

// 创建自定义插件
function exportTerminal(store) {
    // 将store的某个部分添加到Vue原型上
    Vue.prototype.$terminal = store.state.terminal;
}

const loadView = (view) => {
    // 路由懒加载
    return (resolve) => require(['@' + view], resolve)
}

//为权限路由异步添加组件
export const filterAsyncRouter = (routeList) => {
    return routeList.filter((route) => {
        if (route.component && route.title !== 'Layout') {
            // 如果不是布局组件就只能是页面的引用了
            // 利用懒加载函数将实际页面赋值给它
            route.component = loadView(route.component)
            // 判断是否存在子路由，并递归调用自己
            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children)
            }
            return true
        }
    })
}

function childMenu(menuList,menu) {
    if (menuList.children) {
        menuList.forEach(item => {
            childMenu(item.children,menu)
        });
    } else {
        menu.children.push({
            // 路由的路径
            path: menuList.path,
            // 路由名
            name: menuList.name,
            // 路由所在组件
            // component: (resolve) => require([`@/layout/Index`], resolve),
            component: (resolve) => require([`@${menuList.component}`], resolve),
            meta: {
                title: menuList.name,
                icon: menuList.icon
            },
            children: []
        })
    }
}
