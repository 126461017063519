<template>
  <History/>
</template>
<script>
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import paging from "@/components/Paging.vue";
import {orderModifyAPI, orderPageAPI} from "@/api/order";
import History from "@/views/history/History.vue";

export default {
  name: "OrderOther",
  components: {History}
}
</script>

<style scoped lang="scss">
</style>
