<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <div class="header-d">
        <el-select v-model="form.userState"
                   clearable
                   v-if="false"
                   @change="init"
                   placeholder="用户状态">
          <el-option
              v-for="item in options"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-date-picker
            class="common-left-space"
            v-model="timeArr"
            type="daterange"
            align="left"
            @change="init"
            format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-input-number v-model="form.level" class="common-left-space" @change="init" :max="150" label="层级"></el-input-number>
        <el-button type="primary"
                   @click="type='支付宝'"
                   v-if="false"
                   class="common-left-space">切换支付宝
        </el-button>
        <el-button type="primary"
                   @click="type='银行卡'"
                   v-if="false"
                   class="common-left-space">切换银行卡
        </el-button>
        <el-button class="common-left-space"
                   @click="form.level='';form.account='';form.parentId='';init()"
                   type="info">
          <i class="el-icon-refresh"></i>
          重置搜索
        </el-button>
        <el-input
            @keyup.enter.native="init"
            clearable
            class="header-d-input"
            v-model="form.account"
            placeholder="支持帐号、昵称、姓名模糊搜索">
          <el-button @click="init"
                     slot="append"
                     icon="el-icon-search">
          </el-button>
        </el-input>
      </div>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          @row-click=""
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         align="center"
                         v-if="false"
                         width="55">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template && item.type.includes(type)"
                           :show-overflow-tooltip="false"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :fixed="item.fixed"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else-if="item.template && item.type.includes(type)"
                           :fixed="item.fixed"
                           :show-overflow-tooltip="false"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link type="warning"
                         size="mini"
                         class="common-left-space"
                         @click="controls('modify',scope.row)">修改</el-link>
                <el-link type="danger"
                         class="common-left-space"
                         size="mini"
                         @click="controls('sort',scope.row)">{{$t('table.sort')}}</el-link>
                <el-link type="primary"
                         class="common-left-space"
                         size="mini"
                         @click="dialogVisible=true;form.fromId=scope.row.id">转账</el-link>
                <el-link type="success"
                         class="common-left-space"
                         size="mini"
                         @click="dialogBalance=true;form.id=scope.row.id">充值</el-link>
                <el-dropdown class="common-left-space">
                  <span class="el-dropdown-link" style="color: #409EFF;cursor: pointer">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-notebook-2" @click.native="controls('info',scope.row)">{{$t('table.seeBusiness')}}</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus" @click.native="controls('frozenBalance',scope.row)" >冻结余额</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-minus" @click.native="controls('frozenBalanceCancel',scope.row)" >解冻余额</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-warning-outline" @click.native="controls('black',scope.row)" >{{$t('table.shield')}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template v-if="item.params==='businessState'">
                <el-switch
                    @change="businessStateOpen(scope.row)"
                    v-model="scope.row.businessState"
                    active-color="#13ce66"
                    inactive-color="#DCDFE6"
                    :active-value="1"
                    :inactive-value="0"
                    active-text="开启"
                    inactive-text="关闭">
                </el-switch>
              </template>
              <template v-if="item.params==='userState'">
                {{scope.row.userState|userStateTransfer}}
              </template>
              <template v-if="item.params==='countAliSuccessRate'">
                {{ (scope.row.countAliTotal !== 0 ? ((scope.row.countAliSuccess / scope.row.countAliTotal) * 100 ).toFixed(2) + '%' : '0.00%') }}
              </template>
              <template v-if="item.params==='countBankSuccessRate'">
                {{ (scope.row.countBankTotal !== 0 ? ((scope.row.countBankSuccess / scope.row.countBankTotal) * 100).toFixed(2) + '%' : '0.00%') }}
              </template>
              <template v-if="item.params==='payType'">
                {{scope.row.payType|payTypeTransfer()}}
              </template>
              <template v-if="item.params==='usdtAddress'">
                <Copy :text="scope.row.usdtAddress"/>
              </template>
              <template v-if="item.params==='openVerify'">
                {{scope.row.openVerify|dictTransfer(openVerifyList)}}
              </template>
              <template v-if="item.params==='account'">
                <Copy :text="scope.row.account"/>
              </template>
              <template v-if="item.params==='level'">
                <span class="el-link--primary" style="cursor: pointer;color: #409EFF" @click="rowClick(scope.row)">{{scope.row.parentId.length/7+'级'}}</span>
              </template>
              <template v-if="item.params==='juniorCount'">
                {{scope.row.juniorCount===undefined?'0':scope.row.juniorCount}}
              </template>
              <template v-if="item.params==='usdtBalance'">
                {{Number(scope.row.usdtBalance).toFixed(2)}}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
      <SaveOrUpdateUser :role-id="2" @reload="init" ref="saveOrUpdateUser"/>
      <el-dialog
          title="转账"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <el-input placeholder="请输入对方帐号" v-model.trim="form.toAccount"></el-input>
        <el-input-number style="width: 100%;margin-top: 20px;margin-bottom: 20px" placeholder="请输入转账金额" v-model.trim="form.amount"></el-input-number>
        <div style="width: 100%;text-align: right">
          <el-popconfirm
              confirm-button-text='确定'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              @confirm="controls('transferMoney')"
              @cancel="handleClose"
              title="帐号、金额确认无误吗？">
            <el-button slot="reference" type="primary">转账</el-button>
          </el-popconfirm>
        </div>
      </el-dialog>
      <el-dialog
          title="充值"
          :visible.sync="dialogBalance"
          width="30%"
          :before-close="handleClose">
        <el-radio-group v-model="form.type">
          <el-radio :label="0">TCCOIM</el-radio>
          <el-radio v-if="false" :label="1">佣金余额</el-radio>
        </el-radio-group><br><br>
        <el-radio-group v-model="form.direction">
          <el-radio :label="0">增加</el-radio>
          <el-radio :label="1">减少</el-radio>
        </el-radio-group>
        <el-input-number :min="0" style="width: 100%;margin-top: 20px;margin-bottom: 20px" placeholder="请输入充值金额" v-model.trim="form.amount"></el-input-number>
        <div style="width: 100%;text-align: right">
          <el-popconfirm
              confirm-button-text='确定'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              @confirm="controls('transferBalance')"
              @cancel="handleClose"
              title="金额确认无误吗？">
            <el-button slot="reference" type="primary">充值</el-button>
          </el-popconfirm>
        </div>
      </el-dialog>
    </div>
    <div v-else>
      business
    </div>
  </div>
</template>

<script>
import Copy from "@/components/Copy.vue";
import paging from "@/components/Paging.vue";
import SaveOrUpdateUser from "@/views/info/SaveOrUpdateUser.vue";
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import {
  businessPageAPI,
  businessSortAPI,
  frozenBalanceAPI,
  modifyBalanceAPI,
  transferMoneyAPI, userDeleteAPI,
  userInfoAPI, userModifyAPI
} from "@/api/user";
import {controlAPI} from "@/api/Login";

export default {
  name: "StatisticsMS",
  mixins:[common,dictTransfer],
  components:{Copy, SaveOrUpdateUser, paging},
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        {align:"center",label:"码商等级",prop:"level",width:80,template:true,params:'level',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"子级数量",prop:"juniorCount",width:80,template:true,params:'juniorCount',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"码商名称",prop:"nickName",width:'auto',template:false,params:'',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"通道名称",prop:"payType",width:110,template:true,params:'payType',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"TCCOIM",prop:"usdtBalance",width:100,template:true,params:'usdtBalance',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"冻结金额",prop:"frozenBalance",width:90,template:false,params:'openVerify',fixed:false,type:'银行卡,支付宝'},
        {align:"center",label:"利润",prop:"totalProfit",width:'auto',template:false,params:'',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"支付宝交易金额",prop:"totalAmountAli",width:120,template:false,params:'',fixed:false,type:'支付宝,银行卡'},
        //{align:"center",label:"支付宝盈利金额",prop:"profitAli",width:120,template:false,params:'',fixed:false,type:'支付宝'},
        // {align:"center",label:"银行卡盈利金额",prop:"profitBank",width:120,template:false,params:'',fixed:false,type:'银行卡'},
        // {align:"center",label:"支付宝平台总金额",prop:"platformProfitAli",width:140,template:false,params:'',fixed:false,type:'支付宝'},
        // {align:"center",label:"银行卡平台总金额",prop:"platformProfitBank",width:140,template:false,params:'',fixed:false,type:'银行卡'},
        {align:"center",label:"支付宝成功交易数",prop:"countAliSuccess",width:140,template:false,params:'',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"支付宝订单总数",prop:"countAliTotal",width:120,template:false,params:'',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"支付宝成功交易率",prop:"countAliSuccessRate",width:140,template:true,params:'countAliSuccessRate',fixed:false,type:'支付宝,银行卡'},
        {align:"center",label:"银行卡交易金额",prop:"totalAmountBank",width:120,template:false,params:'',fixed:false,type:'银行卡,支付宝'},
        {align:"center",label:"银行卡成功交易数",prop:"countBankSuccess",width:140,template:false,params:'',fixed:false,type:'银行卡,支付宝'},
        {align:"center",label:"银行卡订单总数",prop:"countBankTotal",width:120,template:false,params:'',fixed:false,type:'银行卡,支付宝'},
        {align:"center",label:"银行卡成功交易率",prop:"countBankSuccessRate",width:'auto',template:true,params:'countBankSuccessRate',fixed:false,type:'银行卡,支付宝'},
        //{align:"center",label:"盈利总额",prop:"countBankSuccessRate",width:140,template:true,params:'countBankSuccessRate',fixed:false,type:'银行卡,支付宝'},
        // {align:"center",label:"接单开关",prop:"businessState",width:120,template:true,params:'businessState',fixed:false,type:'银行卡,支付宝'},
        // {align:"center",label:"权重",prop:"sort",width:70,template:false,params:'',fixed:false,type:'支付宝,银行卡'},
        // {align:"center",label:"谷歌验证器",prop:"openVerify",width:100,template:true,params:'openVerify',fixed:false,type:'银行卡,支付宝'},
        // {align:"center",label:"充值地址",prop:"usdtAddress",width:130,template:true,params:'usdtAddress',fixed:false,type:'支付宝,银行卡'},
        // {align:"center",label:"邀请人",prop:"registerName",width:'auto',template:false,params:'usdtAddress',fixed:false,type:'支付宝,银行卡'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        userState: 1,
        account: '',
        roleId: '',
        id:'',
        fromId:'',
        toAccount: '',
        amount:'',
        parentId: '',
        direction: 0,
        type: 0,
        level:''
      },
      ids:[],
      options:constant.userState,
      roleList:constant.roleList,
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      type:'银行卡',
      openVerifyList:constant.phoneVerify,
      dialogVisible:false,
      dialogBalance:false,
    }
  },
  mounted() {
    if (this.$route.query.register!==undefined) {
      this.form.userState=0
    }
    this.init();
  },
  computed: {},
  watch: {},
  methods: {
    init(){
      this.form.roleId=2
      if (this.form.level<=0){
        this.form.level=''
      }
      // this.form.userState=1
      this.form.startTime=this.formatDate(this.timeArr[0],'start');
      this.form.endTime=this.formatDate(this.timeArr[1],'end');
      //this.tableData.list=[]
      //this.tableData.total=0
      businessPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
        }
      })
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    rowClick(row, column, event){
      this.form.parentId=row.parentId
      this.form.level=0
      this.init()
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
      this.$refs.saveOrUpdateUser.dialogUser=true
    },
    controls(type,params){
      switch (type) {
        case 'info':
          if (constant.userRoleName === '码商') {
            this.goPage('/orderList')
          } else {
            this.$router.push({path:'/orderList',query:{businessId:params.id}})
          }
          break
        case 'black':
          controlAPI(2,params.id).then(res=>{
            this.popUp(res)
            if (res.code === 200) {
              this.init()
            }
          })
          break
          //修改
        case 'modify':
          userInfoAPI(params.id).then(res=>{
            if (res.code === 200) {
              let info=res.data
              info.roleId=2
              this.$refs.saveOrUpdateUser.form = info
              this.$refs.saveOrUpdateUser.formBack = info
              this.$refs.saveOrUpdateUser.dialogUser = true
            }
          })
          break
        case 'sort':
          this.$prompt('请输入码商权重(最大权重为99,权重越高,越容易获得订单)', '码商权重', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /^\d+$/,
            inputErrorMessage: '输入格式不正确, 只能输入数字'
          }).then(({ value }) => {
            value=parseInt(value)
            if (value > 99) {
              this.$message.error({message:'权重值不能超过99'})
            }
            businessSortAPI(params.id,params.sort,value).then(res=>{
              this.popUp(res)
              if (res.code === 200) {
                this.init()
              }
            });
          }).catch(() => {});
          break
        case 'transferMoney':
          if (this.form.amount === '' || Number(this.form.amount) <= 0) {
            this.$message.error({message:'转账金额不能为空或者小于等于0',showClose:true})
            return
          }
          if (this.form.toAccount === '') {
            this.$message.error({message:'转账金额不能为空或者小于等于0',showClose:true})
            return
          }
          transferMoneyAPI(this.form).then(res=>{
            if (res.code === 200) {
              this.ok(res.msg)
              this.handleClose()
            }
          });
          break
        case 'transferBalance':
          modifyBalanceAPI(this.form).then(res=>{
            if (res.code === 200) {
              this.ok(res.msg)
              this.handleClose()
              this.init()
            }
          })
          break;
        case 'frozenBalance':
          this.$prompt('请输入需要冻结的金额', '冻结余额', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /\d/,
            inputErrorMessage: '格式不正确'
          }).then(({ value }) => {
            let form={id:params.id,direction:1,frozenBalance:value}
            frozenBalanceAPI(form).then(res=>{
              if (res.code === 200) {
                this.ok(res.msg)
                this.init()
              }
            })
          }).catch(() => {});
          break
        case 'frozenBalanceCancel':
          this.$prompt('请输入需要解冻的金额', '解冻余额', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /\d/,
            inputErrorMessage: '格式不正确'
          }).then(({ value }) => {
            let form={id:params.id,direction:0,frozenBalance:value}
            frozenBalanceAPI(form).then(res=>{
              if (res.code === 200) {
                this.ok(res.msg)
                this.init()
              }
            })
          }).catch(() => {});
          break
      }
    },
    handleClose(){
      this.dialogVisible=false
      this.dialogBalance=false
      this.form.fromId=''
      this.form.amount=''
      this.form.toAccount=''
      this.form.id=''
    },
    businessStateOpen(params){
      let form={}
      form.id=params.id
      form.roleId=2
      form.businessState=params.businessState
      if (params.businessState === 1) {
        form.authBusinessState=form.businessState
      } else {
        form.authBusinessState=form.businessState
      }
      userInfoAPI(params.id).then(res=>{
        form.version=res.data.version
        userModifyAPI(form).then(res=>{
          if (res.code === 200) {
            this.popUp(res);
            this.init()
          }
        });
      });
    },
    batchDelete(){
      userDeleteAPI(this.ids).then(res=>{
        this.ids=[]
        this.popUp(res)
        this.init()
      })
    },
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
  }
}
</style>
