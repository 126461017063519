<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <div class="header-d">
        <el-select v-model="form.state"
                   clearable
                   @change="init"
                   placeholder="订单状态">
          <el-option
              v-for="item in options"
              v-if="item.scope.includes('history')"
              :key="item.type"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-date-picker
            class="common-left-space"
            v-model="timeArr"
            type="daterange"
            align="left"
            @change="init"
            format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-button type="info"
                   style=""
                   @click="init"
                   class="common-left-space">
          <i class="el-icon-refresh"></i>
          {{$t('table.resetSearch')}}
        </el-button>
        <el-button v-if="false" type="primary" class="common-left-space" style="float:right;" @click="controlFold">展开菜单</el-button>
        <el-input
            @keyup.enter.native="init"
            clearable
            show-word-limit
            class="header-d-input"
            v-model.trim="form.orderId"
            placeholder="根据订单号搜索">
          <el-button @click="init" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="flex-around" style="gap: 10px">
        <ConsumerUser
            ref="consumer-user"
            :show-toggle="$checkRole('管理员,客服')"
            :type="$checkRole('管理员,客服')"
            v-if="$checkRole('管理员,客服')"
            @refresh="init"
            style="width: 10%;height: fit-content;margin-top: 0"/>
        <div :style="$checkRole('管理员,客服')?{width: '89%'}:{width: '100%'}">
          <el-table
              ref="multipleTable"
              :header-row-style="{'color':'gray'}"
              border
              lazy
              :data="tableData.list"
              style="width: 100%;"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              @sort-change="sortChange"
              @expand-change="handleExpandChange"
              :row-class-name="tableRowClassName">
            <el-table-column type="selection"
                             v-if="false"
                             width="40">
            </el-table-column>
            <template v-for="(item,index) in columnList">
              <el-table-column :align="item.align"
                               v-if="!item.template"
                               :show-overflow-tooltip="true"
                               :width="item.width"
                               :prop="item.prop"
                               :key="index"
                               :label="item.label">
              </el-table-column>
              <el-table-column :align="item.align"
                               v-else-if="item.template && (!item.fold)"
                               :show-overflow-tooltip="true"
                               :width="item.width"
                               :prop="item.prop"
                               :key="index"
                               :label="item.label">
                <template slot-scope="scope">
                  <template v-if="item.params==='control'">
                    <OrderControl @refresh="init" :row="scope.row"/>
                  </template>
                  <template v-else-if="item.params==='payState'">
                    <el-tag :type="scope.row.state===7 ?
                'warning' : scope.row.state===-1 ?
                'success':scope.row.state===6 ?
                'danger':scope.row.state===3 ? 'warning' : ''"
                            size="mini">{{scope.row.state|orderStateTransfer()}}</el-tag>
                  </template>
                  <template v-else-if="item.params==='payType'">
                    {{scope.row.payType|payTypeTransfer()}}
                  </template>
                  <template v-else-if="item.params==='id'">
                    <Copy :text="scope.row.id"/>
                  </template>
                  <template v-else-if="item.params==='cPayAccount'">
                    <Copy :text="scope.row.cPayAccount"/>
                  </template>
                  <template v-else-if="item.params==='notice'">
                    <el-tag size="mini" :type="scope.row.notice === 1 ? 'success' : 'warning'">{{scope.row.state|noticeStateTransfer}}</el-tag>
                  </template>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <paging
              :page-sizes="[10,20,25,30,40]"
              :update:page="form.pageNum"
              :update:limit="form.pageSize"
              :total="tableData.total"
              @changeSize="changeSize"
              @pagination="pagination"/>
        </div>
      </div>
    </div>
    <div v-else>
      <el-tabs v-if="false" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-if="$checkRole('管理员,客服')" label="异常订单" name="third">
          <div class="h5-content">
            <div>
              <div style="display: flex">
                <DateRange v-model="timeArr"/>
                <el-input
                    size="mini"
                    @keyup.enter.native="init"
                    clearable
                    show-word-limit
                    style="width: 300px;float:right;"
                    v-model="form.orderId"
                    placeholder="订单号">
                </el-input>
              </div>
              <div style="display: flex;margin-top: 5px">
                <el-input
                    size="mini"
                    @keyup.enter.native="init"
                    clearable
                    show-word-limit
                    style="width: 300px;float:right;"
                    v-model="form.orderId"
                    placeholder="订单号">
                </el-input>
                <el-input
                    size="mini"
                    @keyup.enter.native="init"
                    clearable
                    show-word-limit
                    style="width: 300px;float:right;"
                    v-model="form.businessId"
                    placeholder="商户号">
                </el-input>
                <button @click="init" class="el-button--primary">
                  <i class="el-icon-search"></i>
                </button>
              </div>
            </div>
          </div>
          <el-empty class="h5-content" v-if="tableData.total === 0"/>
          <paging
              class="h5-content"
              :layout="'total,prev, pager,next'"
              :component-size="true"
              :page-sizes="[10,20,25,30,40]"
              :update:page="form.pageNum"
              :update:limit="form.pageSize"
              :total="tableData.total"
              @changeSize="changeSize"
              @pagination="pagination"/>
        </el-tab-pane>
      </el-tabs>
      <div class="h5-content">
        <div>
          <div style="display: flex">
            <DateRange v-model="timeArr"/>
            <el-input
                size="mini"
                @keyup.enter.native="init"
                clearable
                show-word-limit
                style="width: 300px;float:right;"
                v-model="form.orderId"
                placeholder="订单号">
            </el-input>
          </div>
          <div style="display: flex;margin-top: 5px">
            <el-select v-model="form.state"
                       clearable
                       size="mini"
                       @change="init"
                       placeholder="订单状态">
              <el-option
                  v-for="item in options"
                  v-if="item.scope.includes('history')"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type">
              </el-option>
            </el-select>
            <el-input
                size="mini"
                @keyup.enter.native="init"
                clearable
                show-word-limit
                style="width: 100%"
                v-model="form.businessId"
                placeholder="商户号">
            </el-input>
            <button @click="init" class="el-button--primary">
              <i class="el-icon-search"></i>
            </button>
          </div>
        </div>
      </div>
      <!--列表-->
      <order-card @refresh="init" :table-data="tableData"/>
      <!--分页-->
      <paging
          class="h5-content"
          :component-size="true"
          :layout="'total,prev, pager,next'"
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
  </div>
</template>

<script>
import paging from "@/components/Paging";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import {orderModifyAPI, orderPageAPI} from "@/api/order";
import common from "@/mixin/common";
import Copy from "@/components/Copy.vue";
import OrderControl from "@/components/OrderControl.vue";
import ConsumerUser from "@/components/ConsumerUser.vue";
import DateRange from "@/components/DateRange.vue";
import H5Row from "@/components/h5-row.vue";
import OrderCard from "@/components/OrderCard.vue";

export default {
  name: "History",
  components:{OrderCard, H5Row, DateRange, ConsumerUser, OrderControl, Copy, paging},
  mixins:[dictTransfer,common],
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        {align:"center",label:"订单号",prop:"id",width:80,template:true,params:'id',show:'',detail:true,fold:false},
        {align:"center",label:"支付方式",prop:"payType",width:100,template:true,params:'payType',show:'',detail:true,fold:false},
        {align:"center",label:"交易金额",prop:"amount",width:100,template:false,params:'',show:'',detail:true,fold:false},
        {align:"center",label:"支付状态",prop:"state",width:100,template:true,params:'payState',show:'',detail:true,fold:false},
        {align:"center",label:"通知状态",prop:"notice",width:100,template:true,params:'notice',show:'',detail:true,fold:false},
        {align:"center",label:"创建时间",prop:"createTime",width:'auto',template:false,params:'',show:'',detail:true,fold:false},
        {align:"center",label:"支付时间",prop:"payTime",width:'auto',template:false,params:'',show:'',detail:true,fold:false},
        // {align:"center",label:"更新时间",prop:"updateTime",width:'auto',template:false,params:'',show:'',detail:true,fold:false},
        // {align:"center",label:"码商昵称",prop:"bNickName",width:100,template:false,params:'payState',show:'',detail:true,fold:false},
        // {align:"center",label:"平台昵称",prop:"cNickName",width:100,template:false,params:'payState',show:'',detail:true,fold:false},
        {align:"center",label:"付款人账号",prop:"cPayAccount",width:'auto',template:true,params:'cPayAccount',show:'',detail:true,fold:false},
        {align:"center",label:"付款人姓名",prop:"cRealName",width:'auto',template:false,params:'',show:'',detail:true,fold:false},
        {align:"center",label:"收款人账号",prop:"bCollAccount",width:'auto',template:false,params:'',show:'',detail:true,fold:false},
        {align:"center",label:"收款人姓名",prop:"bRealName",width:'auto',template:false,params:'',show:'',detail:true,fold:false},
        {align:"center",label:"操作",prop:"answer",width:100,template:true,params:'control',show:'',detail:true,fold:false},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        orderId: '',
        businessId: '',
        startTime: '',
        endTime: '',
        state:''
      },
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      ids:[],
      options:constant.payState,
      noticeList:constant.notice,
      activeName:'first',
    }
  },
  mounted() {
    this.init()
    this.$bus.$on('refreshLayout',()=>{
      this.init()
    })
  },
  computed: {},
  watch: {},
  methods: {
    init(params,type){
      if (params !== undefined) {
        //this.form.businessId=params.id
        // true 商户 false 手动获取,自动获取
        if (type) {
          this.form.userId=''
          this.form.businessId=params.id
        } else {
          this.form.businessId=''
          this.form.userId=params.id
        }
      }

      let form = JSON.parse(JSON.stringify(this.form));
      if (this.form.state==="") {
        form.state='6,7,-1,14,11,21'
      } else {
        form.state=this.form.state
      }
      if (this.timeArr) {
        if (this.$terminal) {
          if (this.timeArr.length > 0) {
            form.startTime=this.formatDate(this.timeArr[0],'start');
            form.endTime=this.formatDate(this.timeArr[1],'end');
          }
        } else {
          form.startTime=this.timeArr[0]
          form.endTime=this.timeArr[1]
        }
      }
      if (form.state==7) {
        form.state='7,21'
      }
      orderPageAPI(form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
        }
      });
    },
    handleClick(tab, event) {
      if (tab.name === 'first') {
        this.form.state=""
        this.init()
      } else if (tab.name === 'second') {

      }else if (tab.name === 'third') {
        this.form.state="9999"
        console.log(this.form)
        this.init()
      }
    },
    controlFold(){
      this.columnList.forEach((value, index, array)=>{
        if (value.label === '操作') {
          value.fold=!value.fold
        }
      })
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    replenishment(params){
      this.form.id=params.id
      this.form.businessId=params.businessId
      this.form.userId=params.userId
      this.form.orderVersion=params.orderVersion
      let form=JSON.parse(JSON.stringify(this.form))
      form.state=11
      form.messageType=11
      this.$confirm('确认补单操作吗?', '危险操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        orderModifyAPI(form).then(res=>{
          if (res.code === 200) {
            this.popUp(res);
            this.init()
          }
        });
      }).catch(() => {});
    },
    freeze(params){
      this.form.id=params.id
      this.form.businessId=params.businessId
      this.form.userId=params.userId
      this.form.orderVersion=params.orderVersion
      let form=JSON.parse(JSON.stringify(this.form))
      form.state=16
      form.messageType=16
      this.$confirm('确认冻结订单操作吗?', '危险操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        orderModifyAPI(form).then(res=>{
          if (res.code === 200) {
            this.popUp(res);
            this.init()
          }
        });
      }).catch(() => {});
    },
    refund(params){
      this.form.id=params.id
      this.form.businessId=params.businessId
      this.form.userId=params.userId
      this.form.orderVersion=params.orderVersion
      let form=JSON.parse(JSON.stringify(this.form))
      form.state=constant.payState[6].type;
      form.messageType=constant.payState[6].type;
      this.$confirm('确认将钱款退还吗?', '危险操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        orderModifyAPI(form).then(res=>{
          if (res.code === 200) {
            this.popUp(res);
            this.init()
          }
        });
      }).catch(() => {});
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
      this.$refs.saveOrUpdateUser.dialogUser=true
    },
  },
  filters: {

  },
  beforeDestroy() {
    this.$bus.$off('refreshLayout')
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  //float: right;
  .header-d-input{
    width: 300px;
    float: right;
    margin-bottom: 10px;
  }
}
.m-card{
  padding: 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04),0 -1px 0px rgba(0, 0, 0, .05);
  margin-top: 10px;
}
</style>
