<template>
  <Trading/>
</template>
<script>
import Trading from "@/views/trading/Trading.vue";
export default {
  name: "OrderTrading",
  components:{Trading},
}
</script>
