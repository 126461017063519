<template>
  <div class="message-p-f">
    <span class="time-p" :class="userType===1?'time-user':''">{{time}}</span>
    <div class="content-img-p">
      <img class="img-user"
           :class="userType===1?'user-message':''"
           :src="userType===0 ? busImg:consImg" alt="">
      <p class="message-p"
         :class="userType===1?'user-message':''">
        {{message}}
      </p>
    </div>
  </div>
</template>

<script>
import busImg from "@/assets/userinfo/serviceUser.svg"
import consImg from "@/assets/userinfo/img.png"
export default {
  name: "Message",
  components: {},
  props:{
    userType:{
      type:Number,
      default:1 //0:客服 1:用户
    },
    time:{
      type:String,
      default:''
    },
    message:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      busImg,
      consImg,
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){
    },
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/conmmon-style.scss";
.content-img-p{
  display: flex;
}
.img-user{
  height: 40px;
  border: solid 1px #c2c4ca;
  padding: 3px;
  border-radius: 5px;
}
.message-p{
  margin-left: 10px;
  padding: $common-padding;
  border-radius: $common-padding;
  line-height: 30px;
  border: 1.5px solid #c2c4ca;
  min-width: 170px;
}
.user-message{
  border: 1.5px solid #cad7f1;
}
.time-user{
  color: #abc0f9 !important;
}
.message-p-f{
  .time-p{
    position: relative;
    left: 70px;
    top: 8px;
    background-color: white;
    padding: 0 8px;
    color: #919494;
    font-size: 12px;
  }
}
</style>
