<template>
  <div>
    <div v-if="$terminal">
      <div class="header-d">
        <el-select v-model="form.fundType"
                   clearable
                   @change="init"
                   placeholder="资金类别">
          <el-option
              v-for="item in fundStreamDirectionList"
              v-if="item.auth!==undefined && item.auth.includes(roleName)"
              :label="item.name"
              :value="item.type">
          </el-option>
        </el-select>
        <el-date-picker
            class="common-left-space"
            v-model="timeArr"
            type="daterange"
            align="left"
            @change="init"
            format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-button style="float:right;"
                   @click="form.account='';form.userId='';form.fundType='';init()"
                   type="info">
          <i class="el-icon-refresh"></i>
          重置搜索
        </el-button>
        <el-input
            v-if="false"
            @keyup.enter.native="init"
            clearable
            class="header-d-input"
            v-model.trim="form.account"
            placeholder="">
          <el-button @click="init"
                     slot="append"
                     icon="el-icon-search">
          </el-button>
        </el-input>
      </div>
      <div class="flex-around">
        <ConsumerUser :show-toggle="true" v-if="$checkRole('管理员')" @refresh="init" style="width: 10%;height: fit-content"/>
        <div :style="roleName==='管理员'?{width: '89%'}:{width: '100%'}">
          <el-table
              :key="new Date().getTime()"
              ref="multipleTable"
              :header-row-style="{'color':'gray'}"
              border
              lazy
              :data="tableData.list"
              style="width: 100%;margin-top: 10px"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              @sort-change="sortChange"
              @expand-change="handleExpandChange"
              :row-class-name="tableRowClassName">
            <el-table-column v-if="false"
                             type="selection"
                             width="55">
            </el-table-column>
            <template v-for="(item,index) in columnList">
              <el-table-column :align="item.align"
                               v-if="!item.template && $checkRole(item.auth)"
                               :show-overflow-tooltip="true"
                               :width="item.width"
                               :prop="item.prop"
                               :label="item.label">
              </el-table-column>
              <el-table-column :align="item.align"
                               v-else-if="item.template && $checkRole(item.auth)"
                               :show-overflow-tooltip="false"
                               :width="item.width"
                               :prop="item.prop"
                               :label="item.label">
                <template slot-scope="scope">
                  <template v-if="item.params==='control'">
                    <el-link class="common-left-space" type="info" size="mini" @click="info(scope.row)">{{$t('table.see')}}</el-link>
                  </template>
                  <template v-else-if="item.params==='payState'">
                    {{scope.row.payState|payStateTransfer('流水统计')}}
                  </template>
                  <template v-else-if="item.params==='payType'">
                    {{scope.row.payType|payTypeTransfer()}}
                  </template>
                  <template v-else-if="item.params==='fundType'">
                    <el-tag size="mini">{{scope.row.fundType|dictTransfer(fundStreamDirectionList)}}</el-tag>
                  </template>
                  <template v-else-if="item.params==='orderId'">
                    <Copy :text="scope.row.orderId"/>
                  </template>
                  <template v-else-if="item.params==='amount'">
                    {{Number(scope.row.amount).toFixed(2)}}
                  </template>
                  <template v-else-if="item.params==='balanceBefore'">
                    {{Number(scope.row.balanceBefore).toFixed(2)}}
                  </template>
                  <template v-else-if="item.params==='balanceAfter'">
                    {{Number(scope.row.balanceAfter).toFixed(2)}}
                  </template>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <paging
              :page-sizes="[10,20,25,30,40]"
              :update:page="form.pageNum"
              :update:limit="form.pageSize"
              :total="tableData.total"
              @changeSize="changeSize"
              @pagination="pagination"/>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="h5-content">
        <div style="display: flex;justify-content: space-between">
          <DateRange v-model="timeArr"/>
          <el-select v-model="form.fundType"
                     clearable
                     size="mini"
                     style="width: 100%;"
                     @change="init"
                     placeholder="资金类别">
            <el-option
                v-for="item in fundStreamDirectionList"
                v-if="item.auth.includes(roleName)"
                :key="item.type"
                :label="item.name"
                :value="item.type">
            </el-option>
          </el-select>
        </div>
        <div class="h5-row-item-right" style="margin-top: 5px">
          <el-button
                     size="mini"
                     @click="form.account='';form.userId='';form.fundType='';init()"
                     type="info">
            <i class="el-icon-refresh"></i>
            重置搜索
          </el-button>
        </div>
        <el-input
            v-if="false"
            @keyup.enter.native="init"
            clearable
            class="header-d-input"
            v-model.trim="form.account"
            placeholder="">
          <el-button @click="init"
                     slot="append"
                     icon="el-icon-search">
          </el-button>
        </el-input>
      </div>
      <div class="h5-content"  v-if="$checkRole('管理员')">
        <ConsumerUser :show-toggle="true" @refresh="init"/>
      </div>
      <div class="h5-content" v-for="(item,index) in tableData.list">
        <h5-row v-if="item.orderId!==undefined" :label="'订单号'" :value="item.orderId"/>
        <h5-row v-if="item.orderId!==undefined" :label="'支付方式'" :value="item.payType">
          <template slot="value">
            {{item.payType|payTypeTransfer()}}
          </template>
        </h5-row>
        <h5-row :label="'资金类别'" :value="item.fundType">
          <template slot="value">
            <el-tag size="mini">{{item.fundType|dictTransfer(fundStreamDirectionList)}}</el-tag>
          </template>
        </h5-row>
        <h5-row :label="'金额'" :value="Number(item.amount).toFixed(2)"/>
        <h5-row :label="'支付状态'" :value="item.payState">
          <template slot="value">
            {{item.payState|payStateTransfer('流水统计')}}
          </template>
        </h5-row>
        <h5-row :label="'交易后余额'" :value="Number(item.balanceAfter).toFixed(2)"/>
        <h5-row :label="'交易前余额'" :value="Number(item.balanceBefore).toFixed(2)"/>
        <h5-row :label="'创建时间'" :value="item.createTime"/>
        <h5-row v-if="false" :label="'操作人昵称'" :value="item.operateNickName"/>
      </div>
      <el-empty v-if="tableData.list===undefined||tableData.list.length===undefined||tableData.list.length===0" class="h5-content"/>
      <paging
          class="h5-content"
          v-if="tableData.total!==0"
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :component-size="true"
          :layout="'total,prev, pager,next'"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
  </div>
</template>

<script>
import paging from "@/components/Paging";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import {walletPageAPI} from "@/api/wallet";
import common from "@/mixin/common";
import Copy from "@/components/Copy.vue";
import ConsumerUser from "@/components/ConsumerUser.vue";
import DateRange from "@/components/DateRange.vue";
import H5Row from "@/components/h5-row.vue";
export default {
  name: "WalletRecord",
  mixins:[dictTransfer, common],
  components:{H5Row, DateRange, ConsumerUser, Copy, paging},
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        {align:"center",label:"订单号",prop:"orderId",width:100,template:true,params:'orderId',auth:constant.authAll},
        {align:"center",label:"昵称",prop:"nickName",width:'auto',template:false,params:'',auth:constant.authAll},
        {align:"center",label:"资金类别",prop:"fundType",width:120,template:true,params:'fundType',auth:constant.authAll},
        {align:"center",label:"状态",prop:"payState",width:100,template:true,params:'payState',auth:constant.authAll},
        {align:"center",label:"支付方式",prop:"payType",width:100,template:true,params:'payType',auth:constant.authAll},
        {align:"center",label:"金额",prop:"amount",width:'auto',template:true,params:'amount',auth:constant.authAll},
        {align:"center",label:"交易前余额",prop:"balanceBefore",width:'auto',template:true,params:'balanceBefore',auth:constant.authAll},
        {align:"center",label:"交易后余额",prop:"balanceAfter",width:'auto',template:true,params:'balanceAfter',auth:constant.authAll},
        {align:"center",label:"创建时间",prop:"createTime",width:'auto',template:false,params:'',auth:constant.authAll},
        {align:"center",label:"操作人昵称",prop:"operateNickName",width:'auto',template:false,params:'',auth:constant.authAdmin},
        //{align:"center",label:"操作",prop:"answer",width:80,template:true,params:'control'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        fundType:'',
        startTime:'',
        endTime:'',
        userId: '',
      },
      ids:[],
      options:constant.payState,
      fundStreamDirectionList:constant.fundStreamDirection,
      topUpStateList:constant.topUpState,
      payStateList:constant.payState,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timeArr:[],
      roleName:constant.userRoleName
    }
  },
  mounted() {
    this.init()
    this.$bus.$on('refreshLayout',()=>{
      this.init()
    })
  },
  watch: {},
  methods: {
    init(params){
      if (params !== undefined) {
        this.form.userId=params.id
      }
      if (this.timeArr.length > 0) {
        if (this.$terminal) {
          this.form.startTime=this.formatDate(this.timeArr[0],'start');
          this.form.endTime=this.formatDate(this.timeArr[1],'end');
        } else {
          this.form.startTime=this.timeArr[0];
          this.form.endTime=this.timeArr[1];
        }
      }
      if (!this.$checkRole('管理员')) {
        this.form.userId=this.loginInfo.id
      }
      walletPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
        }
      });
    },
    info(params){
      this.$router.push({path:'/orderInfo',query:{id:params.orderId}})
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
  },
  filters: {
    rewardState(state,direction){
      if (state.fundType === 10) {
        direction=constant.topUpState
      } else {
        direction=constant.payState
      }
      let str;
      for (let payTypeElement of direction) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          break
        }
      }
      return str
    },
  },
  beforeDestroy() {
    this.$bus.$off('refreshLayout')
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.header-d{
  width: 100%;
  .header-d-input{
    width: 300px;
    float: right;
  }
}
</style>
