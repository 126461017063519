<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <div style="display: flex;width: 100%">
        <span class="m-title">市场码商共有{{tableData.total}}家</span>
        <el-button size="small"
                   type="primary"
                   @click="init"
                   style="margin-left: auto;">
          <i class="el-icon-refresh" />
          刷新列表
        </el-button>
      </div>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template"
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-button type="primary" size="mini" @click="shopping(scope.row)">{{$t('table.shoppingBtn')}}</el-button>
              </template>
              <template v-else-if="item.params==='payType'">
                <img v-for="img in payTypeList"
                     style="width: 30px"
                     v-if="img.type===scope.row.payType"
                     :src="img.icon" alt="">
              </template>
              <template v-else-if="item.params==='usdtBalance'">
                {{Number(scope.row.usdtBalance).toFixed(2)}}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
    <div v-else>
      <div class="m-title h5-content">市场码商共有{{tableData.total}}家</div>
      <div class="h5-content">
        <table class="m-table">
          <thead class="m-table-title-border">
          <tr>
            <th class="m-table-title">昵称</th>
            <th class="m-table-title">支付方式</th>
            <th class="m-table-title">余额</th>
            <th v-if="false" class="m-table-title">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr class="m-tr" v-for="(item,index) in tableData.list">
            <td class="m-th-td">{{item.nickName}}</td>
            <td class="m-th-td">
              <img width="22" :src="item.payType|payTypeTransfer()" alt=""/>
            </td>
            <td class="m-th-td">{{Number(item.usdtBalance).toFixed(2)}}</td>
            <td v-if="false" class="m-th-td">
              <el-button type="primary"
                         @click="shopping(item)"
                         size="mini">
                {{$t('table.shoppingBtn')}}
              </el-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <paging
          class="h5-content"
          :component-size="true"
          :background="false"
          :layout="'total,prev, pager,next'"
          :page-size-show="false"
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
  </div>
</template>

<script>
import Paging from "@/components/Paging";
import constant from "@/constant";
import common from "@/mixin/common";
import {businessPageAPI} from "@/api/user";
import {orderSaveAPI} from "@/api/order";
export default {
  name: "Marketplace",
  mixins:[common,constant],
  components:{Paging},
  data() {
    return {
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        /*{align:"center",label:"码商帐号",prop:"account",width:150,template:false,params:''},*/
        {align:"center",label:"码商名称",prop:"nickName",width:150,template:false,params:''},
        {align:"center",label:"余额",prop:"usdtBalance",width:200,template:true,params:'usdtBalance'},
        {align:"center",label:"支付方式",prop:"payType",width:100,template:true,params:'payType'},
        // {align:"center",label:"上架时间",prop:"createTime",width:180,template:false,params:''},
        // {align:"center",label:"操作",prop:"answer",width:'auto',template:false,params:'control'},
      ],
      mColumnList:[
        {align:"center",label:"码商id",prop:"id",width:70,template:false,params:''},
        {align:"center",label:"码商名称",prop:"id",width:80,template:false,params:''},
        {align:"center",label:"余额",prop:"balance",width:100,template:false,params:''},
        {align:"center",label:"支付方式",prop:"payType",width:70,template:true,params:'payType'},
        {align:"center",label:"上架时间",prop:"createTime",width:180,template:false,params:''},
        {align:"center",label:"操作",prop:"answer",width:'auto',template:true,params:'control'},
      ],
      form:{
        pageNum:1,
        pageSize:10,
        orderType:'asc'
      },
      ids:[],
      payTypeList:constant.payType
    }
  },
  mounted() {
    this.init()
    this.$bus.$on('refreshLayout',()=>{
      this.init()
    })
  },
  computed: {},
  watch: {},
  methods: {
    init(){
      this.form.roleId=2
      this.form.userState=1
      businessPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=res.data.records
          this.tableData.total=res.data.total
        }
      })
    },
    shopping(item){
      const form={
        businessId:item.id,
        payType:item.payType,
        state:constant.payState[8].type,
      }
      orderSaveAPI(form).then(res=>{
        this.popUp(res)
        if (res.code === 200) {
          this.goPage(`/trading`)
        }
      })
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){

    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
  },
  filters: {
    payTypeTransfer(value,direction){
      let str
      for (let payTypeElement of constant.payType) {
        if (payTypeElement.type === value) {
          str=payTypeElement.icon
          break
        }
      }
      return str
    }
  },
  beforeDestroy() {
    this.$bus.$off('refreshLayout')
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
</style>
