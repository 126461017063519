import Admin from "@/views/admin/Admin.vue";

const t = {
    login: {
        account: '邮箱帐号',
        email: '邮箱',
        login: '登录',
        register: '注册',
        cancel: '取消',
        nickName: '昵称',
        password: '密码',
        code: '验证码',
        registerCode: '邀请码(选填)',
        forgotPassword:'忘记密码',
        otpNum:'谷歌验证码',
        return:'返回',
    },
    router: {
        history: '历史',
        userinfo: '用户中心',
        marketplace: '市场列表',
        trading:'交易',
        login: '登录',
        register: '注册',
        admin:'管理',
        adminUser:'用户管理',
        platformUser:'平台用户',
        adminBusiness:'码商管理',
        adminSetting:'设置',
        businessList:'订单管理',
        notFound: '页面走丢了',
        orderInfo: '订单详细信息',


        adminIndex: '首页',
        admin_Business: '码商管理',
        setting: '设置',
        flowStatistics: '钱包记录',
        flowStatisticsAdmin: '账变记录',
        orderOther: '历史订单',
        orderTrading: '正在交易',
        orderTradingB: '接单大厅',
        payType: '通道管理',
        payTypeB: '收款账户',
        platformMember: '对接平台',
        settle: '结算管理',
        teamLeader: '码商管理',
        teamMember: '码商成员',
        adminTopUp: '充值转账',
        collAccount: '收款帐号',
        teamTable: '团队报表',
        TeamList:'码商列表',
        TeamInfo:'码商团队',
        orderList:'订单列表',
        StatisticsCons:'商户统计',
        StatisticsMS:'码商统计',
        loginUser:'在线统计',
    },
    table:{
        shoppingBtn:'购买',
        modify: '修改',
        deleted:'删除',
        see:'详情',
        info:'详情',
        seeBusiness:'交易记录',
        approveOk:'通过注册',
        shield: '拉黑',
        goPay:'去支付',
        payed:'已支付',
        cancelPay:'取消订单',
        closeOrder:'确认收款',
        returnMoney:'退回订金',
        sort: '修改权重',
        resetPwd:'重置密码',
        lockOrder:'锁定订单',
        replenishment:'补单',
        modifyAmount:'修改金额',
        applyPass:'通过审核',
        applyRefund:'退款',
        cancelRefund:'撤回申请',
        resetSearch:'刷 新',
        modifyPayInfo:'修改支付信息',
        rollBack:'撤回操作',
    },
    userInfo:{

    },
}
t.add = '新增'
t.delete = '删除'
export default t
