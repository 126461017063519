import clipboard from 'clipboard';
export default {
  data(){
    return{
        loginInfo:JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  methods:{
    goPage(path){
      this.$router.push({path:path})
    },
    orderState(type,row,roleName){
      let state=false
      let stateArr=''
      switch (type) {
        case '已支付':
          //手动获取,自动获取已支付 支付按钮隐藏
          state=row.state===0
          break
        case '提醒卖家':
          state=row.state===2||row.state===20
          break
        case '确认收款':
          state=row.state===2||row.state===0||row.state===20
          break
        case '补单':
          state=row.state===7||row.state===21
          break
        case '修改订单金额':
          state=row.state===0||row.state===2||row.state===13||row.state===20
          break
        case '审核订单金额':
          state=row.state===13
          break
        case '取消订单':
          state=row.state===0||row.state===2||row.state===13||row.state===20
          break
        case '退款':
          //state=row.state===-1||row.state===11
          state=row.state===-100
          break
        case '冻结':
          // state=row.state===7
          state=row.state===700
          break
        case '修改支付信息':
          state=row.state===0||row.state===2||row.state===13||row.state===20
          break
        case '取消冻结':
          // state=row.state===16||row.state===20
          state=row.state===160||row.state===200
          break;
        case '解冻并补单':
          //state=row.state===16
          break;
        case '状态回退':
          state=row.state===-1||row.state===11
          break;
      }
      return state && this.$checkRole(roleName) && row.settle===0
    },
    copyText(text){
      clipboard.copy(text)
      this.$message({
        message: '复制成功',
        type: 'success',
        showClose:true
      })
    },
    ok(message){
      this.$message.success({message:message,showClose:true,duration:500})
    },
    error(message){
      this.$message.error({message:message,showClose:true,duration:1000})
    },
    resOk(res){
      if (res.code === 200) {
        this.$message.success({message:res.msg,showClose:true,duration:500})
      } else {
        return
      }
    },
    popUp(res){
      if (res.code === 200) {
        this.$message.success({message:res.msg,showClose:true,duration:500})
      } else if (res.code >= 400) {
        this.error(res.msg)
      }
    },
    popUpError(res){
      if (res.code === 500) {
        this.$message.error({message:res.msg,showClose:true})
      }
    },
    formatDate(date,time) {
      if (date !== undefined&&date !=='') {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        let hours = date.getHours().toString().padStart(2, '0');
        let minutes = date.getMinutes().toString().padStart(2, '0');
        let seconds = date.getSeconds().toString().padStart(2, '0');
        if (time === 'start') {
          hours=minutes=seconds='00'
        } else if (time === 'end') {
          hours='23'
          minutes=seconds='59'
        }
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    },

  }
}
