import request from "@/utils/request";

/**
 * 订单相关
 * @type {string}
 */
const modulePrefix = 'order'

/**
 * 分页
 */
export function orderPageAPI(data) {
    return request({
        url: modulePrefix,
        method: 'get',
        params: data
    })
}

/**
 * 详情
 * @param id
 * @returns {*}
 */
export function orderInfoAPI(id) {
    return request({
        url: modulePrefix+'/'+id,
        method: 'get'
    })
}

/**
 * 保存
 */
export function orderSaveAPI(data) {
    return request({
        url: modulePrefix,
        method: 'post',
        data: data
    })
}

/**
 * 修改
 */
export function orderModifyAPI(data) {
    return request({
        url: modulePrefix,
        method: 'put',
        data: data
    })
}

/**
 * 删除
 */
export function orderDeleteAPI(idListStr) {
    return request({
        url: modulePrefix+'/batch?id='+idListStr,
        method: 'delete'
    })
}

export function orderLimitAPI() {
    return request({
        url: modulePrefix+'/limit',
        method: 'get'
    })
}

export function orderFileAPI() {
    return request({
        url: '/file?a=1',
        method: 'get'
    })
}

/**
 * 订单列表结算
 */
export function settlePageAPI(params) {
    return request({
        url: '/settle',
        method: 'get',
        params
    })
}

/**
 * 开始结算
 */
export function settleSaveAPI(data) {
    return request({
        url: '/settle',
        method: 'post',
        data
    })
}

/**
 * 取消结算
 */
export function settleModifyAPI(data) {
    return request({
        url: '/settle',
        method: 'put',
        data
    })
}

/**
 * 经营信息
 * @param params
 * @returns {*}
 */
export function manageInfoAPI(params) {
    return request({
        url: '/order/manage',
        method: 'get',
        params
    })
}

/**
 * 正在交易总额 冻结信息
 * @returns {*}
 */
export function tradingInfoAPI() {
    return request({
        url: '/order/underway',
        method: 'get',
    })
}
