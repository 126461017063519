<template>
  <div style="text-align: center;height: 100vh;display: flex;align-items: center;justify-content: space-around;">
    <div>
      <h1 style="font-size: 30px">您访问的页面不存在, 将在3秒后自动跳转到登录页</h1>
      <span @click="$router.push({path:'/'})" class="el-link el-link--primary">立即前往</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  mounted() {
    setTimeout(() => {
      this.$router.push({path:'/'})
    }, 3000)
  }
}
</script>

<style scoped>

</style>
