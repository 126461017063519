<template>
  <div >
    <div v-if="$terminal" class="content-main-padding">
      <div>
        <el-date-picker
            v-model="timeArr"
            type="daterange"
            align="left"
            @change="init"
            format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-button type="primary" @click="init" class="common-left-space"><i class="el-icon-search"/>刷新 </el-button>
        <el-input
            @keyup.enter.native="init"
            clearable
            style="width: 350px;float:right;"
            v-model.trim="form.account"
            placeholder="支持帐号、昵称、姓名模糊搜索">
          <el-button @click="init"
                     slot="append"
                     icon="el-icon-search">
          </el-button>
        </el-input>
      </div>
      <div v-if="false" class="card-list">
        <el-card class="card-width"
                 @click=""
                 shadow="hover"
                 v-for="(item, index) in tableData.records">
          <el-descriptions :title="item.nickName"
                           :column="1"
                           size="small" border>
            <template slot="extra">
              <el-link @click="" size="mini">
                <i class="el-icon-question"/>
                详情
              </el-link>
            </template>
            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="团长订单总金额">{{item.orderAmountSelf}}</el-descriptions-item>
            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="团长下级订单总金额">{{item.orderAmountJunior}}</el-descriptions-item>
<!--            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="团长订单利润">{{item.orderAmountProfile}}</el-descriptions-item>-->
<!--            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="团长下级订单利润">{{item.orderAmountProfileJunior}}</el-descriptions-item>-->
            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="团长佣金">{{item.brokerage}}</el-descriptions-item>
            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="团长订单数">{{item.orderCountSelf}}</el-descriptions-item>
            <el-descriptions-item :label-style="{ width: '140px',color:'#303133' }" label="团长下级订单数">{{item.orderCountJunior}}</el-descriptions-item>
          </el-descriptions>
        </el-card>
      </div>
      <el-table
          v-else
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.records"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         v-if="false"
                         width="55">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template"
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link type="primary" size="mini" @click="settingBrokerage(scope.row)">最大佣金</el-link>
              </template>
              <template v-else-if="item.params==='payState'">
                {{scope.row.payState|payStateTransfer()}}
              </template>
              <template v-else-if="item.params==='payType'">
                {{scope.row.payType|payTypeTransfer()}}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
      <el-dialog
          title="设置团长抽取下级最大佣金"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <el-form :model="dataForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="120px"
                 class="demo-ruleForm">
          <el-form-item label="支付宝最大佣金" prop="juniorRateAliMax">
            <el-input-number :class="{'apply-shake': valid.juniorRateAliMax}"
                             placeholder="请输入支付宝最大佣金比例"
                             clearable
                             :step="0.001"
                             :max="1"
                             :min="0"
                             style="width: 100%"
                             v-model.trim="dataForm.juniorRateAliMax"></el-input-number>
          </el-form-item>
          <el-form-item label="银行卡最大佣金" prop="juniorRateBankMax">
            <el-input-number :class="{'apply-shake': valid.juniorRateBankMax}"
                             :step="0.001"
                             :min="0"
                             :max="1"
                             placeholder="请输入银行卡最大佣金比例"
                             style="width: 100%"
                             v-model.trim="dataForm.juniorRateBankMax"></el-input-number>
          </el-form-item>
          <el-form-item v-if="false" label="当前支付宝佣金" prop="juniorRateAli">
            <el-input-number placeholder="当前支付宝佣金"
                             clearable
                             :step="0.001"
                             :max="1"
                             :min="0"
                             style="width: 100%"
                             v-model.trim="dataForm.juniorRateAli"></el-input-number>
          </el-form-item>
          <el-form-item v-if="false" label="当前银行卡佣金" prop="juniorRateBank">
            <el-input-number :step="0.001"
                             :min="0"
                             :max="1"
                             placeholder="当前银行卡佣金"
                             style="width: 100%"
                             v-model.trim="dataForm.juniorRateBank"></el-input-number>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="handleClose">取  消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确  定</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-else>

    </div>
  </div>
</template>
<script>
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import {leaderPageAPI, userModifyAPI} from "@/api/user";
import paging from "@/components/Paging.vue";
let that=''
const checkAli =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.toAccount=true
    setTimeout(()=>{
      if (that) {
        that.valid.juniorRateAliMax=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
const checkBank =  (rule, value, callback) =>{
  if (value === undefined || value === '') {
    that.valid.toAccount=true
    setTimeout(()=>{
      if (that) {
        that.valid.juniorRateBankMax=false;
      }
    },820)
    callback(new Error('不可为空'))
  } else {
    callback()
  }
}
export default {
  name: "TeamLeader",
  components: {paging},
  mixins: [common, dictTransfer, constant],
  data() {
    return {
      form: {
        pageSize: 10,
        pageNum: 1,
        account: '',
        startTime: '',
        endTime: '',
      },
      tableData: {
        total: 0,
        records: []
      },
      columnList: [
        {align:"center",label:"团长昵称",prop:"nickName",width:150,template:false,params:''},
        {align:"center",label:"团长订单数",prop:"orderCountSelf",width:150,template:false,params:''},
        {align:"center",label:"团长订单总金额",prop:"orderAmountSelf",width:150,template:false,params:''},
        {align:"center",label:"下级订单数",prop:"orderCountJunior",width:150,template:false,params:''},
        {align:"center",label:"下级订单总金额",prop:"orderAmountJunior",width:150,template:false,params:''},
        {align:"center",label:"团长佣金",prop:"rmbBalance",width:150,template:false,params:''},
        {align:"center",label:"支付宝最大佣金",prop:"juniorRateAliMax",width:150,template:false,params:''},
        {align:"center",label:"银行卡最大佣金",prop:"juniorRateBankMax",width:150,template:false,params:''},
        {align:"center",label:"操作",prop:"control",width:150,template:true,params:'control'},
      ],
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      ids: [],
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dialogVisible:false,
      dataForm:{
        juniorRateBankMax: 0,
        juniorRateAliMax:0,
        juniorRateBank: 0,
        juniorRateAli:0,
        id: '',
        superiorsId: '',
      },
      rules:{
        juniorRateAliMax:[{validator:checkAli, trigger: "blur"},],
        juniorRateBankMax: [{validator:checkBank, trigger: "blur"},],
      },
      valid:{
        juniorRateBankMax: false,
        juniorRateAliMax:false,
      },
    }
  },
  mounted() {
    this.init()
    that=this
  },
  methods: {
    init() {
      if (this.timeArr) {
        if (this.timeArr.length > 0) {
          this.form.startTime=this.formatDate(this.timeArr[0],'start');
          this.form.endTime=this.formatDate(this.timeArr[1],'end');
        }
      }
      leaderPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData=res.data
        }
      })
    },
    handleClose(){
      this.dialogVisible=false
      this.$refs['ruleForm'].resetFields();
    },
    settingBrokerage(params){
      if (this.form.superiorsId === '') {
        this.error('请先选择上级用户')
        return
      }
      this.dialogVisible=true
      this.dataForm.id=params.id
      this.dataForm.version=params.version
      this.dataForm.juniorRateAliMax=params.juniorRateAliMax
      this.dataForm.juniorRateBankMax=params.juniorRateBankMax
      this.dataForm.superiorsId=this.form.superiorsId
      this.dataForm.version=params.version
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dataForm.roleId=this.loginInfo.roleId
          userModifyAPI(this.dataForm).then(res=>{
            if (res.code === 200) {
              this.ok(res.msg)
              this.init()
              this.dialogVisible=false
            }
          })
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(rows) {
      this.ids = []
      rows.forEach((value, index, array) => {
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order) {
      if (column.order === 'descending') {
        this.form.orderType = ''
      } else {
        this.form.orderType = 'asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded) {
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    selectable(row, index) {
      return row.state === constant.payState[0].type
    },
    pagination(obj) {
      this.form.pageSize = obj.limit
      this.init()
    },
    changeSize(obj) {
      this.form.pageNum = obj.page
      this.init()
    },
  },
  beforeDestroy() {
    that=null
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.card-list{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
  .card-width{
    cursor: pointer;
    width: 250px;
  }
}
::v-deep .el-card__body{
  padding: 10px;
}
::v-deep .el-descriptions-item__cell ::v-deep .el-descriptions-item__label ::v-deep .is-bordered-label{
  width: 50px;
}
::v-deep .el-descriptions__header{
  margin-bottom: 10px;
}

.label-width{
  width: 80px;
}
</style>
