<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.records"
          style="width: 100%;margin-top: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @row-click="rowClick"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         v-if="false"
                         width="55">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           v-if="!item.template"
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           v-else
                           :show-overflow-tooltip="true"
                           :width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <el-link type="primary" size="mini" @click="">移分</el-link>
                <el-link type="primary" size="mini" @click="">移分</el-link>
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import paging from "@/components/Paging.vue";

export default {
  name: "TeamList",
  components: {paging},
  data(){
    return{
      tableData: {
        total: 0,
        records: []
      },
      columnList: [
        {align:"center",label:"码商等级",prop:"nickName",width:150,template:false,params:''},
        {align:"center",label:"子级数量",prop:"orderCountSelf",width:150,template:false,params:''},
        {align:"center",label:"码商帐号",prop:"orderAmountSelf",width:150,template:false,params:''},
        {align:"center",label:"团队余额",prop:"orderCountJunior",width:150,template:false,params:''},
        {align:"center",label:"订单数",prop:"orderAmountJunior",width:150,template:false,params:''},
        {align:"center",label:"冻结金额",prop:"orderCountJunior",width:150,template:false,params:''},
        {align:"center",label:"接单状态",prop:"orderCountJunior",width:150,template:false,params:''},
        {align:"center",label:"禁止接单",prop:"orderCountJunior",width:150,template:false,params:''},
        {align:"center",label:"成交订单数",prop:"rmbBalance",width:150,template:false,params:''},
        {align:"center",label:"成功率",prop:"juniorRateAliMax",width:150,template:false,params:''},
        {align:"center",label:"操作",prop:"control",width:150,template:true,params:'control'},
      ],
      form: {
        pageSize: 10,
        pageNum: 1,
        account: '',
        startTime: '',
        endTime: '',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){

    },

    handleSelectionChange(rows) {
      this.ids = []
      rows.forEach((value, index, array) => {
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order) {
      if (column.order === 'descending') {
        this.form.orderType = ''
      } else {
        this.form.orderType = 'asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded) {
    },
    rowClick(row, column, event){},
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    pagination(obj) {
      this.form.pageSize = obj.limit
      this.init()
    },
    changeSize(obj) {
      this.form.pageNum = obj.page
      this.init()
    },
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
</style>
