<template>
  <div>
    <!--暂时没用-->
    <div class="content-main-padding">
      <div class="flex-around">
        <ConsumerUser style="width: 10%"/>
        <div style="width: 89%">

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConsumerUser from "@/components/ConsumerUser.vue";

export default {
  name: "CollAccount",
  components: {ConsumerUser},
  data(){
    return{
      form:{
        pageNum:1,
        pageSize:10,
        account:''
      },
      tableData:{
        records:[],
        total:0
      }
    }
  },
  mounted(){
  },
  methods:{
    init(){
    }
  },
  beforeDestroy(){
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
</style>
