<template>
  <div>
    <Message
        v-for="(item,index) in history"
        :message="item.message"
        :time="item.time"
        :user-type="item.userType"/>
    <el-input placeholder="请输入您遇到的问题"
              clearable
              @keyup.enter.native="sendMsg"
              class="input-d"
              v-model.trim="form.question">
      <el-button slot="append"
                 title="发送"
                 icon="el-icon-upload2"></el-button>
    </el-input>
  </div>
</template>

<script>
import Message from "@/components/Message";
export default {
  name: "CustomerService",
  components:{Message},
  data() {
    return {
      form:{
        question:'',
        userType:0,
        time:'2024-12-12 12:00:00',
        message:'32'
      },
      history:[],
    }
  },
  mounted() {
    this.init()
  },
  computed: {},
  watch: {},
  methods: {
    init(){
    },
    sendMsg(){
      this.history.push({message:this.form.question,time:this.form.time,userType:1},{message:'已经接受到反馈',time:this.form.time,userType:0})
      this.form.question=''
      setTimeout(()=>{
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      },50)
    },
  },
  filters: {},
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.input-d{
  margin-top: 20px;
}
</style>
