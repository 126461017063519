<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <div>
        <el-radio-group v-model="form.settle" @input="init">
          <el-radio :label="0">未结算</el-radio>
          <el-radio :label="1">已结算</el-radio>
        </el-radio-group>
        <el-date-picker
            class="common-left-space"
            v-model="timeArr"
            type="daterange"
            align="left"
            @change="init"
            format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-button class="common-left-space"
                   @click="settled"
                   v-role="'管理员'"
                   type="primary">开始结算
        </el-button>
        <el-button type="info"
                   class="common-left-space"
                   @click="form.userId='';currentUser=null;init()">
          <i class="el-icon-refresh"></i>
          {{ $t('table.resetSearch') }}
        </el-button>
        <el-input
            @keyup.enter.native="init"
            clearable
            show-word-limit
            style="width: 300px;float:right;"
            v-model.trim="form.orderId"
            placeholder="根据订单号搜索">
          <el-button @click="init" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="flex-around">
        <ConsumerUser v-if="$checkRole('管理员')" @refresh="init" :type="false" style="width: 10%"/>
        <div style="width: 89%;">
          <div class="m-title" style="margin-top: 10px">结算记录</div>
          <el-table
              ref="multipleTable"
              :header-row-style="{'color':'gray'}"
              border
              lazy
              :data="settleTableData.records"
              style="width: 100%;margin-top: 10px;margin-bottom: 10px"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              @sort-change="sortChange"
              @expand-change="handleExpandChange"
              :row-class-name="tableRowClassName">
            <el-table-column type="selection"
                             v-if="true"
                             :selectable="selectable"
                             align="center"
                             width="40">
            </el-table-column>
            <template v-for="(item,index) in settleColumnList">
              <el-table-column :align="item.align"
                               :fixed="item.fixed"
                               v-if="(!item.template) && $checkRole(item.auth)"
                               :show-overflow-tooltip="item.detail"
                               :min-width="item.width"
                               :prop="item.prop"
                               :key="index"
                               :label="item.label">
              </el-table-column>
              <el-table-column :align="item.align"
                               :fixed="item.fixed"
                               v-else-if="$checkRole(item.auth)"
                               :show-overflow-tooltip="item.detail"
                               :min-width="item.width"
                               :prop="item.prop"
                               :fit="true"
                               :key="index"
                               :label="item.label">
                <template slot-scope="scope">
                  <template v-if="item.params==='control'">
                    <el-link v-role="'管理员,客服'" v-if="scope.row.state===1" type="success" @click="controlSettle(scope.row,2)">结算完成</el-link>
                    <el-link v-if="false" type="danger" class="common-left-space" @click="controlSettle(scope.row,3)">取消结算
                    </el-link>
                  </template>
                  <template v-else-if="item.params==='payState'">
                    <el-tag size="mini">{{ scope.row.state|payStateTransfer() }}</el-tag>
                  </template>
                  <template v-else-if="item.params==='payType'">
                    {{ scope.row.payType|payTypeTransfer() }}
                  </template>
                  <template v-else-if="item.params==='bCollAccount'">
                    <Copy :text="scope.row.bCollAccount"/>
                  </template>
                  <template v-else-if="item.params==='id'">
                    <Copy :text="scope.row.id"/>
                  </template>
                  <template v-else-if="item.params==='state'">
                    {{scope.row.state|settleTransfer()}}
                  </template>

                  <template v-else-if="item.params==='settleAmount'">
                    {{scope.row.settleCompute?.settleCNY.toFixed(2)||'0.00'}}
                  </template>
                  <template v-else-if="item.params==='settleUSDT'">
                    {{scope.row.settleCompute?.settleUSDT.toFixed(2)||'0.00'}}
                  </template>
                  <template v-else-if="item.params==='profit'">
                    {{scope.row.profit.toFixed(2)||'0.00'}}
                  </template>

                  <template v-else-if="item.params==='upRate'">
                    {{scope.row.realtimeRate?.toFixed(2)||'0.00'}}
                  </template>
                  <template v-else-if="item.params==='realtimeRate'">
                    {{scope.row.settleCompute?.realTimeRate?.toFixed(2)||'0.00'}}
                  </template>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <paging
              :page-sizes="[10,20,25,30,40]"
              :update:page="page.settleNum"
              :update:limit="page.settleSize"
              :total="settleTableData.total"
              @changeSize="changeSizeSettle"
              @pagination="paginationSettle"/>
          <div v-if="$checkRole('管理员')" class="m-title">结算订单列表</div>
          <el-table
              v-role="'管理员'"
              ref="multipleTable"
              :header-row-style="{'color':'gray'}"
              border
              lazy
              :data="tableData.records"
              style="width: 100%;margin-top: 10px;margin-bottom: 10px"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              @sort-change="sortChange"
              @expand-change="handleExpandChange"
              :row-class-name="tableRowClassName">
            <el-table-column type="selection"
                             v-if="true"
                             :selectable="selectable"
                             align="center"
                             width="40">
            </el-table-column>
            <template v-for="(item,index) in columnList">
              <el-table-column :align="item.align"
                               :fixed="item.fixed"
                               v-if="(!item.template) && $checkRole(item.auth)"
                               :show-overflow-tooltip="item.detail"
                               :min-width="item.width"
                               :prop="item.prop"
                               :key="index"
                               :label="item.label">
              </el-table-column>
              <el-table-column :align="item.align"
                               :fixed="item.fixed"
                               v-else-if="$checkRole(item.auth)"
                               :show-overflow-tooltip="item.detail"
                               :min-width="item.width"
                               :prop="item.prop"
                               :fit="true"
                               :key="index"
                               :label="item.label">
                <template slot-scope="scope">
                  <template v-if="item.params==='control'">
                  </template>
                  <template v-else-if="item.params==='payState'">
                    <el-tag size="mini">{{ scope.row.state|payStateTransfer() }}</el-tag>
                  </template>
                  <template v-else-if="item.params==='payType'">
                    {{ scope.row.payType|payTypeTransfer() }}
                  </template>
                  <template v-else-if="item.params==='bCollAccount'">
                    <Copy :text="scope.row.bCollAccount"/>
                  </template>
                  <template v-else-if="item.params==='id'">
                    <Copy :text="scope.row.id"/>
                  </template>

                </template>
              </el-table-column>
            </template>
          </el-table>
          <paging
              v-role="'管理员'"
              :page-sizes="[10,20,25,30,40]"
              :update:page="page.orderNum"
              :update:limit="page.orderSize"
              :total="tableData.total"
              @changeSize="changeSizeOrder"
              @pagination="paginationOrder"/>
        </div>
      </div>
    </div>
    <div v-else>
      <el-tabs v-if="false" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane style="margin-top: 0" label="未结算" name="first"></el-tab-pane>
        <el-tab-pane style="margin-top: 0" label="已结算" name="second"></el-tab-pane>
      </el-tabs>
      <div v-if="false" class="h5-content">
        <div style="display: flex">
          <date-range v-model="timeArr"/>
          <el-input size="mini" v-model="form.orderId" placeholder="订单号"></el-input>
        </div>
        <div style="display: flex;margin-top: 5px">
          <el-input size="mini" v-model="form.orderId" placeholder="平台号"></el-input>
          <el-input size="mini" v-model="form.orderId" placeholder="商户号"></el-input>
          <button class="el-button--primary">
            <i class="el-icon-search"/>
          </button>
        </div>
      </div>
      <div class="h5-content">
        <el-radio-group v-model="form.settle" @input="init">
          <el-radio :label="0">未结算</el-radio>
          <el-radio :label="1">已结算</el-radio>
        </el-radio-group>
      </div>
      <div class="h5-content" v-for="item in settleTableData.records">
        <h5-row label="订单号" :value="item.id"></h5-row>
        <h5-row label="状态">
          <template slot="value">
            {{item.state|settleTransfer()}}
          </template>
        </h5-row>
        <h5-row label="结算单数" :value="item.orderCount"></h5-row>
        <h5-row label="总金额" :value="item.amount"></h5-row>
        <h5-row label="费率" :value="item.rate"></h5-row>
        <h5-row label="应结算金额" :value="item.settleCompute?.settleCNY.toFixed(2)||'0.00'"></h5-row>
        <h5-row label="实时汇率" :value="item.settleCompute?.realTimeRate?.toFixed(2)||'0.00'"></h5-row>
        <h5-row label="上浮汇率" :value="item.realtimeRate?.toFixed(2)||'0.00'"></h5-row>
        <h5-row label="应结算USDT" :value="item.settleCompute?.settleUSDT.toFixed(2)||'0.00'"></h5-row>
        <h5-row label="结算时间" :value="item.createTime"></h5-row>
        <h5-row label="修改时间" :value="item.updateTime"></h5-row>
      </div>
      <el-empty class="h5-content" v-if="settleTableData?.length === 0"
                :description="`暂时没有结算`"></el-empty>
      <paging
          v-if="tableData.total !== 0"
          class="h5-content"
          :layout="'total,prev, pager,next'"
          :component-size="true"
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
  </div>
</template>
<script>
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import Countdown from "@/components/Countdown.vue";
import Copy from "@/components/Copy.vue";
import OrderControl from "@/components/OrderControl.vue";
import ConsumerUser from "@/components/ConsumerUser.vue";
import {orderPageAPI, settleModifyAPI, settlePageAPI, settleSaveAPI} from "@/api/order";
import paging from "@/components/Paging.vue";
import DateRange from "@/components/DateRange.vue";
import H5Row from "@/components/h5-row.vue";

export default {
  name: "Settle",
  components: {H5Row, DateRange, paging, ConsumerUser, OrderControl, Copy, Countdown},
  mixins: [common, dictTransfer, constant],
  data() {
    return {
      form: {
        pageSize: 10,
        pageNum: 1,
        userId: '',
        orderId: '',
        settle: 0,
        id: '',
      },
      page: {
        settleSize: 10,
        settleNum: 1,
        orderSize: 10,
        orderNum: 1,
      },
      tableData: {
        total: 0,
        records: []
      },
      settleTableData: {
        total: 0,
        records: []
      },
      columnList: [
        {align: "center", label: "订单号", prop: "id", width: 110, template: true, params: 'id', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "平台昵称", prop: "cNickName", width: '100', template: false, params: 'account', show: true, auth: constant.authConsumer, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "商户昵称", prop: "bNickName", width: '100', template: false, params: 'account', show: true, auth: constant.authConsumer, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "支付方式", prop: "payType", width: 100, template: true, params: 'payType', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "支付状态", prop: "payState", width: 110, template: true, params: 'payState', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "交易金额", prop: "amount", width: 100, template: false, params: '', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "支付人姓名", prop: "cPayName", width: 'auto', template: false, params: 'account', show: true, auth: constant.authBusiness, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "支付帐号", prop: "cPayAccount", width: '100', template: false, params: 'address', show: true, auth: constant.authAdmin, fixed: '', autoWidth: false, detail: true,},
        //align:"center",label:"操作",prop:"answer",width:'100',template:true,params:'control',show:true,auth:constant.authAll,fixed:'right',autoWidth:false,detail:false,},
      ],
      settleColumnList: [
        {align: "center", label: "订单号", prop: "id", width: 110, template: true, params: 'id', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "状态", prop: "cNickName", width: '80', template: true, params: 'state', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "平台昵称", prop: "cNickName", width: 110, template: false, params: 'id', show: true, auth: constant.authAdmin, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "结算单数", prop: "orderCount", width: 90, template: false, params: 'id', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "总金额", prop: "amount", width: 110, template: false, params: 'id', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "费率", prop: "rate", width: 100, template: false, params: '', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "应结算金额", prop: "settleAmount", width: 100, template: true, params: 'settleAmount', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "实时汇率", prop: "realtimeRate", width: 100, template: true, params: 'realtimeRate', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "上浮汇率", prop: "upRate", width: 100, template: true, params: 'upRate', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "应结算USDT", prop: "settleUSDT", width: 100, template: true, params: 'settleUSDT', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "利润", prop: "profit", width: 100, template: true, params: 'profit', show: true, auth: constant.authAdmin, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "结算时间", prop: "createTime", width: 110, template: false, params: 'id', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "修改时间", prop: "updateTime", width: 110, template: false, params: 'id', show: true, auth: constant.authAll, fixed: '', autoWidth: false, detail: true,},
        {align: "center", label: "操作", prop: "answer", width: '100', template: true, params: 'control', show: true, auth: constant.authAll, fixed: 'right', autoWidth: false, detail: false,},
      ],
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      ids: [],
      timeArr: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      currentUser: null,
      activeName: 'first',
    }
  },
  mounted() {
    this.init()
    if (this.$route.query.userId !== undefined) {
      this.form.userId = this.$route.query.userId
      let msg = `确认结算 <${this.$route.query.nickName}> 平台吗, 是否继续?`;
      this.$confirm(msg, '结算提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const temp = this.form.state
        delete this.form.state
        settleSaveAPI(this.form).then(res => {
          if (res.code === 200) {
            this.ok(res.msg)
            this.form.state = temp
            this.init()
          }
        })
      }).catch(() => {});
    }
  },
  methods: {
    init(params) {
      this.form.state = '-1,11';
      //0 未结算 1已结算
      if (params !== undefined && params !== 0 && params !== 1) {
        this.form.userId = params.id;
        this.currentUser = params
      }
      if (this.$terminal && this.timeArr.length>0) {
        this.form.startTime = this.formatDate(this.timeArr[0], 'start');
        this.form.endTime = this.formatDate(this.timeArr[1], 'end');
      } else {
        if (this.timeArr.length > 0) {
          this.form.startTime = this.timeArr[0];
          this.form.endTime = this.timeArr[1]
        }
      }
      let form = JSON.parse(JSON.stringify(this.form))
      form.pageNum = this.page.orderNum
      form.pageSize = this.page.orderSize
      orderPageAPI(form).then(res => {
        if (res.code === 200) {
          if (res.data.page) {
            this.tableData = res.data.page;
          } else {
            this.tableData = res.data;
          }
        }
      })
      this.initSettle()
    },
    initSettle() {
      let form = JSON.parse(JSON.stringify(this.form))
      form.pageNum = this.page.settleNum
      form.pageSize = this.page.settleSize
      settlePageAPI(form).then(res => {
        this.settleTableData.records = []
        this.settleTableData.total = 0
        if (res.code === 200) {
          this.settleTableData = res.data
        }
      })
    },
    controlSettle(params, type) {
      this.$alert('确认完成结算吗?', '完成结算', {
        confirmButtonText: '确定',
        callback: action => {
          let form = {}
          form.id = params.id
          form.userId = params.userId
          form.settle = type
          settleModifyAPI(form).then(res => {
            if (res.code === 200) {
              this.init();
              this.initSettle()
              this.resOk(res.msg)
            }
          })
        }
      });
    },
    handleClick(tab, event) {
      if (tab.paneName === 'first') {
        this.form.settle = 0
        this.init()
      } else if (tab.paneName === 'second') {
        this.form.settle = 1
        this.init()
      }
    },
    settled() {
      if (this.form.userId === '' || this.currentUser.nickName === undefined) {
        this.error('请先选择平台用户')
        return
      }
      let msg = `确认结算 <${this.currentUser.nickName}> 平台吗, 是否继续?`;
      this.$confirm(msg, '结算提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const temp = this.form.state
        delete this.form.state
        settleSaveAPI(this.form).then(res => {
          if (res.code === 200) {
            this.ok(res.msg)
            this.form.state = temp
            this.init()
          }
        })
      }).catch(() => {});
    },
    handleSelectionChange(rows) {
      this.ids = []
      rows.forEach((value, index, array) => {
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order) {
      if (column.order === 'descending') {
        this.form.orderType = ''
      } else {
        this.form.orderType = 'asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded) {
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    selectable(row, index) {
      return row.state === constant.payState[0].type
    },
    pagination(obj) {
      this.form.pageSize = obj.limit
      this.init()
    },
    changeSize(obj) {
      this.form.pageNum = obj.page
      this.init()
    },
    paginationSettle(obj) {
      this.page.settleSize = obj.limit;
      this.initSettle()
    },
    changeSizeSettle(obj) {
      this.page.settleNum = obj.page;
      this.initSettle()
    },

    paginationOrder(obj) {
      this.page.orderSize = obj.limit;
      this.init();
    },
    changeSizeOrder(obj) {
      this.page.orderNum = obj.page;
      this.init();
    },
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
</style>
