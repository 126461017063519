<template>
  <div id="app" :class="$terminal?'super-back-div':''">
    <RouterView/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      currentWidth: window.innerWidth,
    };
  },
  mounted() {
    //window.addEventListener('resize', this.handleResize);
    // 使用防抖函数包装 handleResize 方法
    window.addEventListener('resize', this.debounce(this.handleResize, 500));
    if (localStorage.getItem('menuList')) {
      //this.$store.dispatch('pushPath',JSON.parse(localStorage.getItem('menuList')));
    }
    /*window.addEventListener('visibilitychange', function(e) {
      if (document.visibilityState === "visible") {
      } else {
      }
    });*/
  },
  methods: {
    handleResize() {
      if (window.innerWidth !== this.currentWidth) {
        this.currentWidth = window.innerWidth;
        location.reload();
      }
    },
    /**
     *      接收两个参数：要防抖的函数 func 和延迟时间 delay（以毫秒为单位）。
     * 这个 debounce 函数返回一个新的函数，当调用这个新函数时，它会清除之前的定时器，
     * 并设置一个新的定时器，只有在指定的延迟时间过后，才会执行原始的函数。
     *      在 mounted 钩子中，我们使用 debounce 函数包装了 handleResize 方法，
     * 并设置了一个 500 毫秒的延迟。这意味着只有在最后一次 resize 事件触发后的 500 毫秒内没有新的 resize 事件触发时，
     * handleResize 方法才会执行。
     *      请注意，我们在 beforeDestroy 钩子中也使用 debounce 函数来移除事件监听器，
     * 以确保不会在组件销毁后留下残留的监听器。
     * 这是因为我们添加的监听器是防抖包装后的函数，而不是原始的 handleResize 方法。
     * @param func
     * @param delay
     * @returns {(function(): void)|*}
     */
    debounce(func, delay) {
      let debounceTimer;
      return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    },
  },
  beforeDestroy() {
    //window.removeEventListener('resize', this.handleResize);
    // 确保在组件销毁前移除事件监听器
    window.removeEventListener('resize', this.debounce(this.handleResize, 500));
  },
}
</script>
<style lang="scss">
//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}
.super-back-div{
  background-color: #F1F1F1;
}
.el-table th.el-table__cell>.cell {
  font-weight: bold;
}
.el-table th {
  background: #F1F1F1 !important;
  .cell{
    //color: #F1F1F1 !important;
  }
}
.el-table__body tr.current-row>td{
  background-color: #69A8EA !important;
  color: #fff;
}

body {
  -webkit-user-select: none; /* Safari/Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}
img {
  user-drag: none;
  -webkit-user-drag: none; /* 兼容性：适用于Safari和Chrome */
  -moz-user-drag: none; /* 兼容性：适用于Firefox */
  -webkit-user-select: none; /* Safari/Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
