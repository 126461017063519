import request from "@/utils/request";

/**
 * 钱包相关
 * @type {string}
 */
const modulePrefix = 'wallet'

/**
 * 分页
 */
export function walletPageAPI(data) {
    return request({
        url: modulePrefix,
        method: 'get',
        params: data
    })
}

/**
 * 保存
 */
export function walletSaveAPI(data) {
    return request({
        url: modulePrefix,
        method: 'post',
        data: data
    })
}

/**
 * 修改
 */
export function walletModifyAPI(data) {
    return request({
        url: modulePrefix,
        method: 'put',
        data: data
    })
}

/**
 * 删除
 */
export function walletDeleteAPI(idListStr) {
    return request({
        url: modulePrefix+'/batch?id='+idListStr,
        method: 'delete'
    })
}