<template>
  <div>
    <div v-if="$terminal">
      <el-divider/>
      <div class="team-title" style="margin-bottom: 10px">异常订单</div>
      <el-table
          ref="multipleTable"
          :header-row-style="{'color':'gray'}"
          border
          lazy
          :data="tableData.list"
          style="width: 100%;margin-top: 10px;margin-bottom: 10px"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          @expand-change="handleExpandChange"
          :row-class-name="tableRowClassName">
        <el-table-column type="selection"
                         v-if="false"
                         :selectable="selectable"
                         align="center"
                         width="40">
        </el-table-column>
        <template v-for="(item,index) in columnList">
          <el-table-column :align="item.align"
                           :fixed="item.fixed"
                           v-if="(!item.template) && $checkRole(item.auth)"
                           :show-overflow-tooltip="true"
                           :min-width="item.width"
                           :prop="item.prop"
                           :key="index"
                           :label="item.label">
          </el-table-column>
          <el-table-column :align="item.align"
                           :fixed="item.fixed"
                           v-else-if="$checkRole(item.auth)"
                           :show-overflow-tooltip="true"
                           :min-width="item.width"
                           :prop="item.prop"
                           :fit="true"
                           :key="index"
                           :label="item.label">
            <template slot-scope="scope">
              <template v-if="item.params==='control'">
                <OrderControl @refresh="init" :row="scope.row"/>
              </template>
              <template v-else-if="item.params==='payState'">
                <el-tag size="mini">{{scope.row.state|payStateTransfer()}}</el-tag>
              </template>
              <template v-else-if="item.params==='payType'">
                {{scope.row.payType|payTypeTransfer()}}
              </template>
              <template v-else-if="item.params==='totalSeconds'">
                <Countdown :start="scope.row.state === 0" :key="scope.row.id" @refresh="init" :total="scope.row.totalSeconds"/>
              </template>
              <template v-else-if="item.params==='notice'">
                <el-tag size="mini" :type="scope.row.notice === 1 ? 'success' : 'warning'">{{scope.row.notice|dictTransfer(noticeList)}}</el-tag>
              </template>
              <template v-else-if="item.params==='bCollAccount'">
                <Copy :text="scope.row.bCollAccount"/>
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <paging
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
    </div>
    <div v-else>
      <!--列表-->
      <div class="h5-content" v-for="(item,index) in tableData.list">
        <div class="h5-row">
          <div class="label-height">
            订单号
          </div>
          <Copy :text="item.id"/>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            平台昵称
          </div>
          <div>{{item.cNickName}}</div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            订单金额
          </div>
          <div>{{item.amount}}</div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            成交数量
          </div>
          <div>
            {{item.amount-item.bonus}}
          </div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            付款人姓名
          </div>
          <div>
            {{item.cRealName}}
          </div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">
            状态
          </div>
          <div>
            <el-tag :type="item.state===7 ?
                'warning' : item.state===-1 ?
                'success':item.state===6 ?
                'danger':item.state===3 ? 'warning' : ''"
                    size="mini">{{item.state|payStateTransfer()}}</el-tag>
          </div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">创建时间</div>
          <div>{{item.createTime}}</div>
        </div><hr>
        <div class="h5-row">
          <div class="label-height">更新时间</div>
          <div>{{item.updateTime}}</div>
        </div><hr>
        <div class="h5-row-item-right">
          <OrderControl @refresh="init" :row="item"/>
        </div>
      </div>
      <!--分页-->
      <paging
          v-if="tableData.total !== 0"
          class="h5-content"
          :component-size="true"
          :page-sizes="[10,20,25,30,40]"
          :update:page="form.pageNum"
          :update:limit="form.pageSize"
          :total="tableData.total"
          @changeSize="changeSize"
          @pagination="pagination"/>
      <el-empty class="h5-content" v-if="tableData.total === 0" description="暂时没有异常订单"></el-empty>
    </div>
  </div>
</template>

<script>
import constant from "@/constant";
import {orderModifyAPI, orderPageAPI} from "@/api/order";
import Countdown from "@/components/Countdown.vue";
import Copy from "@/components/Copy.vue";
import paging from "@/components/Paging.vue";
import dictTransfer from "@/mixin/DictTransfer";
import common from "@/mixin/common";
import OrderControl from "@/components/OrderControl.vue";

export default {
  name: "AbnormalOrder",
  components: {OrderControl, paging, Copy, Countdown},
  mixins:[dictTransfer,common],
  data(){
    return{
      roleName:JSON.parse(localStorage.getItem('userInfo')).roleName,
      tableData:{
        list:[],
        total:0
      },
      columnList:[
        // {align:"center",label:"订单号",prop:"id",width:100,template:false,params:'',show:true,auth:constant.authAll,fixed:''},
        {align:"center",label:"支付状态",prop:"payState",width:110,template:true,params:'payState',show:true,auth:constant.authAll,fixed:'left',autoWidth:false,},
        {align:"center",label:"通知",prop:"notice",width:110,template:true,params:'notice',show:true,auth:constant.authAdmin,fixed:'',autoWidth:false,},
        {align:"center",label:"支付方式",prop:"payType",width:100,template:true,params:'payType',show:true,auth:constant.authAll,fixed:'',autoWidth:false,},
        {align:"center",label:"码商昵称",prop:"bNickName",width:'100',template:false,params:'account',show:true,auth:constant.authConsumer,fixed:'',autoWidth:false,},
        {align:"center",label:"码商姓名",prop:"bRealName",width:'100',template:false,params:'account',show:true,auth:constant.authConsumer,fixed:'',autoWidth:false,},
        {align:"center",label:"收款帐号",prop:"bCollAccount",width:'100',template:true,params:'bCollAccount',show:true,auth:constant.authConsumer,fixed:'',autoWidth:false,},
        {align:"center",label:"手动获取",prop:"cPayName",width:'120',template:false,params:'account',show:true,auth:constant.authBusiness,fixed:'',autoWidth:false,},
        {align:"center",label:"支付帐号",prop:"cPayAccount",width:'100',template:false,params:'address',show:true,auth:constant.authAdmin,fixed:'',autoWidth:false,},
        {align:"center",label:"交易金额",prop:"amount",width:100,template:false,params:'',show:true,auth:constant.authAll,fixed:'',autoWidth:false,},
        {align:"center",label:"购买数量",prop:"uCount",width:100,template:false,params:'usdtCount',show:true,auth:constant.authAll,fixed:'',autoWidth:false,},
        {align:"center",label:"创建时间",prop:"createTime",width:'100',template:false,params:'',show:true,auth:constant.authAll,fixed:'',autoWidth:false,},
        {align:"center",label:"支付时间",prop:"payTime",width:'100',template:false,params:'',show:true,auth:constant.authBC,fixed:'',autoWidth:false,},
        // {align:"center",label:"支付倒计时",prop:"totalSeconds",width:'100',template:true,params:'totalSeconds',show:true,auth:constant.authConsumer,fixed:'',autoWidth:false,},
        {align:"center",label:"操作",prop:"answer",width:'200',template:true,params:'control',show:true,auth:constant.authAll,fixed:'right',autoWidth:false,},
      ],
      form:{
        pageNum:1,
        pageSize:10,
      },
      ids:[],
      options:constant.payState,
      noticeList:constant.notice,
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(params){
      if (params === 'all') {
        this.$emit('refresh','all-a')
      }
      this.form.state='16';
      orderPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData.list=[]
          if (res.data.records) {
            this.tableData.list=res.data.records;
          } else {
            this.showCreateOrder=true
          }
          this.tableData.total=res.data.total
          this.$store.state.eventNum=res.data.total
        }
      })
    },
    controls(type,params){
      console.log(type,params)
      let form
      if (params) {
        form = {
          id:params.id,
          state:constant.payState[0].type,
          payType:params.payType,
          userId:params.userId,
          businessId:params.businessId,
          orderVersion:params.orderVersion,
        };
      }
      switch (type) {
        case 'cancelPay':
          form.state=constant.payState[7].type
          form.messageType=constant.payState[7].type
          this.modify(form)
          break;
        case 'closeOrder':
          this.$confirm('确认当前订单收到款项了吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            form.state=constant.payState[10].type
            form.messageType=constant.payState[10].type
            this.modify(form)
          }).catch(() => {});
          break;
        case 'see':
          this.$router.push({path:'orderInfo',query:{id:params.id}})
          break;
        case 'payed':
          //this.dialogVisible=true
          form.state=constant.payState[2].type;
          form.messageType=constant.payState[2].type;
          this.modify(form)
          break;
        case 'lockOrder':
          console.log(params)
          if (params.uCount) {
            form.state=constant.payState[1].type
            form.messageType=constant.payState[1].type
            form.uCount=String(params.uCount)
            this.modify(form)
          } else {
            this.error('购买数量不少于0')
          }
          break;
        case 'warn':
          form.businessId=params.businessId
          form.messageType=constant.messageType[3].type
          form.state=constant.payState[9].type
          this.modify(form)
          break;
        case 'table-payed':
          if (this.ids.length === 0) {
            this.error('请选择要操作的订单')
            return;
          }

          break
        case 'table-warn':
          if (this.ids.length === 0) {
            this.error('请选择要操作的订单')
            return
          }
          break
          //补单
        case 'replenishment':
          form.state=constant.payState[11].type
          form.messageType=constant.payState[11].type
          this.modify(form)
          break
        case 'applyRefund':
          form.state=constant.payState[4].type
          form.messageType=constant.payState[4].type
          this.modify(form)
          break
        case 'cancelRefund':
          form.state=constant.payState[12].type
          form.messageType=constant.payState[12].type
          this.modify(form)
          break
        case 'modifyAmount':
          form.state=constant.payState[13].type
          form.messageType=constant.payState[13].type
          this.$prompt('请输入修改后的金额', '修改订单金额', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            if (value <= 0) {
              return
            }
            form.amount=value;
            this.modify(form)
          }).catch(() => {});
          break
        case 'applyPass':
          form.state=12
          form.messageType=12
          this.$confirm('订单金额确认无误吗?', '确认订单金额', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form)
          }).catch(() => {
          });
          break
        case 'freeze':
          form.state=16
          form.messageType=16
          this.$confirm('确定要冻结当前订单吗?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form,type)
          }).catch(() => {
          });
          break
      }
    },
    modify(form,type){
      orderModifyAPI(form).then(res=>{
        if (res.code === 200) {
          this.popUp(res);
          this.init()
          this.$emit('refresh','AbnormalOrder')
        }
      });
    },
    refund(params){
      this.form.id=params.id
      this.form.businessId=params.businessId
      this.form.userId=params.userId
      this.form.orderVersion=params.orderVersion
      this.form.state=constant.payState[6].type;
      this.form.messageType=constant.payState[6].type;
      this.$confirm('确认将钱款退还吗?', '危险操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        orderModifyAPI(this.form).then(res=>{
          if (res.code === 200) {
            this.popUp(res);
            this.init()
          }
        });
      }).catch(() => {});
    },
    handleSelectionChange(rows) {
      this.ids=[]
      let sum=0;
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
        if (value.amount) {
          sum+=value.amount
        }
      })
      this.sum=sum
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    selectable(row, index){
      return row.state===constant.payState[0].type
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
    saveOrUpdate(){
    },
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
.el-divider--horizontal{
  margin-top: 0;
}
</style>
