import wechatPay from "@/assets/userinfo/wechatPay.svg"
import aliPay from "@/assets/userinfo/aliPay.svg"
import bankPay from "@/assets/userinfo/bankPay.svg"

const payType = [
    {type: 0, name: '微信', icon: wechatPay, disabled: true},
    {type: 1, name: '支付宝', icon: aliPay, disabled: false},
    {type: 2, name: '银行卡', icon: bankPay, disabled: false},
]
const openStateList = [
    {type: 0, name: '禁止'},
    {type: 1, name: '允许'},
]
const userState = [
    {type: 0, name: '申请注册'},
    {type: 1, name: '正常'},
    {type: 2, name: '拒绝注册'},
    {type: 3, name: '拉黑'},
    {type: 4, name: '删除'},
]
//顺序不可变动
const payState = [
    {type: 0, name: '待支付', show: true,scope:''},
    {type: 1, name: '支付中', show: true,scope:''},
    {type: 2, name: '已支付', show: true,scope:''},
    {type: 3, name: '支付失败', show: true,scope:''},
    {type: 4, name: '申诉中', show: true,scope:''},
    {type: 5, name: '退款中', show: true,scope:''},
    {type: 6, name: '已退款', show: true,scope:''},
    {type: 7, name: '通知失败', show: true,scope:'history,admin'},
    {type: 8, name: '锁定订单', show: true,scope:''},
    {type: 9, name: '提醒卖家', show: false,scope:''},
    {type: -1, name: '通知成功', show: false,scope:'history,admin'},
    {type: 11, name: '补单', show: false,scope:'history,admin'},
    {type: 12, name: '撤回申请', show: false,scope:''},
    {type: 13, name: '审核中', show: false,scope:'admin'},
    {type: 14, name: '修改金额', show: false,scope:''},
    {type: 16, name: '冻结中', show: false,scope:''},
    {type: 17, name: '取消冻结', show: false,scope:''},
    {type: 18, name: '修改支付人信息', show: false,scope:''},
    {type: 19, name: '超时关闭', show: false,scope:''},
    {type: 20, name: '超时关闭', show: false,scope:'admin'},
    {type: 21, name: '支付失败', show: false,scope:''},
    {type: 22, name: '未通知', show: false,scope:'admin'},
    {type: 100, name: '转账成功', show: false,scope:''},
]
const orderState=[
    {type: -1, orderState: '已支付',noticeState:'通知成功'},
    {type: 0, orderState: '待支付',noticeState:'未通知'},
    {type: 2, orderState: '已支付',noticeState:'未通知'},
    {type: 6, orderState: '已退款',noticeState:'未通知'},
    {type: 7, orderState: '待支付',noticeState:'通知失败'},
    {type: 11, orderState: '已支付',noticeState:'补单'},
    {type: 13, orderState: '审核中',noticeState:'未通知'},
    {type: 20, orderState: '超时关闭',noticeState:'未通知'},
    {type: 21, orderState: '已支付',noticeState:'通知失败'},
]

const businessState = [
    {type: 0, name: '关闭盈利'},
    {type: 1, name: '开启盈利'},
]
const roleList = [
    {type: 1, name: '管理员',auth:''},
    {type: 2, name: '码商',auth:''},
    {type: 3, name: '手动获取',auth:'4'},
    {type: 4, name: '自动获取',auth:'4'},
    {type: 5, name: '客服',auth:''},
]
const messageType = [
    {type: 0, name: '聊天'},
    {type: 1, name: 'pong'},
    {type: 2, name: '订单消息'},
    {type: 3, name: '码商提醒'},
]
const roleMap = {
    admin: 1,
    business: 2,
    consumer: 3,
    api: 4
}
const phoneVerify = [
    {type: 0, name: '关闭'},
    {type: 1, name: '开启'},
]
const authAll = '管理员,码商,手动获取,自动获取,客服'
const authBusiness = '管理员,码商,客服'
const authConsumer = '管理员,手动获取,自动获取,客服'
const authAdmin = '管理员,客服'
const authAdminOnly = '管理员'
const authServiceOnly = '客服'
const authBC = '码商,管理员,客服'
const infoAuthList = [
    {type: 0,name:'未认证'},
    {type: 1,name:'申请认证'},
    {type: 2,name:'申请修改认证'},
    {type: 3,name:'认证通过'},
]
const notice = [
    {type: 0, name: '未通知'},
    {type: 1, name: '已通知'},
    {type: 11, name: '补单'},
]
const fundStreamDirection=[
    {type:1,name:'代收进行',auth:authBusiness},
    {type:3,name:'取消订单',auth:authBusiness},
    {type:2,name:'系统充值',auth:authBusiness},
    {type:103,name:'管理员充值',auth:authBusiness},
    {type:101,name:'下级返佣',auth:authBusiness},
    {type:102,name:'返佣退还',auth:authBusiness},
    {type:11,name:'佣金提现',auth:authBusiness},
    {type:100,name:'码商转账',auth:authBusiness},
    {type:105,name:'冻结余额',auth:authBusiness},
    {type:106,name:'解冻余额',auth:authBusiness},
    {type:107,name:'完成订单获利',auth:authBusiness},
    {type:108,name:'订单获利回退',auth:authBusiness},
]
const fundType=[
    {type:1,name:'TCCOIM'},
    {type:2,name:'TCCOIM'},
    {type:3,name:'TCCOIM'},
    {type:6,name:'TCCOIM'},
    {type:9,name:'TCCOIM'},

    {type:4,name:'CNY'},
    {type:5,name:'CNY'},
    {type:7,name:'CNY'},
    {type:8,name:'CNY'},
]
const fileType= {
    order:1
}
const topUpState=[
    {type:0,name:'待审核'},
    {type:1,name:'已到账'},
    {type:3,name:'忽略'},
    // {type:2,name:'已退还'},
]
const userRoleName=JSON.parse(localStorage.getItem('userInfo')).roleName ==='超级管理员'?'管理员':JSON.parse(localStorage.getItem('userInfo')).roleName
export default {
    payType,
    userState,
    payState,
    businessState,
    roleList,
    messageType,
    roleMap,
    phoneVerify,
    authAll,
    authBusiness,
    authConsumer,
    authAdminOnly,
    authBC,
    authAdmin,
    infoAuthList,
    notice,
    fundStreamDirection,
    userRoleName,
    fundType,
    fileType,
    topUpState,
    openStateList,
    orderState,
    authServiceOnly,
}
