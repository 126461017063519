<template>
  <div>
    <div v-if="tableData.total !== 0">
      <div class="h5-content" v-for="item in tableData.records===undefined?tableData.list:tableData.records">
        <h5-row label="订单号">
          <template slot="value">
            <Copy :text="item.id"/>
          </template>
        </h5-row>
        <h5-row v-if="false" label="平台昵称" :value="item.cNickName"></h5-row>
        <h5-row label="订单金额" :value="item.amount"></h5-row>
        <h5-row label="成交数量" :value="item.amount-item.bonus">
        </h5-row>
        <h5-row label="付款人姓名" :value="item.cRealName"></h5-row>
        <h5-row v-if="item.cPayAccount!==undefined&&item.cPayAccount!==''" label="付款人账号" :value="item.cPayAccount"></h5-row>
        <h5-row label="状态">
          <template slot="value">
            <el-tag :type="item.state===7 ?
                'warning' : item.state===-1 ?
                'success':item.state===6 ?
                'danger':item.state===3 ? 'warning' : ''"
                    size="mini">{{item.state|payStateTransfer()}}</el-tag>
          </template>
        </h5-row>
        <h5-row label="创建时间" :value="item.createTime"></h5-row>
        <h5-row label="更新时间" :value="item.updateTime"></h5-row>
        <div class="h5-row-item-right">
          <order-control :row="item" @refresh="init"></order-control>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty class="h5-content"/>
    </div>
  </div>
</template>

<script>
import H5Row from "@/components/h5-row.vue";
import Copy from "@/components/Copy.vue";
import OrderControl from "@/components/OrderControl.vue";
import dictTransfer from "@/mixin/DictTransfer";

export default {
  name: "OrderCard",
  props:{
    tableData:{
      type:Object,
      default:()=>{
        return {
          total:0,
          records:[]
        }
      }
    }
  },
  components: {OrderControl, Copy, H5Row},
  mixins:[dictTransfer],
  methods:{
    init(params,type){
      this.$emit('refresh',params,type)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/conmmon-style.scss";
</style>
