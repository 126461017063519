<template>
  <div>
    <div v-if="$terminal" class="content-main-padding">
      <div style="margin-bottom: 10px">
        <el-radio-group v-model="radio" @input="init">
          <el-radio :label="1">今日订单统计</el-radio>
          <el-radio :label="2">昨日订单统计</el-radio>
        </el-radio-group>
        <el-date-picker
            class="common-left-space"
            v-model="timeArr"
            type="daterange"
            align="left"
            @change="handleClick"
            format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-button class="common-left-space" type="info" @click="radio='';init()"><i class="el-icon-refresh"/> 重置搜索 </el-button>
      </div>
      <TradingList :key="date" :order-info="orderInfo"/>
    </div>
    <div v-else>
      <div class="h5-content">
        <DateRange></DateRange>
      </div>
      <div class="big-div">
        <div class="small-div">
          <span class="font-weight-bold">总金额</span>
          <h5-row label="支付宝" :value="orderInfo.totalAmountAli"></h5-row>
          <h5-row label="银行卡" :value="orderInfo.totalAmountBank"></h5-row>
          <h5-row label="总金额" :value="orderInfo.totalAmountBank+orderInfo.totalAmountAli"></h5-row>
        </div>
        <div class="small-div">
          <span class="font-weight-bold">总订单量</span>
          <h5-row label="支付宝" :value="orderInfo.countAliTotal"></h5-row>
          <h5-row label="银行卡" :value="orderInfo.countBankTotal"></h5-row>
          <h5-row label="总订单量" :value="orderInfo.countAliTotal+orderInfo.countBankTotal"></h5-row>
        </div>
      </div>

      <div class="big-div">
        <div class="small-div">
          <span class="font-weight-bold">成交额</span>
          <h5-row label="支付宝" :value="orderInfo.orderSuccessAli"></h5-row>
          <h5-row label="银行卡" :value="orderInfo.orderSuccessBank"></h5-row>
          <h5-row label="总成交额" :value="orderInfo.orderSuccessBank+orderInfo.orderSuccessAli"></h5-row>
        </div>
        <div class="small-div">
          <span class="font-weight-bold">成功订单量</span>
          <h5-row label="支付宝" :value="orderInfo.countAliSuccess"></h5-row>
          <h5-row label="银行卡" :value="orderInfo.countBankSuccess"></h5-row>
          <h5-row label="总成功订单量" :value="orderInfo.countBankSuccess+orderInfo.countAliSuccess"></h5-row>
        </div>
      </div>

      <div class="big-div">
        <div class="small-div">
          <span class="font-weight-bold">成功率</span>
          <h5-row label="支付宝" :value="(orderInfo.countAliTotal > 0 ? (((orderInfo.countAliSuccess / orderInfo.countAliTotal).toFixed(4))*100).toFixed(2) : '0.00')+'%'"></h5-row>
          <h5-row label="银行卡" :value="(orderInfo.countBankTotal > 0 ? (((orderInfo.countBankSuccess / orderInfo.countBankTotal).toFixed(4))*100).toFixed(2) : '0.00')+'%'"></h5-row>
          <h5-row label="总成功率" :value="((orderInfo.countAliTotal + orderInfo.countBankTotal) > 0 ? ((orderInfo.countAliSuccess + orderInfo.countBankSuccess) / ((orderInfo.countAliTotal + orderInfo.countBankTotal)).toFixed(4)*100).toFixed(2) : '0.00')+'%'"></h5-row>
        </div>
        <div class="small-div">
          <span class="font-weight-bold">结算金额</span>
          <h5-row label="已结算总额" :value="orderInfo.settleAmount"></h5-row>
          <h5-row label="未结算总额" :value="orderInfo.noSettleAmount"></h5-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import common from "@/mixin/common";
import dictTransfer from "@/mixin/DictTransfer";
import constant from "@/constant";
import TradingList from "@/components/TradingList.vue";
import {manageInfoAPI} from "@/api/order";
import DateRange from "@/components/DateRange.vue";
import H5Row from "@/components/h5-row.vue";

export default {
  name: "AdminIndex",
  components: {H5Row, DateRange, TradingList},
  mixins:[common,dictTransfer,constant],
  data(){
    return{
      radio: '',
      form:{
        // pageSize:10,
        //pageNum: 1,
        startTime: '',
        endTime: '',
      },
      tableData:{
        total:0,
        records:[]
      },
      columnList:[],
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      ids:[],
      activeName: '1',
      timeArr:[],
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(start.getTime())
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      date: new Date().getTime(),
      orderInfo:{
        //支付宝订单金额
        "totalAmountAli": 0,
        // 银行卡订单金额
        "totalAmountBank": 0,
        // 支付宝成功订单金额
        "orderSuccessAli": 0,
        // 银行卡成功订单金额
        "orderSuccessBank": 0,
        // 支付宝服务费
        "serviceChargeAli": 0,
        // 银行卡服务费
        "serviceChargeBank": 0,
        // 已结算银行卡服务费
        "serviceChargeBankSettle": 0,
        // 已结算支付宝服务费
        "serviceChargeAliSettle": 0,
        // 支付宝订单数量
        "countAliTotal": 0,
        // 银行卡订单数量
        "countBankTotal": 0,
        // 支付宝成功订单数量
        "countAliSuccess": 0,
        // 银行卡成功订单数量
        "countBankSuccess": 0,
      },
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      if (this.radio === 1) {
        this.timeArr=[new Date(),new Date()]
      } else if (this.radio === 2) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        end.setTime(end.getTime() - 3600 * 1000 * 24);
        this.timeArr=[start,end]
      }
      this.form.startTime=this.formatDate(this.timeArr[0],'start')
      this.form.endTime=this.formatDate(this.timeArr[1],'end')
      manageInfoAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.date=new Date().getTime()
          this.orderInfo=res.data;
        }
      })
    },
    //1 今日订单 2 昨日订单
    handleClick(tab) {
      this.radio=3
      this.init()
    },
    handleSelectionChange(rows) {
      this.ids=[]
      rows.forEach((value, index, array)=>{
        this.ids.push(value.id)
      })
    },
    sortChange(column, prop, order){
      if (column.order === 'descending') {
        this.form.orderType=''
      } else {
        this.form.orderType='asc'
      }
      this.init()
    },
    handleExpandChange(row, expanded){
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return '';
      } else if (rowIndex% 2 !== 0) {
        return 'success-row';
      }
    },
    selectable(row, index){
      return row.state===constant.payState[0].type
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.init()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.init()
    },
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/conmmon-style.scss";
::v-deep .el-tabs__item{
  font-weight: bold;
  font-size: 18px;
}
.trading-card{

}
::v-deep .el-tabs__nav-wrap{
  margin-top: -10px;
}
.big-div{
  margin-left: $right-left-width;
  margin-right: $right-left-width;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.small-div{
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 10px;
  margin-top:10px;
  margin-bottom: 10px;
  flex: 1;
}
</style>
