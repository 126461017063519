<template>
  <div>
    <TopUp/>
  </div>
</template>
<script>
import TopUp from "@/views/info/TopUp.vue";

export default {
  name: "AdminTopUp",
  components: {TopUp}
}
</script>

<style scoped lang="scss">

</style>
