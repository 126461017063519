<template>
  <div class="main">
    <!--pc端内容-->
    <div v-if="$terminal"
         style="display: flex;background-color: #F1F1F1">
      <div :class="isCollapse ? 'e-menu-expand':'e-menu'" style="height: 100vh">
        <el-menu :default-active="active"
                 :unique-opened="true"
                 :collapse-transition="false"
                 style="overflow-y: auto;height: 100%;"
                 class="el-menu-vertical-demo"
                 :class="isCollapse ? 'e-menu-expand':'e-menu'"
                 @open="handleOpen"
                 @close="handleClose"
                 :router="true"
                 background-color="#304156"
                 text-color="#fff"
                 active-text-color="#ffd04b"
                 :collapse="isCollapse">
          <div style="margin-bottom: 20px">
            <img class="logo"
                 :style="isCollapse?{width: '25px'}:{width: '32px'}"
                 style="width: 32px" src="../assets/logo.png" alt="">
            <span v-if="!isCollapse" style="margin-left: 20px;font-weight: bold;color: #F2FFF4">        {{loginText}}</span>
          </div>
          <template  v-for="(item,index) in menuList">
            <el-submenu :index="String(index)" v-if="item.type === 2">
              <template slot="title">
                <i :class="item.icon"/>
                <span v-if="item.type === 2">{{item.name}}</span>
              </template>
              <el-menu-item :route="child.path" v-for="(child,cI) in item.children" :index="child.path">
                <i :class="child.icon"/>{{$t(`router.${child.name}`)}}
              </el-menu-item>
            </el-submenu>
            <el-menu-item :index="item.path" v-if="item.type === 0 && item.show===1">
              <i :class="item.icon"/>
              <span slot="title">{{$t(`router.${item.name}`)}}</span>
            </el-menu-item>
          </template>
          <el-menu-item v-if="$checkRole('自动获取,手动获取')">
            <i class="el-icon-document"/>
            <a style="color: #FFFFFF;text-decoration: none" href="doc/TC-OTC平台API文档.md" download="TC-OTC平台API文档.md" slot="title">对接文档</a>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="content-main" :class="isCollapse ? 'main-menu-expand-no':'main-menu-expand'">
        <div v-if="$terminal" id="header-d-t" class="header-d">
          <div class="header-left">
            <i class="el-icon-s-unfold" v-if="isCollapse" style="cursor: pointer;position:relative;top: 18px;padding-left: 10px;font-size: 25px" @click="isCollapse=false"></i>
            <i class="el-icon-s-fold " v-if="!isCollapse" style="cursor: pointer;position:relative;top: 18px;padding-left: 10px;font-size: 25px" @click="isCollapse=true"></i>
            <span :class="spendTime<=200 ?
                            'network-success':spendTime>200 && spendTime<= 500 ?
                            'network-warning':'network-danger'" style="cursor: pointer;position:relative;top: 15px;margin-left: 10px">
              <i class="el-icon-monitor"/>
              {{spendTime!=='网络已断开'?spendTime+'ms':'请检查网络连接'}}</span>
            <el-breadcrumb style="position: relative;top: -2px;cursor: pointer"
                           :style="spendTime==='网络已断开'?{left: '220px'}:{left: '150px'}"
                           separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/adminIndex' }">首页</el-breadcrumb-item>
<!--              <el-breadcrumb-item v-if="$route.path !== '/adminIndex'" :to="{path: $route.path}">{{$t(`router.${$route.name}`)}}</el-breadcrumb-item>-->
              <el-breadcrumb-item v-if="$route.path !== '/adminIndex'"
                                  style="cursor: pointer"
                                  @click.native="$router.back()">返回上一页</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="header-right">
            <el-dropdown>
              <span class="el-dropdown-link">
                <el-badge :value="$store.state.eventNum"
                          type="danger">
                  <el-avatar shape="square" size="large" :src="img"></el-avatar>
                </el-badge>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="$router.push('/userinfo')">用户中心</el-dropdown-item>
                <el-badge :value="$store.state.eventNum" type="danger" class="item">
                  <el-dropdown-item @click.native="$router.push('/orderTrading')">查看订单</el-dropdown-item>
                </el-badge>
                <el-dropdown-item @click.native="modifyPwd">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="$store.dispatch('logout')" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <RouterView class="content"/>
      </div>
<!--      <div v-if="showGoUp && false" @click="goTop()" class="go-up" title="回到顶部">
        <i class="el-icon-upload2"></i>
      </div>-->
    </div>

    <!--移动端内容-->
    <div v-if="!$terminal">
      <div style="display:flex;background-color: white;height: 55px;margin-bottom: 10px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <!--左侧图标-->
        <div style="flex-grow: 0;">
          <i class="el-icon-s-unfold" v-if="isCollapse" style="cursor: pointer;position:relative;top: 15px;padding-left: 10px;font-size: 25px" @click="isCollapse=false"></i>
          <i class="el-icon-s-fold " v-if="!isCollapse" style="cursor: pointer;position:relative;top: 15px;padding-left: 10px;font-size: 25px" @click="isCollapse=true"></i>
          <i class="el-icon-refresh" @click="refreshMethod" style="cursor: pointer;position:relative;top: 15px;left: 40%;font-size: 25px"/>
        </div>
        <!--当前路由标题-->
        <div style="position: relative;top: 16px;margin: 0 auto;font-size: 20px" class="m-title">{{getTitle()}}</div>
        <!--右侧图标-->
        <div  class="m-title">
          <!--回执图标-->
          <i class="el-icon-message-solid" @click="selectCallback('con')" :class="$store.state.eventNum>0?'feedback-btn':''"
             style="cursor: pointer;position:relative;top: 3px;right: 20%;font-size: 25px"/>
          <!--主题转换-->
          <i :class="theme?'el-icon-sunny':'el-icon-moon'" @click="toggleTheme" style="cursor: pointer;position:relative;top: 3px;right: 10%;font-size: 25px"/>
          <span v-if="false" style="position: relative;top: 13px;padding-right: 13px" @click="$router.back()">返回</span><!--<i class="el-icon-back"></i>-->
          <el-dropdown trigger="click" style="margin-top: 10px">
              <span class="el-dropdown-link">
                <el-badge :value="$store.state.eventNum"
                          type="danger">
                  <el-avatar shape="square" size="large" :src="img"></el-avatar>
                </el-badge>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="$router.push('/userinfo')">用户中心</el-dropdown-item>
              <el-badge :value="$store.state.eventNum" type="danger" class="item">
                <el-dropdown-item @click.native="$router.push('/orderTrading')">查看订单</el-dropdown-item>
              </el-badge>
              <el-dropdown-item @click.native="modifyPwd">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="$store.dispatch('logout')" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <RouterView style="
      height: calc(100vh - 55px - 10px);
      overflow-y: auto;"/>
      <el-drawer
          :show-close="false"
          :withHeader="false"
          size="70%"
          :visible.sync="isCollapse"
          direction="ltr">
        <el-menu :default-active="active"
                 :unique-opened="true"
                 :collapse-transition="false"
                 style="overflow-y: auto;height: 100%;"
                 class="el-menu-vertical-demo"
                 @open="handleOpen"
                 @close="handleClose"
                 :router="true"
                 @select="menuSelect"
                 background-color="#304156"
                 text-color="#fff"
                 active-text-color="#ffd04b"
                 :collapse="false">
          <div style="margin-bottom: 20px">
            <img class="logo"
                 :style="!isCollapse?{width: '25px'}:{width: '32px'}"
                 style="width: 32px" src="../assets/logo.png" alt="">
            <span v-if="isCollapse" style="margin-left: 20px;font-weight: bold;color: #F2FFF4">        {{loginText}}</span>
          </div>
          <template  v-for="(item,index) in menuList">
            <el-submenu :index="String(index)" v-if="item.type === 2">
              <template slot="title">
                <i :class="item.icon"/>
                <span v-if="item.type === 2">{{item.name}}</span>
              </template>
              <el-menu-item :route="child.path" v-for="(child,cI) in item.children" :index="child.path">
                <i :class="child.icon"/>{{$t(`router.${child.name}`)}}
              </el-menu-item>
            </el-submenu>
            <el-menu-item :index="item.path" v-if="item.type === 0 && item.show===1">
              <i :class="item.icon"/>
              <span slot="title">{{$t(`router.${item.name}`)}}</span>
            </el-menu-item>
          </template>
          <el-menu-item v-if="$checkRole('自动获取')">
            <i class="el-icon-document"/>
            <a style="color: #FFFFFF;text-decoration: none" href="doc/TC-OTC平台API文档.md" download="TC-OTC平台API文档.md" slot="title">对接文档</a>
          </el-menu-item>
        </el-menu>
      </el-drawer>
      <el-drawer
          :show-close="false"
          :withHeader="false"
          size="80%"
          :visible.sync="callback"
          direction="ttb">
        <button class="el-button--danger" @click="callback=false" style="position: absolute;right: 10px;top: 10px;">关闭</button>
        <div class="m-title" style="text-align: center;margin-top: 20px">
          回单待审
        </div>
        <order-card :table-data="tableData" @refresh="init"/>
        <!--分页-->
        <paging
            class="h5-content"
            :layout="'total,prev, pager,next'"
            :component-size="true"
            :page-sizes="[10,20,25,30,40]"
            :update:page="form.pageNum"
            :update:limit="form.pageSize"
            :total="tableData.total"
            @changeSize="changeSize"
            @pagination="pagination"/>
      </el-drawer>
      <el-drawer
          :show-close="false"
          :withHeader="false"
          size="50%"
          :visible.sync="callback2"
          direction="ttb">
        <button class="el-button--danger" @click="callback=false" style="position: absolute;right: 10px;top: 10px;">关闭</button>
        <div class="m-title" style="text-align: center;margin-top: 20px">
          回单待审
        </div>
        <order-card :table-data="tableData" @refresh="init"/>
        <!--分页-->
        <paging
            class="h5-content"
            :layout="'total,prev, pager,next'"
            :component-size="true"
            :page-sizes="[10,20,25,30,40]"
            :update:page="form.pageNum"
            :update:limit="form.pageSize"
            :total="tableData.total"
            @changeSize="changeSize"
            @pagination="pagination"/>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import img from "@/assets/userinfo/img.png"
import {getRouteList} from "@/router";
import AdminMenu from "@/components/admin-menu";
import websocket from "@/mixin/websocket";
import H5Row from "@/components/h5-row.vue";
import OrderControl from "@/components/OrderControl.vue";
import paging from "@/components/Paging.vue";
import OrderCard from "@/components/OrderCard.vue";
import {orderPageAPI} from "@/api/order";
let timer
export default {
  name: "Layout-Business",
  components:{OrderCard, paging, OrderControl, H5Row, AdminMenu},
  mixins:[websocket],
  data() {
    return {
      menuList:JSON.parse(localStorage.getItem('menuList'))[0].children,
      active: this.$route.path,
      showGoUp: false,
      img:img,
      isCollapse: false,
      //sun dark
      theme: true,
      callback:false,
      callback2:false,
      tableData:{
        total:0,
        records:[]
      },
      form:{
        pageNum:1,
        pageSize:10,
      },
      loginText: '',
      orderTimer: null,

    }
  },
  mounted() {
    if (location.hostname === 'tc-otc.com') {
      this.loginText='TC - OTC'
    } else {
      this.loginText='TC - 支付后台'
    }
    let that=this
    timer = setInterval(()=>{
      that.showGoUp=document.documentElement.scrollHeight > window.outerHeight
    },500)
    this.$bus.$on('callback',()=>{
      this.selectCallback()
    })
    this.$bus.$on('refreshOrder',()=>{
      this.selectCallback()
    })
    if (this.orderTimer === null) {
      this.orderTimer = setInterval(()=>{
        let form={};
        form.state='0,2,13'
        form.pageSize=1
        form.pageNum=1
        orderPageAPI(form).then(res=>{
          if (res.code === 200) {
            this.$store.state.eventNum=res.data.total
          }
        })
      },1000*60*10);
    }
  },
  computed: {
    isAdmin(){
      return this.active.includes('/admin')
    },
  },
  watch: {
    $route(to, from) {
      this.active = to.path
    },
  },
  methods: {
    init(){
    },
    refreshMethod(){
      this.$bus.$emit('refreshLayout')
    },
    selectCallback(params){
      this.form.state='2';
      orderPageAPI(this.form).then(res=>{
        if (res.code === 200) {
          this.tableData=res.data
          // this.$store.state.eventNum=res.data.total
          if (res.data.total > 0) {
            this.callback=true;
          } else {
            if (params === 'con') {
              this.callback2=true;
            }
            if (params === 'close') {
              this.callback2=false;
              this.callback=false;
            }
          }
        }
      })
    },
    menuSelect(){
      this.isCollapse = false
    },
    logout(){
      this.$router.push('/login')
    },
    modifyPwd(){
      this.$store.dispatch('updatePwd')
    },
    getTitle(){
      return document.title
    },
    skipRoute(path){
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    goTop(){
      window.scrollTo({top:0,behavior:'smooth'})
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
    },
    toggleTheme() {
      // this.theme = !this.theme;
    },
    pagination(obj){
      this.form.pageSize=obj.limit
      this.selectCallback()
    },
    changeSize(obj){
      this.form.pageNum=obj.page
      this.selectCallback()
    },
  },
  filters: {},
  beforeDestroy() {
    clearInterval(timer)
    clearInterval(this.orderTimer)
    this.orderTimer=null
    this.$bus.$off('callback')
    this.$bus.$off('refreshOrder')
  }
}
</script>

<style scoped lang="scss">
@import "../assets/layout/header.scss";
@import "../assets/style/conmmon-style.scss";
$common-width:90%;
$header-height:60px;
$footer-height:0px;
$header-main-footer-space:0px;
@mixin common-center{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.main{
  height: 100vh;
}
.content-main{
  width: 100%;
  height: 100vh !important;
  background-color: #F1F1F1;
  .header-d{
    display: flex;
    background-color: white;
    height: $header-height;
    .header-left{}
    .header-right{
      cursor: pointer;
      position: absolute;
      //60 是头像的宽度
      right: 30px;
      top: 8px;
    }
  }
}

.logo{
  position: relative;
  left: 20px;
  top: 10px;
}
$menu-width-expand:230px;
$menu-width-expand-no:62px;
.main-menu-expand{
  width: calc(100vw - $menu-width-expand);
}
.main-menu-expand-no{
  width: calc(100vw - $menu-width-expand-no);
}
.e-menu{
  width: $menu-width-expand;
}
.e-menu-expand{
  width: $menu-width-expand-no;
}

.network{
  position: absolute;
  left: 10px;
  top: 18px;
  cursor: pointer;
}
.network-success{
  color: #67C23A;
}
.network-warning{
  color: #E6A23C;
}
.network-danger{
  color: #F56C6C;
}
.item {
  //margin-top: 10px;
  margin-right: 20px;
}
::v-deep .el-menu{
  border-right: none;
}
.feedback-btn {
  //margin: 0;
  //padding: 0;
  //height: 100vh;
  background-color: white; /* 初始颜色 */
  animation: colorChange 1s infinite alternate;
  //padding: 5px;
  border-radius: 4px;
}
@keyframes colorChange {
  0% {
    color: #303133; /* 开始颜色 */
  }
  100% {
    color: #F56C6C; /* 结束颜色 */
  }
}
</style>
