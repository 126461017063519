<template>
  <div>
    <!--详情-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" type="info" size="mini" @click="controls('see',row)">{{$t('table.see')}}</el-link>
    <!--已支付-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('已支付',row,'手动获取,自动获取')" type="primary" size="mini" @click="getLoadLink(row)">{{$t('table.payed')}}</el-link>
    <!--修改支付信息-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('修改支付信息',row,'管理员,客服')" type="warning" size="mini" @click="form.cPayAccount=row.cPayAccount;form.cRealName=row.cRealName;form.remark=row.remark;getLoadLink2()">{{$t('table.modifyPayInfo')}}</el-link>
    <!--提醒卖家-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('提醒卖家',row,'手动获取,自动获取')" type="success" size="mini" @click="controls('warn',row)">已支付</el-link>
    <!--确认收款-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('确认收款',row,'码商,管理员,客服')" type="success" size="mini" @click="controls('closeOrder',row)">{{$t('table.closeOrder')}}</el-link>
    <!--修改订单金额-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('修改订单金额',row,'管理员,客服')" type="primary" size="mini" @click="controls('modifyAmount',row)">{{$t('table.modifyAmount')}}</el-link>
    <!--审核订单金额-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('审核订单金额',row,'手动获取,自动获取')" type="primary" size="mini" @click="controls('applyPass',row)">{{$t('table.applyPass')}}</el-link>
    <!--冻结-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('冻结',row,'管理员,客服')" type="warning" size="mini" @click="controls('freeze',row)">冻结订单</el-link>
    <!--取消冻结-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('取消冻结',row,'管理员,客服')" type="warning" size="mini" @click="controls('cancelFreeze',row)">取消冻结</el-link>
    <!--取消订单-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('取消订单',row,'管理员,客服')" type="danger" size="mini" @click="controls('cancelPay',row)">{{$t('table.cancelPay')}}</el-link>
    <!--退款-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('退款',row,'管理员,客服')" type="danger" size="mini" @click="controls('applyRefund',row)">{{$t('table.applyRefund')}}</el-link>
    <!--状态回退-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('状态回退',row,'管理员,客服')" type="danger" size="mini" @click="controls('stateRollBack',row)">{{$t('table.rollBack')}}</el-link>
    <!--补单-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('补单',row,'管理员,客服')" type="success" size="mini" @click="controls('replenishment',row)">{{$t('table.replenishment')}}</el-link>
    <!--解冻补单-->
    <el-link :class="!$terminal?'el-link-h5':''" class="common-left-space" v-if="orderState('解冻并补单',row,'管理员,客服')" type="success" size="mini" @click="controls('freezeAndReplenishment',row)">{{$t('table.replenishment')}}</el-link>
    <el-dialog
        v-if="$terminal"
        title="上传支付凭证"
        :visible.sync="dialogVisible"
        width="30%"
        :modal="false"
        :before-close="handleClose">
      <m-upload @success="handleSuccess" :upload-url="uploadUrl"></m-upload>
      <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-popconfirm
              class="common-left-space"
              confirm-button-text='确定'
              cancel-button-text='重新上传'
              icon="el-icon-info"
              icon-color="red"
              @confirm="confirmPay"
              title="确认上传完成了吗？">
<!--          <el-button type="primary" v-if="false" :disabled="this.form.urlId===''" slot="reference">保 存</el-button>-->
          <el-button type="primary" slot="reference">保 存</el-button>
        </el-popconfirm>
        </span>
    </el-dialog>
    <el-dialog
        title="修改支付信息"
        v-if="$terminal"
        :visible.sync="dialogModifyVisible"
        width="30%"
        :modal="false"
        :modal-append-to-body="true"
        style="z-index: 9999"
        :before-close="handleClose">
      <el-form label-width="100px">
        <el-form-item v-if="false" label="付款帐号">
          <el-input style="width: 100%" v-model="form.cPayAccount" placeholder="请输入付款帐号"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input style="width: 100%" v-model="form.cRealName" placeholder="请输入姓名"></el-input>
        </el-form-item>
      </el-form>
      <m-upload @success="handleSuccess" :upload-url="uploadUrl"></m-upload>
      <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-popconfirm
              class="common-left-space"
              confirm-button-text='确定'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              @confirm="controls('consumerModify',row)"
              title="确认信息无误了吗？">
          <el-button type="primary" slot="reference">保 存</el-button>
        </el-popconfirm>
        </span>
    </el-dialog>
    <el-drawer
        v-if="!$terminal"
        :destroy-on-close="true"
        :show-close="false"
        :withHeader="false"
        size="70%"
        :visible.sync="dialogVisible"
        direction="btt">
      <div class="h5-content">
        <m-upload style="display: flex;flex-wrap: nowrap;flex-direction: column;align-items: center;" @success="handleSuccess" :upload-url="uploadUrl"></m-upload>
        <el-input style="width: 100%;margin-top: 10px;margin-bottom: 10px" v-model="remark" placeholder="请输入备注信息"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-popconfirm
              class="common-left-space"
              confirm-button-text='确定'
              cancel-button-text='重新上传'
              icon="el-icon-info"
              icon-color="red"
              @confirm="confirmPay"
              title="确认上传完成了吗？">
<!--          <el-button type="primary" v-if="false" :disabled="this.form.urlId===''" slot="reference">保 存</el-button>-->
          <el-button type="primary" slot="reference">保 存</el-button>
        </el-popconfirm>
        </span>
      </div>
    </el-drawer>
    <el-drawer
        v-if="!$terminal"
        :destroy-on-close="true"
        :show-close="false"
        :withHeader="false"
        size="70%"
        :visible.sync="dialogModifyVisible"
        direction="btt">
      <div class="h5-content">
        <el-form label-width="100px">
          <el-form-item v-if="false" label="付款帐号">
            <el-input style="width: 100%" v-model="form.cPayAccount" placeholder="请输入付款帐号"></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input style="width: 100%" v-model="form.cRealName" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input style="width: 100%" v-model="remark" placeholder="请输入备注信息"></el-input>
          </el-form-item>
        </el-form>
        <m-upload style="display: flex;flex-wrap: nowrap;flex-direction: column;align-items: center;" @success="handleSuccess" :upload-url="uploadUrl"></m-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-popconfirm
              class="common-left-space"
              confirm-button-text='确定'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              @confirm="controls('consumerModify',row)"
              title="确认信息无误了吗？">
          <el-button type="primary" slot="reference">保 存</el-button>
        </el-popconfirm>
        </span>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import constant from "@/constant";
import {orderFileAPI, orderModifyAPI} from "@/api/order";
import common from "@/mixin/common";
import MUpload from "@/components/M-Upload.vue";
import 'wc-messagebox/style.css'
let a=1
let that
export default {
  name: "OrderControl",
  components: {MUpload},
  props:{
    row:{
      type:Object,
      default(){
        return {}
      }
    },
    //1 正在交易 2异常订单 3历史订单
    type:{
      type:Number,
      default:1
    }
  },
  mixins:[common],
  data(){
    return {
      dialogVisible:false,
      tempParam:{},
      uploadUrl: '',
      form:{
        urlId:'',
        cRealName:'',
        cPayAccount:'',
        remark: '',
      },
      remark: '',
      dialogModifyVisible:false,
    }
  },
  mounted() {
    that=this
    this.$bus.$on('payedApi',(form)=>{
      if (a === 1) {
        that.getLoadLink(form);
      }
    })
  },
  methods:{
    getLoadLink(params){
      a=0
      orderFileAPI().then(res=>{
        a=1
        if (res.code === 200) {
          this.uploadUrl=res.data
          this.dialogVisible=true
          this.tempParam=params
        }
      })
    },
    getLoadLink2(){
      orderFileAPI().then(res=>{
        if (res.code === 200) {
          this.uploadUrl=res.data
          this.dialogModifyVisible=true;
        }
      })
    },
    confirmPay(){
      this.controls('payed',this.tempParam)
    },
    handleClose(){
      this.form.urlId=''
      this.dialogVisible=false
      this.dialogModifyVisible=false
      this.remark=''
    },
    handleSuccess(){
      this.form.urlId=this.uploadUrl.substring(this.uploadUrl.lastIndexOf('/') + 1, this.uploadUrl.indexOf('.png'))
    },
    async controls(type,params){
      let form
      if (params) {
        form = {
          id:params.id,
          state:constant.payState[0].type,
          payType:params.payType,
          userId:params.userId,
          businessId:params.businessId,
          orderVersion:params.orderVersion,
        };
      }
      switch (type) {
        case 'cancelPay':
          this.$confirm('确认取消订单吗?', '取消订单', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            form.state=7
            form.messageType=7
            this.modify(form)
          })
          break;
        case 'closeOrder':
          if (this.$terminal) {
            this.$confirm('此操作将结束订单, 是否继续?', '确认收款', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              form.state=constant.payState[10].type
              form.messageType=constant.payState[10].type
              this.modify(form)
            }).catch(() => {});
          } else {
            this.$confirm('是否确认收款?').then(()=>{
              form.state=constant.payState[10].type
              form.messageType=constant.payState[10].type
              this.$bus.$emit('callback','close')
              this.modify(form)
            }).catch(()=>{})
          }
          break;
        case 'see':
          this.$router.push({path:'orderInfo',query:{id:params.id}})
          break;
        case 'payed':
          form.urlId=this.form.urlId
          form.fileName=this.uploadUrl.substring(this.uploadUrl.indexOf('trading')+8,this.uploadUrl.indexOf('?'))
          form.state=constant.payState[2].type;
          form.messageType=constant.payState[2].type;
          form.remark=this.remark
          await this.modify(form)
          break;
        case 'lockOrder':
          console.log(params)
          if (params.uCount) {
            form.state=constant.payState[1].type
            form.messageType=constant.payState[1].type
            form.uCount=String(params.uCount)
            await this.modify(form)
          } else {
            this.error('购买数量不少于0')
          }
          break;
        case 'warn':
          form.businessId=params.businessId
          form.messageType=constant.messageType[3].type
          form.state=constant.payState[9].type
          await this.modify(form)
          break;
        case 'table-payed':
          if (this.ids.length === 0) {
            this.error('请选择要操作的订单')
            return;
          }

          break
        case 'table-warn':
          if (this.ids.length === 0) {
            this.error('请选择要操作的订单')
            return
          }
          break
          //补单
        case 'replenishment':
          form.state=constant.payState[11].type
          form.messageType=constant.payState[11].type
          this.$confirm('此操作将进行< 补单 > 操作, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form)
          }).catch(() => {});
          break
        case 'applyRefund':
          form.state=constant.payState[6].type
          form.messageType=constant.payState[6].type
          this.$confirm('确认将钱款退还吗?', '危险操作', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form)
          }).catch(() => {});
          break
        case 'cancelRefund':
          form.state=constant.payState[12].type
          form.messageType=constant.payState[12].type
          await this.modify(form)
          break
        case 'modifyAmount':
          form.state=constant.payState[13].type
          form.messageType=constant.payState[13].type
          this.$prompt('请输入修改后的金额', '修改订单金额', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            if (value <= 0) {
              return
            }
            form.amount=value;
            this.modify(form)
          }).catch(() => {});
          break
        case 'applyPass':
          form.state=12
          form.messageType=12
          this.$confirm('订单金额确认无误吗?', '确认订单金额', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form)
          }).catch(() => {
          });
          break
        case 'freeze':
          form.state=16
          form.messageType=16
          this.$confirm('确定要冻结当前订单吗?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form,type)
          }).catch(() => {
          });
          break
        case 'cancelFreeze':
          form.state=17
          form.messageType=17
          this.$confirm('确定要解冻当前订单吗?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form,type)
          }).catch(() => {});
          break
        case 'consumerModify':
          form.state=18
          form.messageType=18
          form.cPayAccount=this.form.cPayAccount
          form.cRealName=this.form.cRealName
          if (this.form.urlId !== '') {
            form.urlId=this.form.urlId;
            form.fileName=this.uploadUrl.substring(this.uploadUrl.indexOf('trading')+8,this.uploadUrl.indexOf('?'))
          }
          form.remark=this.remark
          await this.modify(form)
          break
        case 'freezeAndReplenishment':
          // form.state=17
          // form.messageType=17
          // await this.modify(form,type)
          // setTimeout(()=>{
          //   this.controls('replenishment',params)
          // },1500)
          break
        case 'stateRollBack':
          form.state=19
          form.messageType=19
          this.$confirm('确定要将订单状态回退到上一步吗?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.modify(form,type)
          }).catch(() => {});
          break
      }
    },
    async modify(form, type) {
      try {
        const res = await orderModifyAPI(form);
        if (res.code === 200) {
          this.popUp(res);
          this.dialogVisible = false;
          this.dialogModifyVisible = false;
          this.$emit('refresh', 'all');
          this.$emit('callback');
        }
      } catch (error) {
        // 处理错误情况
        console.error(error);
      }
    }
  },
  beforeDestroy() {
    this.$bus.$off('payedApi')
    that=null
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/conmmon-style.scss";
.el-link-h5 {
  font-size: 18px;
}
</style>
