<template>
  <div style="display: flex;flex-wrap: wrap;gap: 10px">
    <el-card  shadow="hover" style="min-width: 200px">
      <div>支付宝：{{info.totalAmountAli}}</div>
      <div class="top-bottom">银行卡：{{info.totalAmountBank}}</div>
      <div>总金额：{{info.totalAmountAli+info.totalAmountBank}}</div>
    </el-card>
    <el-card  shadow="hover" style="min-width: 200px">
      <div>支付宝：{{info.orderSuccessAli}}</div>
      <div class="top-bottom">银行卡：{{info.orderSuccessBank}}</div>
      <div>总成交额：{{info.orderSuccessAli+info.orderSuccessBank}}</div>
    </el-card>
    <el-card  shadow="hover" style="min-width: 200px">
      <div>支付宝：{{info.countAliTotal}}</div>
      <div class="top-bottom">银行卡：{{info.countBankTotal}}</div>
      <div>总订单量：{{info.countAliTotal+info.countBankTotal}}</div>
    </el-card>
    <el-card  shadow="hover" style="min-width: 200px">
      <div>支付宝：{{info.countAliSuccess}}</div>
      <div class="top-bottom">银行卡：{{info.countBankSuccess}}</div>
      <div>总成功订单量：{{info.countAliSuccess+info.countBankSuccess}}</div>
    </el-card>
    <el-card  shadow="hover" style="min-width: 200px">
      <div>支付宝：{{ (info.countAliTotal > 0 ? (((info.countAliSuccess / info.countAliTotal).toFixed(4))*100).toFixed(2) : '0.00') }}%</div>
      <div class="top-bottom">银行卡：{{ (info.countBankTotal > 0 ? (((info.countBankSuccess / info.countBankTotal).toFixed(4))*100).toFixed(2) : '0.00') }}%</div>
      <div>总成功率：{{ ((info.countAliTotal + info.countBankTotal) > 0 ? (((info.countAliSuccess + info.countBankSuccess) / (info.countAliTotal + info.countBankTotal)).toFixed(4)*100).toFixed(2) : '0.00') }}%</div>
    </el-card>

    <el-card v-if="false" v-role="'管理员,客服'" shadow="hover" style="min-width: 200px">
      <div>支付宝：{{info.serviceChargeAli}}</div>
      <div class="top-bottom">银行卡：{{info.serviceChargeBank}}</div>
      <div>商户总服务费：{{info.serviceChargeAli+info.serviceChargeBank}}</div>
    </el-card>
    <el-card v-if="false" v-role="'管理员,客服'" shadow="hover" style="min-width: 200px">
      <div>支付宝：{{info.serviceChargeBankSettle}}</div>
      <div class="top-bottom">银行卡：{{info.serviceChargeAliSettle}}</div>
      <div>商户当日清算总额：{{info.serviceChargeBankSettle+info.serviceChargeAliSettle}}</div>
    </el-card>
    <el-card v-role="'自动获取,手动获取,管理员,商户'" shadow="hover" style="min-width: 200px">
      <div>结算总额：{{info.settleAmount}}</div>
      <div class="top-bottom">未结算总额：{{info.noSettleAmount}}</div>
    </el-card>
    <el-card  shadow="hover" style="min-width: 200px" v-role="'管理员'">
      <div>商户总余额：{{info.totalAmount?.toFixed(2)}}</div><br>
      <div>平台总盈利：{{info.totalProfit?.toFixed(2)}}</div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "TradingList",
  props:{
    orderInfo:{
      type:Object,
      default:function(){
        return {

        }
      }
    },
  },
  data(){
    return{
      info:{
        //支付宝订单金额
        "totalAmountAli": 0,
        // 银行卡订单金额
        "totalAmountBank": 0,
        // 支付宝成功订单金额
        "orderSuccessAli": 0,
        // 银行卡成功订单金额
        "orderSuccessBank": 0,
        // 支付宝服务费
        "serviceChargeAli": 0,
        // 银行卡服务费
        "serviceChargeBank": 0,
        // 已结算银行卡服务费
        "serviceChargeBankSettle": 0,
        // 已结算支付宝服务费
        "serviceChargeAliSettle": 0,
        // 支付宝订单数量
        "countAliTotal": 0,
        // 银行卡订单数量
        "countBankTotal": 0,
        // 支付宝成功订单数量
        "countAliSuccess": 0,
        // 银行卡成功订单数量
        "countBankSuccess": 0,
        "noSettleAmount": 0,
        "settleAmount": 0,
      }
    }
  },
  mounted() {
    this.info=this.orderInfo
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/conmmon-style.scss";
::v-deep .el-card__body, .el-main {
  padding: 10px;
}
.top-bottom{
  margin: 10px 0;
}
</style>
