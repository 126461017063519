
import constant from "@/constant";
export default {
  data() {
    return {
      privateLoginInfo:JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
  },
  filters:{
    payTypeTransfer(value,direction){
      let str
      for (let payTypeElement of constant.payType) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          break
        }
      }
      return str
    },
    payStateTransfer(value,direction){
      let str
      for (let payTypeElement of constant.payState) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          if (value === 8) {
            str='未下单'
          }
          if (direction !== undefined && direction === '流水统计' && (value ==-1||value ==11)) {
            str='已成功'
          }
          if (direction !== undefined && direction === '流水统计' && (value ==7)) {
            str='已取消'
          }
          if (direction !== undefined && direction === '流水统计' && (value ==13)) {
            str='修改金额'
          }
          if (direction !== undefined && direction === '流水统计' && (value ==1)) {
            str='充值成功'
          }
          if (direction !== undefined && direction === '流水统计' && (value ==100)) {
            str='转账成功'
          }
          if (direction !== undefined && direction === '流水统计' && (value ==0)) {
            str='已成功'
          }

          break;
        }
      }
      return str
    },
    orderStateTransfer(value,direction){
      let str
      for (let payTypeElement of constant.orderState) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.orderState
          if (value === 20 && direction!==undefined && direction.roleName==='码商') {
            str='待支付'
          }
          break;
        }
      }
      return str
    },
    noticeStateTransfer(value,direction){
      let str
      for (let payTypeElement of constant.orderState) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.noticeState
          break;
        }
      }
      return str
    },
    userStateTransfer(value,direction){
      let str
      for (let payTypeElement of constant.userState) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          break
        }
      }
      return str
    },
    businessStateTransfer(value,direction){
      let str
      for (let payTypeElement of constant.businessState) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          break
        }
      }
      return str
    },
    dictTransfer(value,direction){
      let str
      for (let payTypeElement of direction) {
        // noinspection EqualityComparisonWithCoercionJS
        if (payTypeElement.type == value) {
          str=payTypeElement.name
          break
        }
      }
      return str
    },
    settleTransfer(val){
      return val!==1?'结算完成':'结算中'
    },
  }
}
