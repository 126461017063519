export const rmap ={
    Layout:()=>import("@/components/Layout.vue"),
    LayoutBusiness:()=>import("@/components/Layout-Business.vue"),
    marketplace:()=>import("@/views/marketplace/Marketplace.vue"),
    trading:()=>import("@/views/trading/Trading.vue"),
    orderList:()=>import("@/views/trading/OrderList.vue"),
    admin:()=>import("@/views/admin/Admin.vue"),
    history:()=>import("@/views/history/History.vue"),
    userinfo:()=>import("@/views/info/UserInfo.vue"),
    orderInfo:()=>import("@/views/info/OrderInfo.vue"),
    teamTable:()=>import("@/views/info/TeamTable.vue"),
    adminUser:()=>import("@/views/admin/user/User.vue"),
    adminSetting:()=>import("@/views/admin/setting/Setting.vue"),
    adminBusiness:()=>import("@/views/admin/business/Business.vue"),
    platformUser:()=>import("@/views/admin/user/User.vue"),
    businessList:()=>import("@/views/admin/business/BusinessList.vue"),

    adminIndex:()=>import("@/views/admin_v2/AdminIndex.vue"),
    admin_Business:()=>import("@/views/admin_v2/AdminBusiness.vue"),
    setting:()=>import("@/views/admin_v2/AdminSetting.vue"),
    flowStatistics:()=>import("@/views/admin_v2/FlowStatistics.vue"),
    flowStatisticsAdmin:()=>import("@/views/admin_v2/FlowStatistics.vue"),
    loginUser:()=>import("@/views/admin_v2/LoginUser.vue"),
    orderOther:()=>import("@/views/admin_v2/OrderOther.vue"),
    orderTrading:()=>import("@/views/admin_v2/OrderTrading.vue"),
    orderTradingB:()=>import("@/views/admin_v2/OrderTrading.vue"),
    payType:()=>import("@/views/admin_v2/PayType.vue"),
    payTypeB:()=>import("@/views/admin_v2/PayType.vue"),
    platformMember:()=>import("@/views/admin_v2/PlatformMember.vue"),
    settle:()=>import("@/views/admin_v2/Settle.vue"),
    teamLeader:()=>import("@/views/admin_v2/TeamLeader.vue"),
    teamMember:()=>import("@/views/admin_v2/TeamMember.vue"),
    adminTopUp:()=>import("@/views/admin_v2/AdminTopUp.vue"),
    collAccount:()=>import("@/views/admin_v2/CollAccount.vue"),
    TeamInfo:()=>import("@/views/admin_v2/TeamInfo.vue"),
    TeamList:()=>import("@/views/admin_v2/TeamList.vue"),
    StatisticsCons:()=>import("@/views/admin_v2/StatisticsCons.vue"),
    StatisticsMS:()=>import("@/views/admin_v2/StatisticsMS.vue"),
}

export const routerhandle = (routes)=>{
    let arr=[];
    routes.forEach(ro=>{
        if (ro.type === 0) {
            let children = ro.children && ro.children.length > 1 ? routerhandle(ro.children) : [];
            let a={
                ...ro,
                meta: {title: ro.name, icon: ro.icon,id:ro.id,show:ro.show},
                component: rmap[ro.name],
                children
            }
            arr.push(a)
        } else if (ro.type === 2) {
            ro.children.forEach(item=>{
                let child = item.children && item.children.length > 1 ? routerhandle(item.children) : [];
                let b={
                    ...item,
                    meta: {title: item.name, icon: item.icon,id:item.id,show:item.show},
                    component: rmap[item.name],
                    children:child
                }
                arr.push(b)
            })
        }
    })
    return arr
}
/**
 if (JSON.parse(localStorage.getItem('userInfo')).roleName.includes('管理员')) {
 let arr=[];
 routes.forEach(ro=>{
 if (ro.type === 0) {
 let children = ro.children && ro.children.length > 1 ? routerhandle(ro.children) : [];
 let a={
 ...ro,
 meta: {title: ro.name, icon: ro.icon,id:ro.id,show:ro.show},
 component: rmap[ro.name],
 children
 }
 arr.push(a)
 } else if (ro.type === 2) {
 ro.children.forEach(item=>{
 let child = item.children && item.children.length > 1 ? routerhandle(item.children) : [];
 let b={
 ...item,
 meta: {title: item.name, icon: item.icon,id:item.id,show:item.show},
 component: rmap[item.name],
 children:child
 }
 arr.push(b)
 })
 }
 })
 return arr
 } else {
 return routes.map(ro => {
 let children = ro.children && ro.children.length > 1 ? routerhandle(ro.children) : [];
 return {
 ...ro,
 meta: {title: ro.name, icon: ro.icon,id:ro.id,show:ro.show},
 component: rmap[ro.name],
 children
 };
 })
 }


 */
